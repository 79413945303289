import React from 'react'

import Editable from '../Editable'
import P from '../../P'
import { SelectStore } from '../../Fields'

export default Editable({
  input: props => {
    return (
      <SelectStore { ...props } />
    )
  },

  display: ({ label, value: store }) => {
    // editable component pre fills empty fields with '-'
    const newValue = store !== '-' ? store.name : store

    return (
      <div>
        <P value={label} type='textButton' />
        <P value={newValue} />
      </div>
    )
  }
})
