import { colors } from '../../../../config/theme'

const styles = theme => ({
  container: {
    flex: 1,
    flexDirection: 'column',
    backgroundColor: colors.lightGrey
  },
  imageContainer: {
    display: 'flex',
    flex: 1,
    alignItems: 'center',
    justifyContent: 'center'
  },
  image: {
    width: 100,
    height: 100
  },
  footerContainer: {
    paddingTop: 5
  },
  footer: {
    textAlign: 'center'
  }
})

export default styles
