import { colors } from '../../../config/theme'

const getClearButtonCrossLineStyle = angle => ({
  content: '""',
  position: 'absolute',
  top: '50%',
  left: '50%',
  transform: `translate(-50%,-50%) rotate(${angle}deg)`,
  width: 14,
  height: 2,
  borderRadius: 1,
  backgroundColor: 'white'
})

export default theme => ({
  container: {
    display: 'flex',
    flexWrap: 'wrap'
  },
  textField: {
    width: '100%'
  },
  textFieldPadding: {
    paddingRight: 32
  },
  inputInkbar: {
    color: colors.materialUIFormGrey
  },
  inputContainer: {
    display: 'flex',
    flexDirection: 'column',
    flex: 1,
    minWidth: 0
  },
  clearButtonContainer: {
    position: 'absolute',
    top: 16,
    right: 28,
    bottom: 0,
    display: 'flex',
    flexDirection: 'column',
    justifyContent: 'center'
  },
  clearButton: {
    width: 20,
    height: 20,
    backgroundColor: 'black',
    borderRadius: 10,
    opacity: 0.25,
    marginTop: -5,
    position: 'relative',
    cursor: 'pointer',
    transition: '0.1s linear opacity',
    '&:hover': {
      opacity: 0.5
    },
    '&:before': getClearButtonCrossLineStyle(45),
    '&:after': getClearButtonCrossLineStyle(135)
  },
  arrowDropDownIcon: {
    // this is pasted straight out of node_modules/material-ui/Select/SelectInput.js
    position: 'absolute',
    right: 0,
    top: 'calc(50% - 12px)',
    color: theme.palette.action.active,
    'pointer-events': 'none'
  },
  noLabelArrow: {
    top: 'calc(50% - 20px)'
  }
})
