const styles = {
  requiredFieldsText: {
    fontFamily: 'Nunito',
    fontSize: '13px',
    lineHeight: '20px',
    margin: '25px 0'
  },
  contentContainer: {
    padding: '0 7.5%'
  }
}

export default theme => styles
