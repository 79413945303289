import { withHandlers, compose } from 'recompose'
import { push } from 'connected-react-router'
import _ from 'lodash'
import SelectCustomer from '../../Customer/SelectCustomerView'
import withCheckoutFlow from '../withCheckoutFlow'
import { connect } from 'react-redux'
import { pathForCheckoutType } from '../../../helpers/checkout'
import { selectors as followingSelectors } from '../../../store/modules/following'
import * as currentOrderCombinedSelectors from '../../../store/modules/combinedSelectors/currentOrderCombinedSelectors'
import { actions as currentOrderActions } from '../../../store/modules/currentOrder'

const mapStateToProps = state => {
  const order = currentOrderCombinedSelectors.currentOrderSelector(state)
  return {
    following: followingSelectors.getFollowingCustomersWithoutAnonymised(state),
    checkoutType: _.get(state, 'checkoutFlow.checkoutType'),
    ..._.pick(order, [
      'editMode',
      'orderNumber'
    ])
  }
}

export default compose(
  connect(mapStateToProps),
  withCheckoutFlow,
  withHandlers({
    onCustomerClick: ({ updateOrder, next }) => customer => {
      updateOrder({ customer })
      next()
    },
    onAnonymousCustomerClick: ({ dispatch, next }) => ({ anonymousReason }) => {
      dispatch(currentOrderActions.anonymousCustomer({ anonymousReason }))
      next()
    },
    registerCustomer: ({ dispatch, checkoutType }) => {
      dispatch(push(`/${pathForCheckoutType[checkoutType]}/register-customer`))
    }
  })
)(SelectCustomer)
