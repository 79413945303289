import React from 'react'
import styled from 'styled-components'

import useVideoContext from '../../hooks/useVideoContext'

import ToggleVideoButton from './ToggleVideoButton'
import ToggleAudioButton from './ToggleAudioButton'
import ToggleMonitor from './ToggleMonitor'
import ToggleParticipantVideoButton from './ToggleParticipantVideoButton'

const Controls = styled.div`
  display: flex;
  position: absolute;
  bottom: ${({ isConsultant }) => isConsultant ? 25 : 80}px;
  width: 100%;
  justify-content: center;
  align-items: center;
  z-index: 10;
`

const VideoControls = () => {
  const { isConsultant } = useVideoContext()

  return (
    <Controls isConsultant={isConsultant}>
      {/*
        * <ToggleParticipantVideoButton /> is a component for a safety feature that allows the Consultant
        * to show/hide the Customer's feed if they feel that the content might be unsafe.
        * NOTE: This feature is only available to the Consultant.
        */}
      {isConsultant && <ToggleParticipantVideoButton />}
      <ToggleVideoButton />
      <ToggleAudioButton />
      {/* {isConsultant && <ToggleMonitor />} */}
    </Controls>
  )
}

export default VideoControls
