import React from 'react'

import ProductsRouter from '../../../../containers/Products/ProductsRouter'
import Editable from '../../../../components/Editable'
import FormContentBox from '../../../../components/FormContentBox'
import { FormBody, FormError } from '../../../../components/Form'
import { SelectProduct } from '../../../../components/EditableFields'
import { translations, customerProfileContentOptions } from '../../../../config'
import InspirationsSearchScreen from '../../../Inspirations/InspirationsSearchScreen'

const WishlistForm = FormContentBox('wishlist')
const editButtonText = WishlistForm ? translations('Add/Edit') : translations('Edit')

const WrappedWishlistForm = Editable(({ initialValues, editing, toggleEdit, isSubmitting, boxName, onSubmit, formId, saveDisabled, editable }) => {
  return (
    <WishlistForm
      enableReinitialize
      initialValues={initialValues}
      editing={editing}
      editable={editable}
      toggleEdit={toggleEdit}
      isSubmitting={isSubmitting}
      boxName={boxName}
      onSubmit={onSubmit}
      formId={formId}
      saveDisabled={saveDisabled}
      editButtonText={editButtonText}
    >
      <FormError />
      <FormBody>
        <SelectProduct
          options={customerProfileContentOptions}
          hidePrice={false}
          label={boxName}
          editing={editing}
          name='wishlist'
          productsModalComponent={ProductsRouter}
          inspirationsModalComponent={InspirationsSearchScreen}
        />
      </FormBody>
    </WishlistForm>
  )
})

export default WrappedWishlistForm
