import _ from 'lodash'
import { useEffect, useState } from 'react'

import { notify } from '../helpers'

/*
 * The 'useSubscribe' hook is used to track when a participant is subscribed to a track.
 * This is mainly used for visual notifications to indicate to the user when they successfuly
 * connected/disconnected to a video/audio track that has been published from another participant.
 * 
 * NOTE: When toggling audio and video, video tracks are published/unpublished and therefore we use the 'useSubscribe' hook notify the participant.
 * However, audio track are published when the app load and toggling audio will just enabled/disabled it's state and therefore, we need to track them using the 'usePublicationIsTrackEnabled' hook.
 */
const useSubscribe = (participant) => {
  const [subscriptions, setSubscriptions] = useState([])

  useEffect(() => {
    if (!_.get(participant, 'tracks')) return
    // Set the Participant's initial track subscriptions
    setSubscriptions(Array.from(participant.tracks.values()))

    const subscribed = (track) => {
      setSubscriptions(prevTrack => [...prevTrack, track])
      notify({
        type: 'info',
        message: `${_.capitalize(participant.identity)} turned ${_.get(track, 'kind')} on`
      })
    }
    const unsubscribed = (track) => {
      setSubscriptions(prevTrack => prevTrack.filter(p => p !== track))
      notify({
        type: 'info',
        message: `${_.capitalize(participant.identity)} turned ${_.get(track, 'kind')} off`
      })
    }

    console.log(`Registering 'Subscribe' events for participant: ${participant.identity}`)

    participant.on('trackSubscribed', subscribed)
    participant.on('trackUnsubscribed', unsubscribed)
    return () => {
      participant.off('trackSubscribed', subscribed)
      participant.off('trackUnsubscribed', unsubscribed)
    }
  }, [participant])

  return subscriptions
}

export default useSubscribe
