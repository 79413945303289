import _ from 'lodash'
import { getGroupKeyFromProduct } from '@redant/digital-store-prices-beaverbrooks'

import { defaultState } from './store'
import { promiseReducer } from '../../util'
import { selectors as orderDetailsSelectors } from '../orderDetails'
import { products } from '../../../config'

const defaultEditMatchingProducts = (products) => products

/**
 * @description Find out if two products match by comparing multiple key/value pairs.
 * @param {*} product
 * @param {*} otherProduct
 * @returns {boolean} boolean is a product match
 */
export const productMatch = (product, otherProduct) => {
  return getGroupKeyFromProduct(product) === getGroupKeyFromProduct(otherProduct)
}

class CurrentOrderReducers {
  addProduct = (state, action) => {
    const { product, quantity = 1 } = action
    const currentOrderProducts = _.get(state, 'products', [])

    if (product) {
      const productsToAddToCurrentOrder = _.map(_.range(quantity), () => ({ ...product }))
      return {
        ...state,
        products: [
          ...currentOrderProducts,
          ...productsToAddToCurrentOrder
        ]
      }
    } else {
      return state
    }
  }

  removeProduct = (state, action) => {
    const { product } = action
    const currentOrderProducts = _.get(state, 'products', [])

    if (product) {
      const productsWithRemovedProduct = _.filter(currentOrderProducts, (currentOrderProduct) => {
        return !productMatch(product, currentOrderProduct)
      })
      return {
        ...state,
        products: [
          ...productsWithRemovedProduct
        ]
      }
    } else {
      return state
    }
  }

  /**
   * @description utility function to edit an array of products easily
   * @param {object} object
   * @param {array} object.products products collection
   * @param {object} object.product product to edit
   * @param {function(array)} object.editMatchingProducts function to run on all matching product
   * @memberof CurrentOrderReducers
   */
  _editProducts = ({
    products,
    product,
    editMatchingProducts = defaultEditMatchingProducts
  }) => {
    if (products && product) {
      const [matchingProducts, nonMatchingProducts] = _.partition(products, (productToMatch) => {
        return productMatch(product, productToMatch)
      })

      if (!_.size(matchingProducts)) {
        return products
      }

      return [
        ...nonMatchingProducts,
        ...editMatchingProducts(matchingProducts)
      ]
    } else {
      return products || []
    }
  }

  toggleLove = (state, action) => {
    const { product } = action
    if (product && state.products) {
      const products = this._editProducts(
        { products: state.products, product },
        matchingProducts => {
          return _.map(matchingProducts, product => ({
            ...product,
            isLoved: product.isLoved === undefined
              ? true
              : !product.isLoved
          }))
        })
      return { ...state, products }
    } else {
      return state
    }
  }

  // TODO: use _editProducts for this ffs
  toggleUnsold = (state, action) => {
    const { products } = state
    const { productId, variantId } = action
    const allOfAProduct = _.chain(products)
      .groupBy(product => `${product.id}_${product.variant.id}`)
      .get(`${productId}_${variantId}`)
      .value()
    const isUnsold = allOfAProduct.length === 1 && allOfAProduct[0].unsold
    const replacedProducts = _.chain(allOfAProduct)
      .take(1)
      .map(product => isUnsold ? { ...product, unsold: false } : { ..._.omit(product, 'manualDiscount'), unsold: true })
      .value()
    const nextProducts = _.chain(products)
      .reject(product => product.id === productId && product.variant.id === variantId)
      .concat(replacedProducts)
      .value()
    return {
      ...state,
      products: nextProducts
    }
  }

  changeProductQuantity = (state, action) => {
    const { quantity, product } = action
    const currentOrderProducts = _.get(state, 'products', [])

    if (quantity && product) {
      const editMatchingProducts = (matchingProducts) => {
        const currentProductQuantity = _.size(matchingProducts)
        if (quantity <= currentProductQuantity) {
          return _.slice(matchingProducts, 0, quantity)
        } else {
          return [
            ...matchingProducts,
            ..._.map(_.range(quantity - currentProductQuantity), () => ({ ...matchingProducts[0] }))
          ]
        }
      }

      const productsWithModifiedQuantities = this._editProducts({
        products: currentOrderProducts,
        product: product,
        editMatchingProducts: editMatchingProducts
      })

      return {
        ...state,
        products: [
          ...productsWithModifiedQuantities
        ]
      }
    } else {
      return state
    }
  }

  changeProductPurchaseReason = (state, action) => {
    const { reason, product } = action
    const currentOrderProducts = _.get(state, 'products', [])

    if (product && currentOrderProducts.length > 0) {
      const products = this._editProducts(
        {
          products: currentOrderProducts,
          product,
          editMatchingProducts: (matchingProducts) => {
            return _.map(matchingProducts, product => ({
              ...product,
              metadata: {
                ...product.metadata || {},
                productPurchaseReason: reason
              }
            }))
          }
        })
      return { ...state, products }
    } else {
      return state
    }

  }

  addManualDiscountToProduct = (state, action) => {
    const { discount, applyTo, product } = action
    const currentOrderProducts = _.get(state, 'products', [])

    if (discount && applyTo && product) {
      const editMatchingProducts = (matchingProducts) => {
        return [
          ..._.map(_.slice(matchingProducts, 0, applyTo), (matchedProduct) => {
            return {
              ...matchedProduct,
              manualDiscount: discount
            }
          }),
          ..._.slice(matchingProducts, applyTo)
        ]
      }

      const productsWithDiscountsApplied = this._editProducts({
        products: currentOrderProducts,
        product: product,
        editMatchingProducts: editMatchingProducts
      })

      return {
        ...state,
        products: [
          ...productsWithDiscountsApplied
        ]
      }
    } else {
      return state
    }
  }

  removeManualDiscountFromProduct = (state, action) => {
    const { product } = action
    const currentOrderProducts = _.get(state, 'products', [])

    if (product) {
      const editMatchingProducts = (matchingProducts) => {
        return _.map(matchingProducts, (matchedProduct) => {
          return _.omit(matchedProduct, 'manualDiscount')
        })
      }

      const productsWithDiscountsRemoved = this._editProducts({
        products: currentOrderProducts,
        product: product,
        editMatchingProducts: editMatchingProducts
      })

      return {
        ...state,
        products: [
          ...productsWithDiscountsRemoved
        ]
      }
    } else {
      return state
    }
  }

  startConsultationMode = (state, action) => {
    const { consultationMode, products, selectedProducts } = state
    if (_.get(action, 'startEvent') === 'addProductOrService') {
      return {
        ...state,
        consultationMode: true,
        selectedProducts: products ? [...products] : [],
        products: []
      }
    }
    return {
      ...state,
      consultationMode: true
    }
  }

  updateOrder = (state, action) => {
    return {
      ...state,
      ..._.omit(action, ['type', 'details']),
      details: {
        ..._.get(state, 'details', {}),
        ..._.get(action, 'details', {})
      }
    }
  }

  createCustomer = promiseReducer(
    (state, action) => {
      return {
        ...state,
        customer: action.result
      }
    },
    (state) => {
      return state
    },
    (state) => {
      return state
    },
    'addCustomerStatus',
    'addCustomerError'
  )

  addCustomer = promiseReducer(
    (state, action) => {
      return {
        ...state,
        customer: action.result
      }
    }
  )

  clearOrder = (state, { doNotRemoveEditMode, clearAppointment, isVirtualConsultation }) => {
    const nextStateCustomer = isVirtualConsultation ? state.customer : null

    const nextCurrentOrderState = {
      ...defaultState,
      ...clearAppointment,
      customer: nextStateCustomer
    }

    if (state.editMode && doNotRemoveEditMode) {
      return {
        ...nextCurrentOrderState,
        editMode: state.editMode,
        editType: state.editType,
        orderNumber: state.orderNumber
      }
    } else {
      return {
        ...nextCurrentOrderState
      }
    }
  }

  restoreOrder = promiseReducer(
    (state, action) => {
      return {
        ...state,
        ...action.result
      }
    }
  )

  clearOrderExceptBasket = (state, action) => {
    // lol
    const {
      products,
      editMode,
      editType,
      orderNumber,
      id,
      storeroomOrderId
    } = state

    return {
      ...defaultState,
      editMode,
      editType,
      products,
      orderNumber,
      id,
      storeroomOrderId
    }
  }

  _formatDatabaseOrderToReduxOrder = (order) => {
    const orderClone = _.cloneDeep(order)
    return {
      ...orderClone,
      products: _.map(orderClone.products, product => {
        if (typeof (_.get(product, 'variant')) === 'string') {
          product.variant = {
            name: _.get(product, 'variant').toString()
          }
        }
        product.id = _.get(product, 'productId')
        return _.omit(product, 'productId')
      })
    }
  }

  populateOrder = (state, action) => {
    const { type, ...rest } = action
    return {
      ...this._formatDatabaseOrderToReduxOrder(rest)
    }
  }

  editOrder = (state, { payload }) => {
    const transformedPayload = this._formatDatabaseOrderToReduxOrder(payload.order)
    const newState = {
      ...transformedPayload,
      editMode: true,
      editType: payload.editType
    }
    return newState
  }

  stopEditingOrder = (state) => {
    const newState = { ...defaultState }
    return newState
  }

  anonymousCustomer = (state, { customerWasAnonymised, anonymousReason }) => {
    const newState = _.omit(state, ['customer', 'deliveryDetails'])
    return {
      ...newState,
      customerWasAnonymised,
      details: {
        ...(newState.details || {}),
        anonymousReason
      }
    }
  }

  resetCustomerWasAnonymised = (state) => {
    return { ...state, customerWasAnonymised: false }
  }

  deselectProduct = (state, { product }) => {
    const filteredSelectedProducts = _.filter(state.selectedProducts, p => p.id !== product.id)
    const filteredOrderProducts = _.filter(state.products, p => p.id !== product.id)

    return {
      ...state,
      selectedProducts: filteredSelectedProducts,
      products: filteredOrderProducts
    }
  }

  clearSelectedProducts = (state) => {
    return {
      ...state,
      selectedProducts: []
    }
  }

  manageCustomerReviewBasketStatus = (state, action) => {
    if (state.customerHasReviewedBasket) {
      return state
    }
    return {
      ...state,
      customerHasReviewedBasket: _.get(state, 'customerHasReviewedBasket', true)
    }
  }

  addInspirationToOrder = (state, { inspiration }) => {
    const inspirationProductIdsToBeRemovedFromBasket = _.map(_.get(state, 'details.inspiration.products'), (product) => _.get(product, 'id'))
    return {
      ...state,
      addedInspirationProducts: !_.isEmpty(inspiration),
      details: {
        ..._.get(state, 'details', {}),
        inspiration: inspiration
      },
      products: [
        ..._.filter(_.get(state, 'products', []), (product) => {
          return !_.includes(inspirationProductIdsToBeRemovedFromBasket, _.get(product, 'id'))
        }),
        ..._.map(_.get(inspiration, 'products', []), (product) => {
          if (_.get(product, 'variant.id')) {
            return {
              unsold: true,
              ...product
            }
          }
          return {
            unsold: true,
            ...product,
            variant: _.get(product, 'variants[0]', null)
          }
        })
      ]
    }
  }

  setDiscount = (state, { discount }) => {
    return {
      ...state,
      details: {
        ...state.details,
        discount
      }
    }
  }

  anonymousCustomer = (state, { customerWasAnonymised, anonymousReason }) => {
    const newState = _.omit(state, ['customer', 'deliveryDetails'])
    return {
      ...newState,
      customerWasAnonymised,
      details: {
        ...(newState.details || {}),
        anonymousReason
      }
    }
  }

  resetCustomerWasAnonymised = (state) => {
    return { ...state, customerWasAnonymised: false }
  }

  manageCustomerReviewBasketStatus = (state, action) => {
    if (state.customerHasReviewedBasket) {
      return state
    }
    return {
      ...state,
      customerHasReviewedBasket: _.get(state, 'customerHasReviewedBasket', true)
    }
  }

  toggleRefundItem = (state, action) => {
    return {
      ...state,
      products: state.products.map(product => {
        if (product.id !== action.id) {
          return product
        }

        // Inverse the price and convert back to string
        const price = parseFloat(product.price.value)
        const inversePrice = action.refund
          ? String(-Math.abs(price))
          : String(Math.abs(price))

        const newProduct = {
          ...product,
          price: {
            ...product.price,
            value: inversePrice
          },
          refund: action.refund
        }

        // Ensure manual discounts are removed on toggle
        return _.omit(newProduct, ['manualDiscount'])
      })
    }
  }

  setCurrentOrder = (state, action) => {
    return {
      ...state,
      ...action.currentOrder
    }
  }

  addConsultationServiceToCurrentOrder = (state, action) => {
    const serviceCategoryId = _.get(action, 'servicesCategory.id')
    const quickTricksCategoryId = _.get(action, 'quickTricksCategory.id')

    const currentProducts = _.get(state, 'products')

    const productsWithoutServices = _.filter(currentProducts, (product) => {
      const productCatId = _.get(product, 'category.id') || _.get(product, 'categoryId')
      return productCatId !== serviceCategoryId && productCatId !== quickTricksCategoryId
    })

    const nextProducts = _.concat(productsWithoutServices, [action.product])

    return {
      ...state,
      products: nextProducts
    }
  }

  fetchBasketConfiguration = (state, action) => {
    return state
  }

  toggleAttachContentMode = (state, action) => {
    return {
      ...state,
      attachContentMode: action.toggle
    }
  }

  setStripePaymentSuccess = (state, action) => {
    return {
      ...state,
      stripePaymentSuccess: action.success
    }
  }

  setDiscount = (state, { discount }) => {
    return {
      ...state,
      details: {
        ...state.details,
        discount
      }
    }
  }
  setPaymentDetails = (state, action) => ({
    ...state,
    payment: action.payment
  })

  updateOrderDetails = (state, action) => {
    const newDetails = action.details || {}
    return {
      ...state,
      details: {
        ...state.details,
        ...newDetails
      }
    }
  }
}

export default new CurrentOrderReducers()
