import { useState, useEffect } from 'react'

import { notify } from '../helpers'

/*
 * The 'usePublicationIsTrackEnabled' hook is used to notify when a publication is enabled/disabled.
 * While this is an abstrack hook, it's purpose is to to notify when 'audio track' are toggled on/off.
 * 
 * NOTE: When toggling audio and video, video tracks are published/unpublished and therefore we use the 'useSubscribe' hook notify the participant.
 * However, audio track are published when the app load and toggling audio will just enabled/disabled it's state and therefore, we need to track them using the 'usePublicationIsTrackEnabled' hook.
 */

const usePublicationIsTrackEnabled = (publication, participant, isLocalParticipant) => {
  const [isEnabled, setIsEnabled] = useState(publication ? publication.isTrackEnabled : false)

  useEffect(() => {
    setIsEnabled(publication ? publication.isTrackEnabled : false)

    if (publication && !isLocalParticipant) {
      const onEnabled = () => {
        setIsEnabled(true)
        notify({
          type: 'info',
          message: `${_.capitalize(participant.identity)} turned ${_.get(publication, 'kind')} on`
        })
      }
      const onDisabled = () => {
        setIsEnabled(false)
        notify({
          type: 'info',
          message: `${_.capitalize(participant.identity)} turned ${_.get(publication, 'kind')} off`
        })
      }
      publication.on('trackEnabled', onEnabled)
      publication.on('trackDisabled', onDisabled)
      return () => {
        publication.off('trackEnabled', onEnabled)
        publication.off('trackDisabled', onDisabled)
      }
    }
  }, [publication])

  return isEnabled
}

export default usePublicationIsTrackEnabled
