import { colors } from '../../../../config/theme'

export default theme => {
  return {
    container: {
    },
    basketTypeHeader: {
      backgroundColor: colors.lightGrey,
      textAlign: 'center',
      border: `1px solid ${colors.border}`,
      textTransform: 'capitalize'
    },
    basketTypeTitle: {
      padding: 3,
      color: colors.text
    },
    column: {
      width: '100%',
      borderLeft: `0.5px solid ${colors.border}`,
      '&:first-child': {
        borderLeft: 'none'
      }
    },
    uppercase: {
      textTransform: 'uppercase'
    },
    section: {
      flexShrink: 0,
      borderBottom: `0.5px solid ${colors.border}`,
      padding: '25px 30px 25px',
      '&:last-child': {
        borderBottom: 'none'
      }
    },
    inner: {
      maxWidth: 380,
      width: '100%',
      margin: 'auto'
    },
    title: {
      marginBottom: 16,
      textTransform: 'uppercase',
      textAlign: 'center'
    },

    basketSection: {
    },
    basketInner: {
    },
    basketList: {
      boxShadow: '0 2px 4px 0 rgba(0,0,0,0.2)',
      marginBottom: 20
    },

    dateInner: {
      maxWidth: 320,
      margin: '0 auto'
    },

    totalsContainer: {
      display: 'grid',
      gridTemplateColumns: 'auto auto',
      justifyContent: 'space-between',
      gridGap: '8px',
      maxWidth: 320,
      margin: '0 auto 20px',
      '& >:nth-child(2n+1)': {
        fontWeight: 'bold'
      },
      '& >:nth-last-child(-n+2)': {
        marginTop: 8
      }
    },

    extraDetailsContentBox: {
      padding: 0,
      maxWidth: 320,
      margin: '0 auto'
    },

    extraDetailsContentBoxContainer: {
      border: 0
    },

    question: {
      fontWeight: 'bold'
    },
    answer: {
      fontWeight: 'normal'
    },
    formControl: {
      width: '100%'
    },
    discountButtonContainer: {
      display: 'flex',
      margin: '10px 0px'
    },
    discountButton: {
      flex: 1
    },
    discountModeSubheader: {
      backgroundColor: '#FAFAFA',
      display: 'flex',
      flexDirection: 'row',

      '& >p': {
        flex: 2,
        margin: '11px',
        fontWeight: 'bold',
        fontSize: '12px'
      },
      '& >button': {
        padding: '8px',
        fontSize: '12px'
      }
    },
    discountModeButtonsContainer: {
      display: 'flex',
      flexDirection: 'row',
      borderTop: '1px solid #e9e9e9',
      borderBottom: '1px solid #e9e9e9',
      padding: '10px'
    },
    discountModeSelectAll: {
      flex: 1,
      fontSize: '10px',
      padding: 0
    },
    discountModeApply: {
      flex: 2,
      fontSize: '10px',
      padding: 0
    }
  }
}
