import React, { Fragment } from 'react'
import _ from 'lodash'

import { withStyles } from '@material-ui/core/styles'

import { translations, noPrices } from '../../../../config'
import currencyFormatter from '../../../../formatters/currencyFormatter'
import H2 from '../../../../components/H2'
import P from '../../../../components/P'
import FormSubmit from '../../../../components/Form/FormSubmit'

import style from './style'

const format = currencyFormatter.format

const ConfirmSection = props => {
  const {
    submitText,
    handleNext,
    numberOfProducts,
    numberOfPurchaseableProducts,
    total,
    next,
    classes,
    deliveryText,
    deliveryPrice,
    totalDiscount,
    subTotal,
    invalid,
    hasDelivery,
    hasTax,
    tax,
    customer
  } = props

  return <div className={classes.section}>
    <div className={classes.inner}>
      <div className={classes.totalsContainer}>
        <P value={translations('Items')} />
        <P value={numberOfPurchaseableProducts || 0} />
        {
          noPrices
            ? null
            : <Fragment>
              <P value={translations('Sub Total')} />
              <P value={format(subTotal)} />
              {
                totalDiscount
                  ? <Fragment>
                    <P value={translations('Discounts')} />
                    <P value={format(totalDiscount)} />
                  </Fragment>
                  : null
              }
              {
                hasDelivery && customer
                  ? <Fragment>
                    <P value={deliveryText} />
                    <P value={format(deliveryPrice)} />
                  </Fragment>
                  : null
              }
              {
                hasTax
                  ? <Fragment>
                    <P value={translations('Tax')} />
                    <P value={format(tax)} />
                  </Fragment>
                  : null
              }
              <H2 value={_.toUpper(translations('Total'))} />
              <H2 value={format(total)} />
            </Fragment>
        }
      </div>
      <FormSubmit
        onClick={handleNext ? next : undefined}
        buttonType='primary'
        big
        label={submitText}
        disabled={invalid}
      />
    </div>
  </div>
}

export default withStyles(style)(ConfirmSection)
