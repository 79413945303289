import React, { useState, useEffect } from 'react'
import styled from 'styled-components'
import _ from 'lodash'
import Grid from '@material-ui/core/Grid'
import FormControl from '@material-ui/core/FormControl'
import { Dropdown } from '../../../../../components/Fields'
import { translations } from '../../../../../config'
import { required } from '../../../../../validators'
import { useGetFormValues } from '../../../../../hooks/formHooks'
import { useDispatch } from 'react-redux'
import { change } from 'redux-form'

const CustomFormControl = styled(FormControl)`
  width: 100%;
  margin-bottom: 18px;
`

const SelectServiceDropdowns = () => {
  const dispatch = useDispatch()
  const { serviceType } = useGetFormValues('new-appointment')
  const [serviceOptions, setServiceOptions] = useState([])

  useEffect(() => {
    dispatch(change('new-appointment', 'service', undefined))
    if (serviceType === 'APPOINTMENT') {
      setServiceOptions([
        translations('Consultation - appointment type service - option 1'), 
        translations('Consultation - appointment type service - option 2'), 
        translations('Consultation - appointment type service - option 3'),
        translations('Consultation - appointment type service - option 4'),
        translations('Consultation - appointment type service - option 5'),
        translations('Consultation - appointment type service - option 6'),
        translations('Consultation - appointment type service - option 7'),
        translations('Consultation - appointment type service - option 8')
      ])
    } else if (serviceType === 'ENQUIRY') {
      setServiceOptions([
        translations('Consultation - enquiry type service - option 1'), 
        translations('Consultation - enquiry type service - option 2'),
        translations('Consultation - enquiry type service - option 3'),
        translations('Consultation - enquiry type service - option 4'),
        translations('Consultation - enquiry type service - option 5'),
        translations('Consultation - enquiry type service - option 6'),
        translations('Consultation - enquiry type service - option 7'),
        translations('Consultation - enquiry type service - option 8'),
        translations('Consultation - enquiry type service - option 9'),
        translations('Consultation - enquiry type service - option 10'),
        translations('Consultation - enquiry type service - option 11'),
        translations('Consultation - enquiry type service - option 12'),
        translations('Consultation - enquiry type service - option 13'),
        translations('Consultation - enquiry type service - option 14'),
        translations('Consultation - enquiry type service - option 15'),
        translations('Consultation - enquiry type service - option 16'),
        translations('Consultation - enquiry type service - option 17'),
        translations('Consultation - enquiry type service - option 18'),
        translations('Consultation - enquiry type service - option 19'),
        translations('Consultation - enquiry type service - option 20'),
        translations('Consultation - enquiry type service - option 21'),
        translations('Consultation - enquiry type service - option 22'),
        translations('Consultation - enquiry type service - option 23'),
        translations('Consultation - enquiry type service - option 24'),
        translations('Consultation - enquiry type service - option 25'),
        translations('Consultation - enquiry type service - option 26'),
        translations('Consultation - enquiry type service - option 27'),
        translations('Consultation - enquiry type service - option 28'),
        translations('Consultation - enquiry type service - option 29'),
      ])
    } else {
      setServiceOptions([])
    }
  }, [serviceType])

  return (
    <Grid container spacing={2}>
      <Grid item xs={12} sm={5}>
        <CustomFormControl required>
            <Dropdown
              label={translations('Type')}
              name='serviceType'
              options={[{ label: `${translations('Appointment')}`, value: 'APPOINTMENT' }, { label: `${translations('Enquiry')}`, value: 'ENQUIRY' }]}
            />
        </CustomFormControl>
      </Grid>
      <Grid item xs={12} sm={7}>
        <CustomFormControl required>
            <Dropdown
              label={translations('Service')}
              name='service'
              validate={required}
              options={serviceOptions}
              disabled={!serviceType}
            />
        </CustomFormControl>
      </Grid>
    </Grid>
  )
}

export default SelectServiceDropdowns
