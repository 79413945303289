const styles = theme => ({
  text: {
    whiteSpace: 'pre-line',
    overflowY: 'auto',
    margin: 0
  },
  title: {
    fontWeight: 'bold',
    fontSize: '19px',
    fontFamily: 'Nunito',
    marginBottom: '21px'
  },
  container: {
    width: '48%',
    padding: '20px 20px 10px 20px'
  },
  buttonsContainer: {
    marginTop: 20
  }
})

export default styles
