export const ADD_PRODUCT_TO_ORDER = 'RA/currentOrder/ADD_PRODUCT_TO_ORDER'
export const ADD_CUSTOMER_TO_ORDER = 'RA/currentOrder/ADD_CUSTOMER_TO_ORDER'
export const REMOVE_PRODUCT_FROM_ORDER = 'RA/currentOrder/REMOVE_PRODUCT_FROM_ORDER'
export const CHANGE_PRODUCT_QUANTITY_IN_ORDER = 'RA/currentOrder/CHANGE_PRODUCT_QUANTITY_IN_ORDER'
export const UPDATE_ORDER = 'RA/currentOrder/UPDATE_ORDER'
export const CREATE_CUSTOMER_FOR_ORDER = 'RA/currentOrder/CREATE_CUSTOMER_FOR_ORDER'
export const CLEAR_ORDER = 'RA/currentOrder/CLEAR_ORDER'
export const RESTORE_ORDER = 'RA/currentOrder/RESTORE_ORDER'
export const CLEAR_ORDER_EXCEPT_BASKET = 'RA/currentOrder/CLEAR_ORDER_EXCEPT_BASKET'
export const ADD_MANUAL_DISCOUNT_TO_PRODUCT = 'RA/currentOrder/ADD_MANUAL_DISCOUNT_TO_PRODUCT'
export const REMOVE_MANUAL_DISCOUNT_FROM_PRODUCT = 'RA/currentOrder/REMOVE_MANUAL_DISCOUNT_FROM_PRODUCT'
export const EDIT_ORDER = 'RA/currentOrder/EDIT_ORDER'
export const STOP_EDITING_ORDER = 'RA/currentOrder/STOP_EDITING_ORDER'
export const ANONYMOUS_CUSTOMER = 'RA/currentOrder/ANONYMOUS_CUSTOMER'
export const SET_DISCOUNT = 'RA/currentOrder/SET_DISCOUNT'
export const RESET_CUSTOMER_WAS_ANONYMISED = 'RA/currentOrder/RESET_CUSTOMER_WAS_ANONYMISED'
export const POPULATE_ORDER = 'RA/currentOrder/POPULATE_ORDER'
export const TOGGLE_REFUND_ITEM = 'RA/currentOrder/TOGGLE_REFUND_ITEM'
export const CALCULATE_BASKET_DISCOUNT = 'RA/currentOrder/CALCULATE_BASKET_DISCOUNT'
export const SET_CURRENT_ORDER = 'RA/currentOrder/SET_CURRENT_ORDER'
export const TOGGLE_CUSTOMER_BASKET_REVIEW_STATUS = 'RA/currentOrder/TOGGLE_CUSTOMER_BASKET_REVIEW_STATUS'
export const ADD_REQUEST_ITEM = 'RA/currentOrder/ADD_REQUEST_ITEM'
export const SAVE_TO_PROFILE = 'RA/currentOrder/SAVE_TO_PROFILE'
export const ADD_CONSULTATION_SERVICE_TO_CURRENT_ORDER = 'RA/currentOrder/ADD_CONSULTATION_SERVICE_TO_CURRENT_ORDER'
export const FETCH_BASKET_CONFIGURATION = 'RA/currentOrder/FETCH_BASKET_CONFIGURATION'
export const TOGGLE_UNSOLD = 'RA/currentOrder/TOGGLE_UNSOLD'
export const TOGGLE_LOVE = 'RA/currentOrder/TOGGLE_LOVE'
export const REMOVE_MANUAL_DISCOUNT_FROM_ORDER = 'RA/currentOrder/REMOVE_MANUAL_DISCOUNT_FROM_ORDER'
export const SET_PAYMENT_DETAILS = 'RA/currentOrder/SET_PAYMENT_DETAILS'
export const CHANGE_PRODUCT_PURCHASE_REASON = 'RA/currentOrder/CHANGE_PRODUCT_PURCHASE_REASON'
export const UPDATE_ORDER_DETAILS = 'RA/currentOrder/UPDATE_ORDER_DETAILS'

// to be removed
export const START_CONSULTATION_MODE = 'RA/currentOrder/START_CONSULTATION_MODE'

export const TOGGLE_ATTACH_CONTENT_MODE = 'RA/currentOrder/TOGGLE_ATTACH_CONTENT_MODE'
export const PAY_BY_EMAIL = 'RA/currentOrder/PAY_BY_EMAIL'
export const SET_STRIPE_PAYMENT_SUCCESS = 'RA/currentOrder/SET_STRIPE_PAYMENT_SUCCESS'

export const EDIT_ORDER_TYPES = {
  DEFAULT: 'DEFAULT',
  EXCHANGE: 'EXCHANGE'
}
