import { createSelector } from 'reselect'
import _ from 'lodash'
import { FETCH_POLL_RESULTS_FRESH } from './constants'
import { PENDING } from '../../middleware/redux-promise'
import { pollReporting, colors } from '../../../config'

export const getIsInitial = state => !state.pollResults.status
export const getStatus = state => state.pollResults.status
export const getAction = state => state.pollResults.action
export const getFilters = state => _.get(state.pollResults, 'filters', {})
export const getPage = state => _.get(state.pollResults, 'page')
export const getTotal = state => _.get(state.pollResults, 'total')
export const getSize = state => _.get(state.pollResults, 'size')
export const getPollResults = state => _.get(state.pollResults, 'results')

export const getFilterCount = createSelector([
  getFilters
], (filters) => {
  return _.filter(filters, (f) => !!f).length
})

export const getHasMore = createSelector([
  getTotal,
  getPollResults
], (total, results) => {
  return total && results.length < total
})

export const getIsLoadingInitial = createSelector([
  getAction, getStatus
], (action, status) => (
  (status === PENDING && action === FETCH_POLL_RESULTS_FRESH)
))

export const getIsLoading = createSelector([
  getAction, getStatus
], (action, status) => (
  (status === PENDING && action === FETCH_POLL_RESULTS_FRESH)
))

const _getLabelForQuestionType = ({ key, type }) => {
  const label = _.get(pollReporting, `${type}.reportLabels.${key}`)
  return label || key
}

const _getBaseAnswers = ({ type }) => {
  const labels = _.get(pollReporting, `${type}.reportLabels`)
  return _.map(labels, (label, key) => {
    return {
      key,
      name: label,
      value: 0,
      color: colors.primary
    }
  })
}

const _getAnswerCounts = (result) => {
  const answers = result.pollAnswers
  const type = _.get(result, 'pollQuestion.type')
  const baseAnswers = _getBaseAnswers({ type })

  let userAnswers = []
  const countedAnswers = _.chain(answers)
    .countBy('answer')
    .value()

  _.forIn(countedAnswers, (value, key) => {
    userAnswers.push({
      key,
      name: _getLabelForQuestionType({ key, type }),
      value,
      color: colors.primary
    })
  })

  const combined = _.chain(baseAnswers)
    .map((baseAnswer) => {
      const realAnswer = _.find(userAnswers, (ua) => baseAnswer.key === ua.key)
      if (realAnswer) return realAnswer
      return baseAnswer
    })
    .sortBy('key')
    .reverse()
    .value()

  return combined
}

const _getCommentCount = (result) => {
  const answers = result.pollAnswers
  return _.filter(answers, (a) => a.details && a.details.comment).length
}

const _getAnswerAverage = (result) => {
  const answers = result.pollAnswers
  const type = _.get(result, 'pollQuestion.type')

  if (!answers.length) return null

  if (type === 'faces' || type === 'scale') {
    return _.chain(answers)
      .meanBy((a) => parseInt(a.answer))
      .floor(2)
      .value()
  }

  return null
}

export const getGroupedPollResults = createSelector([
  getPollResults
], (pollResults) => {
  const answerCounts = _.chain(pollResults)
    .map((result) => {
      return {
        ...result,
        answerCounts: _getAnswerCounts(result),
        commentCount: _getCommentCount(result),
        average: _getAnswerAverage(result)
      }
    })
    .value()

  return answerCounts
})
