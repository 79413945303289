import React, { useEffect } from 'react'
import styled from 'styled-components'
import { useSelector, useDispatch } from 'react-redux'
import Grid from '@material-ui/core/Grid'
import H2 from '../../../../components/H2'
import { translations } from '../../../../config'
import SummaryDetails from './Sections/SummaryDetails'
import SummaryContent from './Sections/SummaryContent'
import Notice from '../Notice'
import media from '../../../../config/media'
import { selectors as currentAppointmentSelectors } from '../../../../store/modules/currentAppointment'
import { actions as orderDetailsActions } from '../../../../store/modules/orderDetails'

const SummaryContainer = styled.div`
  max-width: 50%;
  overflow-y: scroll;
  overflow-x: hidden; 
  height: calc(100vh - 75px - 75px - 48px - env(safe-area-inset-top));
`

const SummaryTitle = styled(H2)`
  text-align: center;
  margin-bottom: 1rem;
  margin-top: 1rem;
`

const NoticeContainer = styled.div`
  margin: 10px;
`

const ConsultationSummary = ({ stacked }) => {
  const dispatch = useDispatch()
  const appointmentOrderId = useSelector(currentAppointmentSelectors.getAppointmentOrderId)
  const appointmentOrder = useSelector(currentAppointmentSelectors.getAppointmentOrder)
  const appointmentStage = useSelector(currentAppointmentSelectors.getAppointmentStage)

  useEffect(() => {
    if (appointmentOrderId && !appointmentOrder) {
      dispatch(orderDetailsActions.fetchOrder({ orderNumber: appointmentOrderId }))
    }
  }, [appointmentOrderId, appointmentOrder, appointmentStage])

  return (
    <SummaryContainer>
        <Grid item xs={12} sm={stacked ? 12 : 8}>
          {appointmentStage === 'ORDER_COMPLETION_FAILED' && (
            <NoticeContainer>
              <Notice
                title={translations('User review consultation - order completion failed message')}
              />
            </NoticeContainer>
          )}
          <SummaryTitle value={translations('Summary')} />
          <SummaryDetails stacked={stacked} />
          <SummaryContent stacked={stacked} />
        </Grid>
    </SummaryContainer>
  )
}

export default ConsultationSummary