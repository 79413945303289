import theme, { colors } from '../../config/theme'

const styles = {
  p: {
    ...theme.p.p,
    margin: 0,
    color: colors.text
  },
  large: {
    ...theme.p.large,
    color: colors.text
  },
  hubTileText: {
    ...theme.p.hubTileText,
    color: colors.whiteText
  },
  promoText: {
    ...theme.p.promoText,
    color: colors.whiteText,
    padding: '0 5px',
    margin: 0,
    textTransform: 'uppercase'
  },
  textButton: {
    ...theme.p.textButton,
    color: colors.text
  },
  alert: {
    ...theme.p.alert,
    color: colors.error
  },
  inline: {
    ...theme.p.inline,
    color: colors.text
  },
  bold: {
    ...theme.p.p,
    color: colors.text,
    fontWeight: 'bold'
  },
  greenText: {
    ...theme.p.p,
    color: colors.actionedGreen
  },
  small: {
    ...theme.p.small,
    color: colors.text
  },
  tag: {
    ...theme.p,
    color: colors.tagGrey,
    fontSize: 13,
    fontStyle: 'italic',
    textDecoration: 'underline'
  }
}

export default theme => styles
