import React from 'react'
import PropTypes from 'prop-types'
import { withStyles } from '@material-ui/core/styles'
import _ from 'lodash'

import ThreadItem from '../ThreadItem'
import MessageFrom from './MessageFrom'
import AttachmentsModal from './AttachmentsModal'

import { translations } from '../../config'
import { getFullName, stringManipulation } from '../../helpers'
import modalService from '../../services/modalService'

import style from './style'

const getToName = (message) => {
  if (message.fromCustomer) {
    if (message.communicationType === 'sms') {
      return 'Reply via SMS'
    }
    if (message.communicationType === 'whatsapp') {
      return 'Reply via Whatsapp'
    }
    if (message.user) {
      return getFullName(_.get(message, 'user', {}))
    } else {
      // customer has removed the id from the email
      return 'Unassigned'
    }
  } else {
    const customer = _.get(message, 'customer', {})
    return getFullName(customer)
  }
}

const openAttachmentsModal = ({ attachments }) => {
  modalService.open({
    component: AttachmentsModal,
    attachments,
    success: () => modalService.close()
  })
}

const generateButtons = ({ messageId, link,
  attachments, isFlagged, onFlagClicked }) => {
  let array = []
  // products were included in the message
  if (link) {
    array.push({ name: translations('View Images'), onClick: () => window.open(link, '_blank') })
  }
  if (attachments && attachments.length) {
    array.push({
      name: translations('See Attachments'),
      onClick: () => openAttachmentsModal({ attachments })
    })
  }
  if (!isFlagged) {
    array.push({
      name: translations('Report Inappropriate'),
      onClick: () => {
        onFlagClicked({ messageId })
      },
      disabled: isFlagged
    })
  }
  return array
}

const Message = ({
  message,
  truncate,
  fromCustomer = false,
  latest = false,
  showButtons,
  customerName,
  customerId,
  readMoreLink,
  goToMessage,
  classes,
  showFullMessageButton,
  onFlagClicked
}) => {
  const text = _.get(message, 'details.text', '')
  const link = _.get(message, 'details.messageS3Link', '')
  const isFlagged = _.get(message, 'isFlagged', false)
  const attachments = _.get(message, 'details.attachments')
  const formattedCustomerName = customerName || getFullName(_.get(message, 'customer', {}))
  const fromName = message.fromCustomer
    ? formattedCustomerName
    : getFullName(_.get(message, 'user', {}))
  const toName = getToName(message)
  // store from which the message was sent
  // displayed under from field - not displayed if it is from a customer
  const roleName = _.get(message, 'user.role.name')
  const storeName = _.get(message, 'store.name')
  const fromStoreRoleInfo = !message.fromCustomer && roleName && storeName
    ? `(${stringManipulation.formatRoleName(roleName)} - ${storeName})`
    : ''
  // role of the user the message is being sent to
  const toRoleInfo = message.fromCustomer && roleName
    ? `(${stringManipulation.formatRoleName(roleName)})`
    : ''
  return (
    <ThreadItem
      latest={latest}
      threadItemContent={text}
      link={readMoreLink}
      truncate={truncate}
      itemId={message.id}
      editable={false}
      renderFrom={() => (
        <MessageFrom
          message={message}
          toName={toName}
          fromName={fromName}
          fromStoreRoleInfo={fromStoreRoleInfo}
          toRoleInfo={toRoleInfo}
          hasAttachment={link || (attachments && attachments.length > 0)}
        />
      )}
      contentClass={classes.messageContent}
      showReadMore={false}
      showButtons={showButtons}
      buttons={
        generateButtons({
          text,
          link,
          attachments,
          fromCustomer,
          customerName: formattedCustomerName,
          isFlagged,
          goToMessage,
          messageId: message.id,
          showFullMessageButton,
          onFlagClicked
        })}
      isFlagged={isFlagged}
      onContainerClick={goToMessage}
    />
  )
}

Message.propTypes = {
  message: PropTypes.shape({}).isRequired,
  classes: PropTypes.shape({}).isRequired,
  truncate: PropTypes.bool,
  fromCustomer: PropTypes.bool.isRequired,
  latest: PropTypes.bool,
  customerName: PropTypes.string.isRequired,
  customerId: PropTypes.string.isRequired,
  showButtons: PropTypes.func,
  readMoreLink: PropTypes.string,
  goToMessage: PropTypes.func,
  showFullMessageButton: PropTypes.bool,
  onFlagClicked: PropTypes.func.isRequired
}

export default withStyles(style)(Message)
