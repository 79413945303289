import _ from 'lodash'
import { PENDING, SUCCESS, FAIL } from '../../middleware/redux-promise'

class CustomerDetailsReducers {
  getIsFlagged = result => !!result.flagId
  formatResult = result => {
    const latestMessage = _.get(result, 'latestMessage', null)
    return {
      ...result,
      latestMessage: latestMessage ? {
        ...latestMessage,
        isFlagged: this.getIsFlagged(latestMessage)
      } : latestMessage
    }
  }
  formatMarketingStoreIds = results => {
    return _.map(results, result => {
      return result.storeId
    })
  }
  addFlagToMessageIfIdMatches = (state, action) => {
    if (action.status === SUCCESS) {
      const id = _.get(action, 'payload.messageId')
      if (id === _.get(state, 'customer.latestMessage.id')) {
        return {
          ...state,
          result: {
            ...state.result,
            latestMessage: {
              ...state.result.latestMessage,
              isFlagged: true
            }
          }
        }
      }
    }
    return state
  }
  fetchFullCustomer = (state, action) => {
    switch (action.status) {
      case PENDING:
        return this.fetchFullCustomerPending(state, action)
      case SUCCESS:
        return this.fetchFullCustomerSuccess(state, action)
      case FAIL:
        return this.fetchFullCustomerFail(state, action)
      default:
        return state
    }
  }

  fetchFullCustomerPending = (state, action) => {
    return {
      ...state,
      status: PENDING,
      action: action.type
    }
  }

  fetchFullCustomerSuccess = (state, action) => {
    const { customer, marketingStoreIds, customerUserRelationships } = action.result
    const customerUserRelationship = customerUserRelationships.total === 1 ?
      customerUserRelationships.items[0] :
      {}
    return {
      ...state,
      result: {
        ...this.formatResult(customer),
        marketingStoreIds: this.formatMarketingStoreIds(marketingStoreIds),
        following: customerUserRelationships.total === 1,
        customerUserRelationshipId: customerUserRelationship.id
      },
      status: SUCCESS,
      action: undefined,
      error: undefined
    }
  }

  fetchFullCustomerFail = (state, action) => {
    return {
      ...state,
      status: FAIL,
      action: undefined,
      error: action.error.message
    }
  }

  updateCustomer = (state, action) => {
    switch (action.status) {
      case PENDING:
        return this.updateCustomerPending(state, action)
      case SUCCESS:
        return this.updateCustomerSuccess(state, action)
      case FAIL:
        return this.updateCustomerFail(state, action)
      default:
        return state
    }
  }

  updateCustomerPending = (state, action) => {
    return {
      ...state,
      status: PENDING,
      action: action.type
    }
  }

  updateCustomerSuccess = (state, action) => {
    return {
      ...state,
      result: action.result,
      status: SUCCESS,
      action: undefined,
      error: undefined
    }
  }

  updateCustomerFail = (state, action) => {
    return {
      ...state,
      status: FAIL,
      action: undefined,
      error: action.error.message
    }
  }

  createCustomer = (state, action) => {
    switch (action.status) {
      case PENDING:
        return this.createCustomerPending(state, action)
      case SUCCESS:
        return this.createCustomerSuccess(state, action)
      case FAIL:
        return this.createCustomerFail(state, action)
      default:
        return state
    }
  }

  createCustomerPending = (state, action) => {
    return {
      ...state,
      status: PENDING,
      action: action.type
    }
  }

  createCustomerSuccess = (state, action) => {
    return {
      ...state,
      result: action.result,
      status: SUCCESS,
      action: undefined,
      error: undefined
    }
  }

  createCustomerFail = (state, action) => {
    return {
      ...state,
      status: FAIL,
      action: undefined,
      error: action.error.message
    }
  }

  followCustomer = (state, action) => {
    switch (action.status) {
      case PENDING:
        return this.followCustomerPending(state, action)
      case SUCCESS:
        return this.followCustomerSuccess(state, action)
      case FAIL:
        return this.followCustomerFail(state, action)
      default:
        return state
    }
  }

  followCustomerPending = (state, action) => {
    return {
      ...state,
      status: PENDING,
      action: action.type
    }
  }

  followCustomerSuccess = (state, action) => {
    return {
      ...state,
      status: SUCCESS,
      result: {
        ...state.result,
        following: true
      },
      action: undefined,
      error: undefined
    }
  }

  followCustomerFail = (state, action) => {
    return {
      ...state,
      status: FAIL,
      action: undefined,
      error: action.error.message
    }
  }

  unfollowCustomer = (state, action) => {
    switch (action.status) {
      case PENDING:
        return this.unfollowCustomerPending(state, action)
      case SUCCESS:
        return this.unfollowCustomerSuccess(state, action)
      case FAIL:
        return this.unfollowCustomerFail(state, action)
      default:
        return state
    }
  }

  unfollowCustomerPending = (state, action) => {
    return {
      ...state,
      status: PENDING,
      action: action.type
    }
  }

  unfollowCustomerSuccess = (state, action) => {
    return {
      ...state,
      status: SUCCESS,
      result: {
        ...state.result,
        following: false
      },
      action: undefined,
      error: undefined
    }
  }

  unfollowCustomerFail = (state, action) => {
    return {
      ...state,
      status: FAIL,
      action: undefined,
      error: action.error.message
    }
  }

  anonymiseCustomer = (state, action) => {
    switch (action.status) {
      case PENDING:
        return this.anonymiseCustomerPending(state, action)
      case SUCCESS:
        return this.anonymiseCustomerSuccess(state, action)
      case FAIL:
        return this.anonymiseCustomerFail(state, action)
      default:
        return state
    }
  }

  anonymiseCustomerPending = (state, action) => {
    return {
      ...state,
      status: PENDING,
      action: action.type
    }
  }

  anonymiseCustomerSuccess = (state, action) => {
    return {
      ...state,
      result: action.result,
      status: SUCCESS,
      action: undefined,
      error: undefined
    }
  }

  anonymiseCustomerFail = (state, action) => {
    return {
      ...state,
      status: FAIL,
      action: undefined,
      error: action.error.message
    }
  }
}

export default new CustomerDetailsReducers()
