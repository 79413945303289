import { colors } from '../../../config/theme'

const styles = {
  timeWrapper: {
    width: '100%',
    display: 'inline-flex',
    marginBottom: '-20px'
  },
  timeFieldWrapper: {
    width: '50%',
    minWidth: 0
  },
  dropdownRoot: {
    width: '100%'
  },
  marginLeft: {
    marginLeft: '20px'
  },
  errorStyle: { color: colors.error },
  floatingLabelFocusStyle: { color: colors.accent }
}

export default theme => styles
