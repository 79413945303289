import { connect } from 'react-redux'
import { push } from 'connected-react-router'

import LoginScreen from '../../Login/LoginScreen'
import StorePinEntry from '../../PinEntry/StorePinEntry'
import store from '../../../store'
import { actions as appActions, selectors as appSelectors } from '../../../store/modules/app'
import { actions as authActions, selectors as authSelectors } from '../../../store/modules/auth'
import CustomerRegistrationScreen from './CustomerRegistrationScreen'
import modalService from '../../../services/modalService'
import { translations, environment } from '../../../config'

const mapStateToProps = state => ({ state })
const mapDispatchToProps = dispatch => ({ dispatch })

const mergeProps = ({ state }, { dispatch }, ownProps) => {
  const storePinEnabledMethods = environment.STORE_PIN_ENABLED || []
  const customerModeUpdate = appSelectors.getCustomerModeStatus(state)
  const loginMethod = authSelectors.getLoginMethod(state)
  const storePin = authSelectors.getUserSelectedStorePin(state)
  const shouldShowPin = storePin && !!_.find(storePinEnabledMethods, (method) =>
    method === loginMethod
  )

  const showPasswordModal = () => {
    modalService.open({
      component: shouldShowPin ? StorePinEntry : LoginScreen,
      hideForgotPassword: true,
      hideRecentUsers: true,
      success: () => {
        customerModeOff()
        modalService.close()
      },
      dismiss: () => {
        const accountIsLocked = authSelectors.getAccountIsLocked(store.getState())
        if (accountIsLocked) {
          modalService.action({
            title: translations('Sign out'),
            text: translations('Account Locked Message'),
            actions: [
              {
                success: true,
                text: translations('Sign out'),
                onClick: pushToLogOut,
                primary: true
              },
              {
                text: translations('Cancel')
              }
            ]
          })
        }
      }
    })
  }

  const customerModeOn = () => {
    dispatch(appActions.customerModeToggle({ isOn: true }))
  }
  const customerModeOff = () => {
    dispatch(appActions.customerModeToggle({ isOn: false }))
  }
  const pushToLogOut = () => {
    dispatch(appActions.customerModeToggle({ isOn: false }))
    dispatch(push('/'))
    dispatch(authActions.logout())
  }

  return {
    ...ownProps,
    customerModeUpdate,
    customerModeOn,
    customerModeOff,
    showPasswordModal
  }
}

export default connect(
  mapStateToProps,
  mapDispatchToProps,
  mergeProps
)(CustomerRegistrationScreen)
