import { useCallback } from 'react'
import _ from 'lodash'

import couchbaseService from '../../../../../services/couchbaseService'

const useLogEvent = (isConsultant, appointmentId) => {
  if (!appointmentId) return _.noop
  const logEvent = useCallback((event) => {
    console.log('Log (couchbase):', event)
    couchbaseService.logAppointmentEvent({ appointmentId, isConsultant, event })
  })

  return logEvent
}

export default useLogEvent
