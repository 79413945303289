import React, { Component } from 'react'
import { connect } from 'react-redux'
import { goBack } from 'connected-react-router'
import PropTypes from 'prop-types'
import { compose, withHandlers } from 'recompose'

import MessagePreviewScreen from './MessagePreviewScreen'
import digitalStoreSdk from '../../../digitalStoreSdk'
import { selectors as createMessageSelectors, actions as createMessageActions } from '../../../store/modules/createMessage'
import { selectors as authSelectors } from '../../../store/modules/auth'

const attachCSS = css => {
  const head = document.head
  const styleEl = document.createElement('style')
  styleEl.type = 'text/css'
  if (styleEl.styleSheet) {
    styleEl.styleSheet.cssText = css
  } else {
    styleEl.appendChild(document.createTextNode(css))
  }
  head.appendChild(styleEl)
}

class MessagePreviewScreenWithPreviewFetch extends Component {
  state = {
    preview: ''
  }

  componentDidMount () {
    const { dispatch, messageDetails, recipients, storeId, goBack } = this.props
    if (messageDetails && !messageDetails.text) {
      // do not show the preview if it has no content
      // go to message
      goBack()
    } else {
      dispatch(createMessageActions.getMessagePreview({ message: messageDetails, recipients, storeId }))
        .then(compiled => {
          this.setState({ preview: compiled.rawBody })
          return
          // attaching mjml styles to head
          let xClone = compiled
          // this could be a while loop but I don't want to run the risk of something
          // bugging out and it going on eternally
          for (var i = 0; i < 10; i++) {
            const styleStartTagIndex = xClone.indexOf('<style type="text/css">')
            const styleEndTagIndex = xClone.indexOf('</style>')
            if (styleStartTagIndex < 0) {
              break
            } else {
              const style = xClone.slice(
                styleStartTagIndex + 23,
                styleEndTagIndex
              )
              attachCSS(style)
              xClone = xClone.slice(
                styleEndTagIndex + 8
              )
            }
          }
          // getting the preview body
          const bodyStartTagIndex = compiled.indexOf('<body>')
          const bodyEndTagIndex = compiled.indexOf('</body>')  
          const body = compiled.slice(
            bodyStartTagIndex + 6,
            bodyEndTagIndex
          )
          this.setState({ preview: body })
        })
    }
  }

  render () {
    return <MessagePreviewScreen {...this.props} preview={this.state.preview} />
  }
}

MessagePreviewScreenWithPreviewFetch.propTypes = {
  goBack: PropTypes.func.isRequired,
  // not required
  messageDetails: PropTypes.shape({})
}

const mapStateToProps = state => {
  return {
    messageDetails: createMessageSelectors.getMessageDetails(state),
    recipients: createMessageSelectors.getCurrentRecipients(state),
    storeId: authSelectors.getUserSelectedStoreId(state)
  }
}

export default compose(
  connect(mapStateToProps),
  withHandlers({
    onSubmit: ({ dispatch, messageDetails, recipients, storeId }) => () => {
      return dispatch(createMessageActions.sendMessage({ message: messageDetails, recipients, storeId }))
    }
  })
)(MessagePreviewScreenWithPreviewFetch)
