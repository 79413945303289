export default theme => ({
  dropdown: {
    width: '15em',
    margin: '0 0.5em',
    textAlign: 'left'
  },
  field: {
    width: '100%'
  },
  mobileDropdownsContainer: {
    display: 'grid',
    gridGap: '18px',
    margin: 18
  }
})
