import { colors } from '../../../../../config/theme'

export default theme => ({
  container: {
    display: 'flex',
    flexDirection: 'column',
    alignItems: 'center',
    padding: 15,
    background: colors.accent,
    color: colors.offsetAccent,
    width: '100%',
    zIndex: 1,

    [theme.breakpoints.up('md')]: {
      flexDirection: 'row',
      alignItems: 'left',
      padding: 20,
      paddingLeft: 0
    }
  },
  leftContent: {
    display: 'flex',
    width: '100%',
    marginBottom: 20,
    alignItems: 'center',
    justifyContent: 'center',
    position: 'relative',

    [theme.breakpoints.up('md')]: {
      width: 'auto',
      marginBottom: 0,
      alignItems: 'left',
      justifyContent: 'left',
      position: 'static'
    }
  },
  customer: {
    display: 'flex',

    [theme.breakpoints.up('md')]: {
      marginTop: -3
    }
  },
  customerDetailsWrapper: {

  },
  centerDetailsWrapper: {
    [theme.breakpoints.up('md')]: {
      marginTop: 18
    }
  },
  customerNameWrapper: {
    display: 'flex'
  },
  avatar: {
    width: 60,
    height: 60,
    marginRight: 15,
    marginLeft: -14,
    borderRadius: '50%',
    background: colors.offsetAccent,
    color: colors.accent,
    display: 'none',
    justifyContent: 'center',
    alignItems: 'center',
    fontSize: 27,
    fontWeight: 900,

    [theme.breakpoints.up('md')]: {
      display: 'flex'
    }
  },
  title: {
    margin: 0,
    marginBottom: 11,
    lineHeight: '23px',
    fontSize: 16,
    whiteSpace: 'nowrap',
    color: 'white',

    [theme.breakpoints.up('md')]: {
      fontSize: 27
    }
  },
  pill: {
    height: 'fit-content',
    margin: '3px 0 0 13px',
    padding: '2px 7px',
    fontSize: 13,
    fontWeight: 700,
    textTransform: 'uppercase',
    background: colors.offsetAccent,
    color: colors.accent
  },
  dateOverviewWrapper: {
    fontSize: 13,
    fontWeight: 700,
    textTransform: 'uppercase'
  },
  dateOverview: {
    marginRight: 17
  },
  buttonWrapper: {
    marginTop: -6,
    display: 'flex',
    alignItems: 'center',
    justifyContent: 'center',
    width: 'calc(100% + 10px)',

    [theme.breakpoints.up('md')]: {
      marginLeft: 'auto',
      width: 'auto'
    }
  },
  profileButton: {
    width: '100%',
    marginTop: 10,
    maxWidth: 310,

    [theme.breakpoints.up('md')]: {
      marginTop: -6,
      marginLeft: 5,
      width: 'auto'
    }
  },
  buttonRoot: {
    margin: '0 5px'
  },
  buttonCustom: {
    border: '1px solid',
    borderColor: colors.offsetAccent,
    background: colors.accent,
    color: colors.offsetAccent
  },
  backArrowContainer: {
    position: 'absolute',
    left: -15,
    top: -11,

    [theme.breakpoints.up('md')]: {
      position: 'static'
    }
  },
  backArrowButtonText: {
    color: colors.offsetAccent
  },
  backArrow: {
    borderRightColor: colors.offsetAccent
  },
  buttonImage: {
    width: 20,
    height: 20
  }
})
