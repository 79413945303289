import { PENDING, SUCCESS, FAIL } from '../middleware/redux-promise'

/**
 * Handles on success/fail/pending for a Promise action
 * @param {function} mapSuccessState(state, action) - Reducer if success
 * @param {function} mapPendingState(state, action) - Reducer if pending
 * @param {function} mapFailState(state, action) - Reducer if fail
 * @param {string} statusProp - prop to use for status
 * @param {string} errorProp - prop to use for errors
 * @returns {Function} => Reducer => ReducedState
 */
export const promiseReducer = (
  mapSuccessState = state => state,
  mapPendingState = state => state,
  mapFailState = state => state,
  statusProp = 'status',
  errorProp = 'error'
) => (state, action) => {
  switch (action.status) {
    case PENDING:
      return {
        ...(mapPendingState(state, action)),
        [statusProp]: PENDING,
        action: action.type
      }
    case SUCCESS:
      return {
        ...(mapSuccessState(state, action)),
        [statusProp]: SUCCESS,
        action: undefined,
        [errorProp]: undefined
      }
    case FAIL:
      return {
        ...(mapFailState(state, action)),
        [statusProp]: FAIL,
        action: undefined,
        [errorProp]: action.error.message
      }
  }
}
