import { PhoneNumberUtil } from 'google-libphonenumber'
import moment from 'moment'
import _ from 'lodash'
import * as offlinePasswordHandler from '../helpers/offlinePasswordHandler'

import passwordPolicy from '../passwordPolicy'
import { dateFormat, timeFormat, checkoutBackdateLimit, translations } from '../config'

const dateAndTimeFormat = `${dateFormat}, ${timeFormat}`

export const email = (value) => {
  // regex base on RFC 5322
  return value && !/(?:[a-z0-9!#$%&'*+/=?^_`{|}~-]+(?:\.[a-z0-9!#$%&'*+/=?^_`{|}~-]+)*|"(?:[\x01-\x08\x0b\x0c\x0e-\x1f\x21\x23-\x5b\x5d-\x7f]|\\[\x01-\x09\x0b\x0c\x0e-\x7f])*")@(?:(?:[a-z0-9](?:[a-z0-9-]*[a-z0-9])?\.)+[a-z0-9](?:[a-z0-9-]*[a-z0-9])?|\[(?:(?:25[0-5]|2[0-4][0-9]|[01]?[0-9][0-9]?)\.){3}(?:25[0-5]|2[0-4][0-9]|[01]?[0-9][0-9]?|[a-z0-9-]*[a-z0-9]:(?:[\x01-\x08\x0b\x0c\x0e-\x1f\x21-\x5a\x53-\x7f]|\\[\x01-\x09\x0b\x0c\x0e-\x7f])+)\])/i.test(value)
    ? translations('Invalid email address') : undefined
}

export const password = (value) => {
  const validatePassword = passwordPolicy.validatePassword(value)
  if (validatePassword instanceof Error) {
    return validatePassword.message
  }
}

export const time = (value) => {
  if (value && !moment(value, timeFormat, true).isValid()) {
    return translations('Invalid time')
  }
}

export const timeRequired = (value) => {
  if (!moment(value, timeFormat, true).isValid()) {
    return translations('Invalid time')
  }
}

export const dateTime = (value) => {
  if (value && !moment(value, dateAndTimeFormat, true).isValid()) {
    return translations('Invalid date time')
  }
}
export const dateTimeRequired = (value) => {
  if (!moment(value, dateAndTimeFormat, true).isValid()) {
    return translations('Invalid date time')
  }
}

export const dateTimeRequiredInTheFuture = (value) => {
  const formattedDate = moment(value, dateAndTimeFormat, true)

  if (!formattedDate.isValid()) {
    return translations('Invalid date time')
  }

  if (formattedDate.isBefore(moment().subtract(5, 'minutes'), 'minute')) {
    return translations('Date cannot be in the past')
  }
}

export const dateTimeRequiredInThePast = (value) => {
  const formattedDate = moment(value, dateAndTimeFormat, true)

  if (!formattedDate.isValid()) {
    return translations('Invalid date time')
  }

  if (formattedDate.isAfter(moment())) {
    return translations('Date cannot be in the future')
  }
}

export const date = (value) => {
  if (value && !moment(value, dateFormat, true).isValid()) {
    return translations('Invalid date')
  }
}

export const checkoutDate = (value) => {
  if (value) {
    const formattedDate = moment(value, 'DD/MM/YYYY')
    if (formattedDate.isAfter(moment())) {
      return translations('Date cannot be in the future')
    }
    if (checkoutBackdateLimit) {
      const { value, unit } = checkoutBackdateLimit
      const maxPastDate = moment().subtract(value, unit)
      if (formattedDate.isBefore(maxPastDate)) {
        return translations('Order Date Change Checkout Error', { value, unit })
      }
    }
  }
}

export const dobMinAge = (minAge) => value => {
  const minDob = moment().subtract(minAge, 'years')
  if (moment(value, dateFormat).isAfter(minDob, 'day')) {
    return _.template(translations('Minimum age is minAge'))({ minAge })
  }
}

export const dateRequired = (value) => {
  if (!moment(value, dateFormat, true).isValid()) {
    return translations('Invalid date')
  }
}

export const passwordConfirmation = (value, form) => {
  const confirmationPassword = form.password
  if (confirmationPassword !== value) {
    return translations('Passwords do not match')
  }
}

export const storeCodeConfirmation = (value, form) => {
  const compareValue = form.storeCode
  if (compareValue !== value) {
    return translations('Store codes do not match')
  }
}

export const currentStoreCodeConfirmation = (value = '', form) => {
  const newValue = form.storeCode
  if (!newValue) {
    return undefined
  }

  return value.length === 0 ? translations('Please confirm current pin') : undefined
}

export const currentPinConfirmation = (value, form) => {
  if (!value || !form.currentStorePin) return undefined

  if (!offlinePasswordHandler.compare({ password: value, offlinePasswordHash: form.currentStorePin })) {
    return translations('Incorrect current pin')
  }
}

export const required = (value) => {
  return !value ? translations('Field is required') : undefined
}

export const money = (value) => {
  const moneyRegex = /^[0-9]\d*(((,\d{3}){1})?(\.\d{0,2})?)$/
  return !moneyRegex.test(value) ? translations('Invalid amount') : undefined
}

export const requiredWithFieldName = (fieldName) => (value) => {
  return !value ? _.template(translations('fieldName is required'))({ fieldName }) : undefined
}

export const minLength = (min) => value => {
  return value && value.length < min ? translations(`Must be ${min} characters minimum`) : undefined
}

export const maxLength = (max) => value => {
  return value && value.length > max ? translations(`Must be ${max} characters maximum`) : undefined
}

export const numeric = (value = '') => {
  const numberRegex = /^[0-9]*$/g
  return value.length > 0 && !numberRegex.test(value) ? translations('Numbers 0-9 only') : undefined
}

export const phoneNumber = value => {
  if (value) {
    // starts with +44
    // check the next character is not a 0
    // could use String.startsWith but IE support is bad :(
    const ukCode = '+44'
    // because of the space
    if ((value.substring(0, ukCode.length) === ukCode) && (value[ukCode.length] === '0' || value[ukCode.length + 1] === '0')) {
      return translations('UK phone numbers cannot begin with 0')
    }
    try {
      PhoneNumberUtil.getInstance().parse(value)
    } catch (e) {
      return translations('Invalid phone number')
    }
  }
}

export const url = value => {
  try {
    value && new window.URL(value)
  } catch (e) {
    return translations('Invalid - please ensure https is included at the start of your URL')
  }
}

export const storeRequired = value => {
  const errorText = translations('Boutique is required')
  if (value instanceof Array) {
    return value.length === 0 ? errorText : undefined
  } else {
    return !value ? errorText : undefined
  }
}

export const departmentRequired = value => {
  const errorText = translations('Department is required')
  if (value instanceof Array) {
    return value.length === 0 ? errorText : undefined
  } else {
    return !value ? errorText : undefined
  }
}

export const ean = value => {
  const errorText = translations('Invalid EAN')
  if (value && !!parseInt(value) && value.length >= 12 && value.length <= 13) {
    return undefined
  } else {
    return errorText
  }
}

export const generateSchemaValidationFromConfig = (validateObj) => {
  return _.map(validateObj, (value, key) => {
    switch (key) {
      case 'maxLength':
        return maxLength(value)
      default:
        return null
    }
  })
}
