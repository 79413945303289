import React from 'react'
import PropTypes from 'prop-types'

import BirthdayNotification from '../../../components/Notification/BirthdayNotification'
import ReceivedMessageNotification from '../../../components/Notification/ReceivedMessageNotification'
import PendingQuestionsNotification from '../../../components/Notification/PendingQuestionsNotification'
import OrderStatusNotification from '../../../components/Notification/OrderStatusNotification'
import UserToUserNotification from '../../../components/Notification/UserToUserNotification/UserToUserNotification';

const NotificationSwitch = notification => {
  const { type } = notification

  if (type === 'birthday') {
    return <BirthdayNotification {...notification} />
  }
  if (type === 'receivedMessage') {
    return <ReceivedMessageNotification {...notification} />
  }

  if (type === 'pendingQuestions') {
    return <PendingQuestionsNotification {...notification} />
  }
  if (type === 'orderStatus') {
    return <OrderStatusNotification {...notification} />
  }
  if (type === 'userToUser') {
    return <UserToUserNotification {...notification} />
  }
  if (type === 'scheduledNotificationSent') {
    return <UserToUserNotification toSender hideFlag {...notification} />
  }
  return null
}

NotificationSwitch.propTypes = {
  type: PropTypes.string.isRequired
}

export default NotificationSwitch
