import React, { Component } from 'react'
import _ from 'lodash'
import { withStyles } from '@material-ui/core/styles'
import cx from 'classnames'

import { getImage } from '../Images'
import { translations } from '../../config'
import Button from '../Button'

import style from './style'
import fileUploadService from '../../services/fileUploadService/fileUploadService'

class UploadFile extends Component {
  state = { file: this.props.file }

  fileSelectedWeb = (e) => {
    const { onPhotoAdded, onFileAdded } = this.props
    const file = e.target.files[0]
    const blobUrl = window.URL.createObjectURL(file)
    this.setState && this.setState({ file: blobUrl })
    if (file.type.includes('image')) {
      onPhotoAdded({ blobUrl, fileName: file.name })
    } else {
      onFileAdded({ blobUrl, fileName: file.name })
    }
  }

  fileSelectedCordova = (file) => {
    const { onPhotoAdded, onFileAdded } = this.props
    const isImage = (url) => /\.(jpg|jpeg|png|webp|avif|gif|svg)$/.test(url)
    this.setState && this.setState({ file })
    if (isImage(file)) {
      onPhotoAdded({ blobUrl: file, fileName: translations('File attached') })
    } else {
      onFileAdded({ blobUrl: file, fileName: translations('File attached') })
    }
  }

  openFilePickerWeb = () => {
    const { filepicker } = this.refs
    filepicker.click()
  }

  _onDeleteClick = () => {
    const { onPhotoRemoved } = this.props
    const { filepicker } = this.refs
    this.setState && this.setState({ file: '' })
    onPhotoRemoved()
    filepicker.value = null
  }

  _renderDeleteFileButton = () => {
    return (
      <Button
        buttonType='primary'
        onClick={this._onDeleteClick}
      >
        {translations('Delete File')}
      </Button>
    )
  }

  _renderButtonCordova = () => {
    const { classes, buttonSpacing } = this.props
    if (this.state.file) {
      this._renderDeleteFileButton()
    }
    const buttonClasses = cx(classes.submitButtonHalf, {
      [classes.buttonSpace]: buttonSpacing
    })
    return (
      <div className={classes.buttonContainer}>
        <Button
          className={classes.submitButtonHalf}
          buttonType='primary'
          onClick={this._openImagePickerCamera}
        >
          <div className={classes.submitButtonLabelContainer}>
            <img className={classes.submitButtonLabelIcon} src={getImage('camera')} />
            <div className={classes.submitButtonLabelText}>
              {translations('Take Photo')}
            </div>
          </div>
        </Button>
        <Button
          className={buttonClasses}
          buttonType='primary'
          onClick={this._openImagePickerLibrary}
        >
          <div className={classes.submitButtonLabelContainer}>
            <img className={classes.submitButtonLabelIcon} src={getImage('addFromGallery')} />
            <div className={classes.submitButtonLabelText}>
              {translations('Add from Gallery')}
            </div>
          </div>
        </Button>
        <Button
          className={classes.submitButtonHalf}
          buttonType='primary'
          onClick={this.openFilePickerWeb}
        >
          <div className={classes.submitButtonLabelContainer}>
            <img className={classes.submitButtonLabelIcon} src={getImage('pdfIcon')} />
            <div className={classes.submitButtonLabelText}>
              {translations('Add Generic File')}
            </div>
          </div>
        </Button>
      </div>
    )
  }

  _openImagePickerLibrary = () => fileUploadService.openImagePickerLibrary(this.fileSelectedCordova)
  _openImagePickerCamera = () => fileUploadService.openImagePickerCamera(this.fileSelectedCordova)

  _renderButtonWeb = () => {
    if (this.state.file) {
      return this._renderDeleteFileButton()
    } else {
      return (
        <Button
          buttonType='primary'
          onClick={this.openFilePickerWeb}
        >
          {translations('Add Image Web')}
        </Button>
      )
    }
  }

  renderButton = () => {
    if (window.cordova) {
      return this._renderButtonCordova()
    } else {
      return this._renderButtonWeb()
    }
  }

  render () {
    const {
      classes,
      className
    } = this.props
    const acceptedFileTypes = ['application/pdf']

    if (!window.cordova) {
      acceptedFileTypes.push('image/*')
    }

    return (
      <div className={className}>
        {this.renderButton()}
        <input
          type='file'
          ref={'filepicker'}
          accept={acceptedFileTypes.join(',')}
          className={classes.filePicker}
          onChange={this.fileSelectedWeb}
        />
      </div>
    )
  }
}

export default withStyles(style)(UploadFile)
