import React from 'react'
import styled from 'styled-components'
import CircularProgress from '@material-ui/core/CircularProgress'

import { translations } from '../../../../config'

const Container = styled.div`
  min-height: calc(100vh - 50px - env(safe-area-inset-top));
  background-color: #F3F3F3;
  color: #340C0C;
  flex: 1;
  justify-content: center;
  align-items: center;
  display: flex;
  text-align: center;
  padding: 0 20px;
  flex-direction: column;
  > div { max-width: 700px; }
`

const WaitingRoom = () => {
  return (
    <Container>
      <div>
        <CircularProgress size={25} />
        <p style={{ fontWeight: 'bold' }}>{translations('Waiting room - headline')}</p>
        <p>{translations('Waiting room - line 1')}</p>
        <p>{translations('Waiting room - line 2')}</p>
        <p>{translations('Waiting room - line 3')}</p>
      </div>
    </Container>
  )
}

export default WaitingRoom
