import _ from 'lodash'

import * as constants from './constants'
import { selectors as authSelectors } from '../auth'
import { selectors as storeDetailsSelectors } from '../storeDetails'
import digitalStoreSdk from '../../../digitalStoreSdk'
import { apiUpdateSource } from '../../../config'

class CreateMessageActions {
  updateReceipient = (customerObject) => ({
    type: constants.UPDATE_RECIPIENT,
    customerObject
  })

  resetStore = () => ({
    type: constants.RESET_STORE
  })

  updateMessage = message => ({
    type: constants.UPDATE_MESSAGE,
    message
  })
  addContentToMessage = message => ({
    type: constants.ADD_CONTENT_TO_MESSAGE,
    message
  })

  getMessagePreview = ({ message, recipients = [], storeId }) => {
    const firstRecipient = _.first(recipients)
    return {
      type: constants.PREVIEW_MESSAGE,
      promise: (dispatch, getState) => {
        const currentState = getState()
        const store = storeDetailsSelectors.getStore(currentState)

        const messageTemplateVariables = {
          customer: firstRecipient,
          store: store,
          messageBody: message.text,
          subject: message.subject,
          messageProducts: _.filter(message.content, c => c.type === 'product') || [],
          messageImages: _.filter(message.content, c => c.type === 'image') || [],
          messageInspirations: _.filter(message.content, c => c.type === 'inspiration') || [],
        }

        return digitalStoreSdk.messages.getMessagePreview(message.template, messageTemplateVariables)
      }
    }
  }

  sendMessage = ({ message, recipients, storeId }) => {
    const firstRecipient = recipients[0]
    const isEmail = message.communicationType === 'email'
    const reqParams = {
      updateSource: apiUpdateSource,
      communicationType: message.communicationType,
      customerId: firstRecipient.id,
      // to: isEmail ? firstRecipient.email : firstRecipient.telephone,
      storeId,
      details: {
        text: message.text,
        content: message.content,
        templateId: message.template,
        other: _.get(message, 'communicationTypeOther', '')
      }
    }
    if (isEmail) {
      reqParams.details.subject = message.subject
    }
    return {
      type: constants.SEND_MESSAGE,
      promise: (dispatch, getState) => {
        const userId = authSelectors.getActiveUserId(getState())
        return digitalStoreSdk.messages
          .sendMessage({ ...reqParams, userId })
      }
    }
  }

  setPreviousPath = path => ({
    type: constants.SET_PREVIOUS_PATH,
    path
  })

  fetchMessageTemplates = () => ({
    type: constants.FETCH_MESSAGE_TEMPLATES,
    promise: () => digitalStoreSdk.messages.fetchMessageTemplates({ commsType: 'message' })
  })
}

export default new CreateMessageActions()
