import React, { useState, useEffect } from 'react'
import _ from 'lodash'
import { useDispatch } from 'react-redux'
import { loadStripe } from '@stripe/stripe-js'
import {
  CardElement,
  Elements,
  useStripe,
  useElements
} from '@stripe/react-stripe-js'
import styled from 'styled-components'

import { FormSubmit } from '../../../../components/Form'

const CARD_ELEMENT_OPTS = {
  style: {
    base: {
      color: '#390B09',
      fontFamily: 'sans-serif',
      fontSmoothing: 'antialiased',
      fontSize: '14px',
      '::placeholder': {
        color: 'rgba(57, 11, 9, 0.6)'
      }
    },
    invalid: {
      color: '#fa755a',
      iconColor: '#fa755a'
    }
  },
  hidePostalCode: true
}

const Label = styled.label`
  font-family: 'Nunito', 'Helvetica', 'Arial', sans-serif;
  font-size: 12px;
  color: #390B09;
  opacity: 0.6;
`

const Form = styled.form`
  display: flex;
  justify-content: center;
  align-items: flex-start;
  flex-direction: column;
  margin-bottom: 1em;

  #card-element {
    margin: 5px 0 10px 0;
    padding: 10px 16px;
    background-color: white;
    border-radius: 5px;
    box-shadow: 0 0 20px 0 rgba(133,109,109,0.2);
    width: 100%;
  }
`

const Errors = styled.div`
  color: #fa755a;
  margin-bottom: 10px;
  width: 100%;
  text-align: center;
`

const PaymentForm = ({ orderTotal, paymentIntentToken, onSuccess, onError }) => {
  const [paymentSubmitting, setPaymentSubmitting] = useState(false)
  const [error, setError] = useState(null)
  const dispatch = useDispatch()
  const stripe = useStripe()
  const elements = useElements()

  const handleSubmit = async (event) => {
    event.preventDefault()
    setPaymentSubmitting(true)
    stripe.confirmCardPayment(paymentIntentToken, {
      payment_method: {
        card: elements.getElement(CardElement)
      }
    })
      .then(({ paymentIntent, error }) => {
        setPaymentSubmitting(false)
        if (error) {
          onError(error)
          setError(error.message)
        } else {
          onSuccess(paymentIntent)
        }
      })
  }

  const handleChange = (event) => {
    if (event.error) {
      setError(event.error.message)
    } else {
      setError(null)
    }
  }

  return (
    <Form onSubmit={handleSubmit}>
      <Label for="card-element">
        {'Credit or debit card'}
      </Label>
      <CardElement
        id="card-element"
        options={CARD_ELEMENT_OPTS}
        onChange={handleChange}
      />
      {error && (<Errors
        className="card-errors"
        role="alert"
      >
        {error}
      </Errors>)}
      <FormSubmit
        disabled={!stripe || error}
        fullWidth
        color={'primary'}
        variant={'contained'}
        submitting={paymentSubmitting}
        noContainer
        customStyle
      >
        {`Pay ${orderTotal}`}
      </FormSubmit>
    </Form>
  )
}

const StripePayment = (props) => {
  const [stripePromise, setStripePromise] = useState()

  const stripePublicKey = props.stripeKey

  useEffect(() => {
    setStripePromise(() => loadStripe(stripePublicKey))
  }, [stripePublicKey])

  if (!stripePublicKey) {
    return null
  }

  return (
    <Elements stripe={stripePromise || null}>
      <PaymentForm {...props} />
    </Elements>
  )
}

export default StripePayment
