import { colors } from '../../../config/theme'

const styles = theme => ({
  commentsContainer: {
    padding: 15
  },
  accordionContent: {
    padding: '15px 15px 5px 15px',
    boxShadow: '0 1px 5px 0 rgba(0,0,0,0.1)'
  },
  commentDetail: {
    marginBottom: 10
  },
  questionContainer: {
    padding: 15,
    borderBottom: `2px solid ${colors.lightGrey}`,
    marginBottom: 15
  },
  header: {
    backgroundColor: colors.whiteText,
    borderBottom: `1px solid ${colors.lightGrey}`
  }
})

export default styles
