import React, { useState, useMemo } from 'react'
import Autocomplete from '@material-ui/lab/Autocomplete'
import TextField from '@material-ui/core/TextField'
import Input from '../../Fields/Input'

const AutoCompleteField = ({
  options,
  label,
  onChange,
  value,
  passedProps,
  ...props
}) => {
  const handleOnChange = (event, newValue) => {
    onChange(newValue)
  }
  return (
    <Autocomplete
        value={value}
        inputValue={value}
        id={passedProps.name}
        freeSolo
        options={options}
        onChange={handleOnChange}
        onInputChange={handleOnChange}
        renderInput={(params) => (
          <TextField {...params} label={passedProps.label} />
        )}
      />
  )
}

export default AutoCompleteField