import { colors } from '../../../config/theme'

const style = {
  container: {
    display: 'flex',
    padding: '10px',
    borderBottom: `1px solid ${colors.activityBorderGrey}`,
    cursor: 'pointer',
    '&:hover': {
      backgroundColor: colors.tableHoverGrey
    }
  },
  leftContainer: {
    width: 50,
    display: 'flex',
    justifyContent: 'center'
  },
  imageContainer: {
    display: 'flex',
    alignItems: 'center',
    justifyContent: 'center',
    height: 50,
    width: 50,
    padding: 5,
    border: `1px solid ${colors.alphabetListGrey}`
  },
  circleImageContainer: {
    display: 'flex',
    alignItems: 'center',
    justifyContent: 'center',
    background: colors.lightGrey,
    borderRadius: 20,
    height: 38,
    width: 38
  },
  image: {
    height: '100%'
  },
  content: {
    flex: 1,
    paddingLeft: 10,
    overflow: 'hidden'
  },
  nameText: {
    overflow: 'hidden',
    textOverflow: 'ellipsis',
    whiteSpace: 'nowrap',
    marginBottom: 3
  },
  bodyText: {
    color: colors.chartLegendsGrey,
    fontSize: 11,
    fontWeight: 'bold',
    lineHeight: '15px',
    marginBottom: 3
  }
}

export default style
