import _ from 'lodash'
import PermissionsDeniedModal from './ErrorModals/PermissionsDeniedModal'
import UnsupportedBrowserModal from './ErrorModals/UnsupportedBrowserModal'

import toastService from '../../../../services/toastService'
import modalService from '../../../../services/modalService'

export const DEFAULT_VIDEO_CONSTRAINTS = {
  width: 720,
  height: 720,
  frameRate: 24
}

// These are used to store the selected media devices in localStorage
export const SELECTED_AUDIO_INPUT_KEY = 'TwilioVideoApp-selectedAudioInput'
export const SELECTED_AUDIO_OUTPUT_KEY = 'TwilioVideoApp-selectedAudioOutput'
export const SELECTED_VIDEO_INPUT_KEY = 'TwilioVideoApp-selectedVideoInput'

// This is used to store the current background settings in localStorage
export const SELECTED_BACKGROUND_SETTINGS_KEY = 'TwilioVideoApp-selectedBackgroundSettings'

export const getIsRoomNotInitialised = (room, appointmentId) =>
  !room ||
  _.get(room, 'name') !== appointmentId ||
  _.get(room, 'name') === 'undefined' ||
  _.get(room, 'name') === 'null' ||
  _.get(room, 'name') === undefined ||
  _.get(room, 'name') === null

export const showPermissionsDeniedModal = () => {
  modalService.open({
    component: PermissionsDeniedModal,
    modalIndex: 1
  })
}

export const showUnsupportedBrowserModal = () => {
  modalService.open({
    component: UnsupportedBrowserModal,
    modalIndex: 1
  })
}

export const notify = ({ type, message }) => {
  toastService.action({
    type,
    message,
    verticalPosition: 'top',
    horizontalPosition: 'right'
  })
}

export const getDeviceInfo = async () => {
  const devices = _.get(navigator, 'mediaDevices')
    ? await navigator.mediaDevices.enumerateDevices()
    : []

  return {
    audioInputDevices: devices.filter(device => device.kind === 'audioinput'),
    videoInputDevices: devices.filter(device => device.kind === 'videoinput'),
    audioOutputDevices: devices.filter(device => device.kind === 'audiooutput'),
    hasAudioInputDevices: devices.some(device => device.kind === 'audioinput'),
    hasVideoInputDevices: devices.some(device => device.kind === 'videoinput')
  }
}

// This function will return 'true' when the specified permission has been denied by the user.
// If the API doesn't exist, or the query function returns an error, 'false' will be returned.
export async function isPermissionDenied(name) {
  if (navigator.permissions) {
    try {
      const result = await navigator.permissions.query({ name })
      return result.state === 'denied'
    } catch {
      return false
    }
  } else {
    return false
  }
}
