import { colors } from '../../../../config/theme'

const styles = theme => ({
  content: {
    position: 'relative'
  },
  containerWeekly: {
    borderTop: `3px solid ${colors.pollQuestionWeekly}`,
    backgroundColor: '#FFF'
  },
  containerMonthly: {
    borderTop: `3px solid ${colors.pollQuestionMonthly}`,
    backgroundColor: '#FFF'
  },
  boxShadow: {
    boxShadow: '0 1px 5px 0 rgba(0,0,0,0.1)'
  },
  maxheight: {
    height: 250,
    maxHeight: 250,
    overflow: 'hidden'
  },
  headerContainer: {
    borderBottom: `2px solid ${colors.lightGrey}`
  },
  header: {
    padding: 15
  },
  answerContainer: {
    padding: 15
  },
  commentContainer: {
    padding: 20
  },
  buttonContainer: {
    display: 'flex',
    flexDirection: 'row',
    padding: 15
  },
  buttonCell: {
    flex: 1
  },
  button: {
    width: '100%',
    border: `1px solid ${'#B7B7B7'}`
  },
  buttonText: {
    color: colors.text
  },
  submittingOverlay: {
    backgroundColor: colors.lightGrey,
    position: 'absolute',
    opacity: 0.3,
    top: 0,
    bottom: 0,
    left: 0,
    right: 0
  },
  submittedOverlay: {
    backgroundColor: colors.lightGrey,
    position: 'absolute',
    top: 0,
    bottom: 0,
    left: 0,
    right: 0
  }
})

export default styles
