import React, { useState, Fragment } from 'react'
import styled from 'styled-components'
import { useDispatch, useSelector } from 'react-redux'
import ExpandMoreIcon from '@material-ui/icons/ExpandMore'
import ExpandLessIcon from '@material-ui/icons/ExpandLess'

import CustomerDetailsPanelItem from '../../Consultations/CustomerDetailsPanel'
import Button from '../../../components/Button'

import { translations } from '../../../config'
import media from '../../../config/media'

import { selectors as currentAppointmentSelectors } from '../../../store/modules/currentAppointment'

const CustomerNotesButton = styled.div`
  display: block;
  padding: 1rem 1rem 0 1rem;
  ${media.greaterThan('md')`
    display: none;
  `}
`

// height is same as used for the video
const CustomerDetailsContainer = styled.div`
  width: 100%;
  ${media.greaterThan('md')`
    width: 33%;
    min-width: 250px;
    height: ${props => props.fixedHeight ? 'calc(100vh - 75px - 48px - 180px)' : 'auto'};
    overflow: scroll;
  `}
`

const CustomerNotesButtonInner = styled.div`
  background-color: #F5F3F3;
  padding: 1rem;
  text-align: left;
  display: flex;
  justify-content: space-between;
`

const CustomerDetailsPanel = ({ fixedHeight }) => {
  const [customerDetailsOpen, setCustomerDetailsOpen] = useState(false)
  const customerId = useSelector(currentAppointmentSelectors.getAppointmentCustomerId)

  return (
    <Fragment>
      <CustomerNotesButton>
        <Button fullWidth onClick={() => setCustomerDetailsOpen(!customerDetailsOpen)}>
          <CustomerNotesButtonInner>
            {translations('Customer notes')}
            <div>
              {customerDetailsOpen ? <ExpandMoreIcon /> : <ExpandLessIcon />}
            </div>
          </CustomerNotesButtonInner>
        </Button>
      </CustomerNotesButton>
      <CustomerDetailsContainer fixedHeight={fixedHeight}>
        <CustomerDetailsPanelItem customerId={customerId} mobileOpen={customerDetailsOpen} />
      </CustomerDetailsContainer>
    </Fragment>
  )
}

export default CustomerDetailsPanel
