import React from 'react'
import styled from 'styled-components'
import Image from '../../../../../components/Image'
import CloseIcon from '@material-ui/icons/Close'
import IconButton from '@material-ui/core/IconButton'

const Container = styled.div`
  position: relative;
`

const CloseButton = styled(IconButton)`
  position: absolute;
  top: 0;
  right: 0;
`

const Img = styled(Image)`
  max-width: 100%;
  height: 100vh;
`

const LargeImageModal = ({ onClose, src }) => {
  return (
    <Container>
      <CloseButton onClick={onClose}>
        <CloseIcon />
      </CloseButton>
      <Img src={src} />
    </Container>
  )
}

export default LargeImageModal
