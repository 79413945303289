import React, { Component } from 'react'
import PropTypes from 'prop-types'
import _ from 'lodash'
import { connect } from 'react-redux'
import { getFormValues } from 'redux-form'

import { selectors as storesSelectors } from '../../../../store/modules/stores'
import CommunicationOptions from './CommunicationOptions'

class CommunicationOptionsContainer extends Component {
  _generateProps = () => {
    const initialValuesKeys = ['customerMarketingChannels', 'customerMarketingPreferences', 'marketingStoreIds', 'id']
    let initialValues = _.pick(this.props.initialValues, initialValuesKeys)
    // make sure it is an empty array at least - material ui breaks otherwise
    initialValues.marketingStoreIds = initialValues.marketingStoreIds || []
    return {
      ...this.props,
      initialValues,
      extraProps: {
        storeMarketingValue: !!this.props.storeMarketingValue
      }
    }
  }

  render () {
    const props = this._generateProps()
    return (
      <CommunicationOptions {...props} />
    )
  }
}

CommunicationOptionsContainer.propTypes = {
  initialValues: PropTypes.shape({})
}

const mapStateToProps = (state, ownProps) => {
  const formValues = getFormValues(ownProps.formId)(state)
  const customerMarketingPreferences = _.get(formValues, ['customerMarketingPreferences'], [])
  const storeMarketingValue = customerMarketingPreferences.find(item => item === 'storeMarketing')
  const allStoresOptions = storesSelectors.getAllStoresAsOptions(state)
  return {
    storeMarketingValue,
    allStoresOptions
  }
}

export default connect(mapStateToProps)(CommunicationOptionsContainer)
