import uuid from 'uuid/v4'
import _ from 'lodash'
import moment from 'moment'
import { amendOrder } from '@redant/digital-store-prices-beaverbrooks'
import { logger } from '../../helpers'
import { constants as currentOrderConstants } from '../../store/modules/currentOrder'

import digitalStoreSdk from '../../digitalStoreSdk'

import { dateFormat, apiUpdateSource } from '../../config'

const uploadOrder = params => {
  params = amendOrder(params)

  const products = _.chain(params)
    .get('products', [])
    .map(product => {
      const orderProduct = {
        ..._.pick(product, [
          'brand',
          'categoryId',
          'discount',
          'externalProductId',
          'vatPercent',
          'images',
          'link',
          'manualDiscount',
          'name',
          'preview',
          'price',
          'subTotal',
          'total',
          'tax',
          'unsold',
          'service',
          'clientId',
          'refund',
          'hasBeenRefunded',
          'metadata'
        ]),
        id: uuid(),
        productId: product.id,
        variant: _.pick(product.variant, [
          'name',
          'ean',
          'details',
          'id'
        ])
      }
      return orderProduct
    })
    .value()

  const status = params.status || 'pending'

  const salesChannel = 'Store'

  let orderDateISOString = moment(params.orderDate, dateFormat).toISOString()
  // if orderDate is today, set it to the exact time of right NOW
  if (moment(orderDateISOString).isSame(moment(), 'day')) {
    orderDateISOString = moment().toISOString()
  } else if (!params.orderDate) {
    orderDateISOString = moment().toISOString() // default to now
  }
  // anon
  if (!_.get(params, 'customerId')) {
    params = { ...params, deliveryType: null, deliveryOption: null, deliveryDetails: null, deliveryAddress: null, deliveryPrice: null }
  }

  const details = { ...params.details }
  if (params.storeroomOrderId) details.storeroomOrderId = params.storeroomOrderId

  const uploadParams = {
    ..._.pick(params, [
      'customerId',
      'deliveryAddress',
      'deliveryDetails',
      'deliveryOption',
      'paymentDeviceId',
      'deliveryType',
      'id',
      'orderDate',
      'orderType',
      'paymentToken',
      'storeId',
      'subTotal',
      'tax',
      'total',
      'totalDiscount',
      'userId'
    ]),
    products,
    status,
    salesChannel,
    orderDate: orderDateISOString,
    details
  }

  if (params.editMode) {
    const editOrder = params.editType === currentOrderConstants.EDIT_ORDER_TYPES.EXCHANGE
      ? digitalStoreSdk.orders.exchangeOrder
      : digitalStoreSdk.orders.editOrder
    return editOrder({ ...uploadParams, orderNumber: params.orderNumber, updateSource: apiUpdateSource })
      .then((res) => digitalStoreSdk.orders.fetchOrder({ id: res.orderNumber, includes: ['customer', 'user'] }))
  }
  return digitalStoreSdk.orders.createOrder({ ...uploadParams, updateSource: apiUpdateSource })
}

export default uploadOrder
