import React, { PureComponent } from 'react'
import PropTypes from 'prop-types'
import { withStyles } from '@material-ui/core/styles'
import _ from 'lodash'
import FormControl from '@material-ui/core/FormControl'
import Dropdown from '../../../components/Fields/Dropdown'

import { translations, wishlistFilters } from '../../../config'

import BackBar from '../../../components/BackBar'
import SubHeader from '../../../components/SubHeader'
import Heading from '../../../components/Heading'

import FullScreenProgress from '../../../components/FullScreenLoader'
import Waitlist from '../../../components/Waitlist'

import style from './style'

class ProductWaitlistScreen extends PureComponent {
  
  sortWaitlist = _.debounce(({ sort }) => {
    let iteratees
    let field1
    let field2
    let sortedWaitlist
    switch (sort) {
      case 'NAME_ASC':
        field1 = obj => _.get(obj, 'customer.lastName') ?  _.get(obj, 'customer.lastName') : ''
        field2 = obj => _.get(obj, 'customer.firstName') ?  _.get(obj, 'customer.firstName') : ''
        sortedWaitlist = _.orderBy(this.props.waitlist, [field1, field2], ['asc', 'asc'])
        this.setState({ sortedWaitlist })
        break
      case 'NAME_DESC':
        field1 = obj => _.get(obj, 'customer.lastName') ?  _.get(obj, 'customer.lastName') : ''
        field2 = obj => _.get(obj, 'customer.firstName') ?  _.get(obj, 'customer.firstName') : ''
        sortedWaitlist = _.orderBy(this.props.waitlist, [field1, field2], ['desc', 'desc'])
        this.setState({ sortedWaitlist })
        break
      case 'ADDED_ASC':
        iteratees = obj => _.get(obj, 'createdAt') || _.get(obj, 'createdAt') 
        sortedWaitlist = _.orderBy(this.props.waitlist, iteratees, 'asc')
        this.setState({ sortedWaitlist })
        break
        case 'ADDED_DESC':
        iteratees = obj => _.get(obj, 'createdAt') || _.get(obj, 'createdAt')
        sortedWaitlist = _.orderBy(this.props.waitlist, iteratees, 'desc')
        this.setState({ sortedWaitlist })
        break
      case 'SPEND_ASC':
        iteratees = obj => _.get(obj, 'customer.reporting.lifetime') ? _.get(obj, 'customer.reporting.lifetime.profit.totalSpend.value') : 0
        sortedWaitlist = _.orderBy(this.props.waitlist, iteratees, 'asc')
        this.setState({ sortedWaitlist })
        break
      case 'SPEND_DESC':
        iteratees = obj => _.get(obj, 'customer.reporting.lifetime') ? _.get(obj, 'customer.reporting.lifetime.profit.totalSpend.value') : 0
        sortedWaitlist = _.orderBy(this.props.waitlist, iteratees, 'desc')
        this.setState({ sortedWaitlist })
        break
      default:
        iteratees = obj => _.get(obj, 'customer.createdAt', '') || _.get(obj, 'customer.createdAt')
        sortedWaitlist = _.orderBy(this.props.waitlist, iteratees, 'asc')
        this.setState({ sortedWaitlist })
        break
    }
  }, 300)

  state = {
    sort: 'SPEND_DESC',  
    sortedWaitlist: this.sortWaitlist({ sort: 'SPEND_DESC' })
  }

  updateFilter = (e) => {
    const sort = e.target.value
    this.sortWaitlist({sort})
    this.setState({sort})
  }

  render () {
    const { classes, loadMore, hasMore, onClickDelete, onClickSendMessage } = this.props
    const waitlist = this.state.sortedWaitlist
    return (
      <div className={classes.root}>
        <SubHeader
          leftContent={(
            <BackBar />
          )}
          centerContent={(
            <Heading uppercase component={'h1'}>{translations('Waitlist')}</Heading>
          )}
          rightContent={(
            <div className={classes.filterContainer}>
                <Dropdown
                  label={!this.state.sort ? translations('Sort') : undefined}
                  value={this.state.sort === null ? '' : this.state.sort}
                  options={wishlistFilters}
                  onChange={this.updateFilter}
                  noErrorTextLabel
                  noNullOption
                />
              </div>
          )}
        />
        <Waitlist
          loadMore={loadMore}
          hasMore={hasMore}
          waitlist={waitlist}
          sort={this.state.sort}
          onChange={this.updateFilter}
          onClickDelete={onClickDelete}
          onClickSendMessage={onClickSendMessage}
        />
      </div>
    )
  }
}

ProductWaitlistScreen.propTypes = {
  getProductWaitlist: PropTypes.func.isRequired
}

export default FullScreenProgress(
  withStyles(style)(ProductWaitlistScreen)
)
