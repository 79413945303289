import { colors } from '../../../config/theme'

export default (theme) => ({
  container: {
    display: 'flex',
    flex: 1,
    minWidth: 0
  },
  inputsContainer: {
    flex: 1,
    display: 'flex',
    flexDirection: 'row',
  },
  dropdownContainer: {
    flex: 1,
    '&:first-child': {
      marginRight: 10
    }
  },
  dropdown: {
    width: '100%'
  },
  floatingLabelFocusStyle: {
    color: colors.accent
  }
})
