import { colors } from '../../../config/theme'

const styles = theme => ({
  container: {
    position: 'fixed',
    top: 0,
    right: 0,
    bottom: 0,
    left: 0,
    zIndex: 1,
    display: 'flex',
    flexDirection: 'column',
    flexWrap: 'nowrap'
  },
  header: {
    padding: 17,
    backgroundColor: colors.white,
    color: '#340C00',
    borderBottom: `0.5px solid ${colors.border}`,
    fontWeight: 500,
    textTransform: 'uppercase',
    display: 'flex',
    justifyContent: 'center',
    alignItems: 'center',
    flexShrink: 0
  },
  listContainer: {
    flexGrow: 1,
    overflow: 'auto',
    '-webkit-overflow-scrolling': 'touch'
  },
  productGrid: {
    display: 'flex',
    flex: 1,
    flexWrap: 'wrap',
    padding: 15,
    alignItems: 'flex-end'
  },
  footer: {
    flexShrink: 0
  },
  button: {
    margin: 'auto'
  },
  buttonContainer: {
    borderTop: `1px solid ${colors.mediumGrey}`,
    background: colors.lightGrey,
    padding: 20
  }
})

export default styles
