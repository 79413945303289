import * as constants from './constants'
import digitalStoreSdk from '../../../digitalStoreSdk'
import { salesforceOCAPI } from '../../../config'

class CheckoutFlowActions {
  next = () => ({
    type: constants.NEXT
  })

  start = ({ checkoutType }) => ({
    type: constants.START,
    checkoutType,
    checkoutOpen: true
  })

  end = () => ({
    type: constants.END
  })

  back = () => ({
    type: constants.BACK
  })

  clear = () => ({
    type: constants.CLEAR
  })

  fail = (code) => ({
    type: constants.FAIL,
    code
  })

  setCurrent = (module) => ({
    type: constants.SET_CURRENT,
    module
  })

  setLoading = (isLoading) => ({
    type: constants.SET_LOADING,
    isLoading
  })

  setOrderNumber = (orderNumber) => ({
    type: constants.SET_ORDER_NUMBER,
    orderNumber
  })

  setDeferredModule = module => ({
    type: constants.SET_DEFERRED_MODULE,
    module
  })

  setOrderId = (orderId) => ({
    type: constants.SET_ORDER_ID,
    orderId
  })

  fetchDeliveryOptions = (basketId) => ({
    type: constants.FETCH_DELIVERY_OPTIONS,
    promise: () => basketId && salesforceOCAPI.basketEnabled
      ? digitalStoreSdk.baskets.fetchBasketShippingMethods({ id: basketId })
      : digitalStoreSdk.deliveryOptions.fetchDeliveryOptions()
  })

  setPreviousPath = (previousPath) => ({
    type: constants.SET_PREVIOUS_PATH,
    previousPath
  })
}

export default new CheckoutFlowActions()
