import { connect } from 'react-redux'
import { withHandlers, compose } from 'recompose'
import { selectors as authSelectors } from '../../../../store/modules/auth'
import { selectors as storeDetailsSelectors, actions as storeDetailsActions } from '../../../../store/modules/storeDetails'

import RetailAnalyticsFilters from './RetailAnalyticsFilters'

const mapStateToProps = state => {
  const store = storeDetailsSelectors.getStore(state)
  const storeOptions = authSelectors.getUserStoresOptions(state)
  const storeValue = storeDetailsSelectors.getStoreId(state)
  const viewOptions = storeDetailsSelectors.getViewOptions(state)
  const viewValue = storeDetailsSelectors.getViewValue(state)

  return {
    store,
    storeOptions,
    storeValue,
    viewOptions,
    viewValue
  }
}

const mapDispatchToProps = dispatch => {
  const fetchStore = id => dispatch(storeDetailsActions.fetchStore({ id, includes: ['users'] }))
  const setView = id => dispatch(storeDetailsActions.setView(id))

  return {
    fetchStore,
    setView
  }
}

export default compose(
  connect(mapStateToProps, mapDispatchToProps),
  withHandlers({
    onChangeStore: ({ fetchStore }) => e => {
      const id = e.target.value
      fetchStore(id)
    },
    onChangeView: ({ setView }) => e => {
      const id = e.target.value
      setView(id)
    }
  })
)(RetailAnalyticsFilters)
