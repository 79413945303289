import React, { Component } from 'react'
import PropTypes from 'prop-types'
import { Link } from 'react-router-dom'
import { withStyles } from '@material-ui/core/styles'

import style from './style'
import { assets, translations, auth, loginTypes, forgotPasswordFormEnabled } from '../../../config'
import MainLogo from '../../../components/MainLogo'
import P from '../../../components/P'
import Heading from '../../../components/Heading'
import ForgotPasswordForm from '../ForgotPasswordForm'

const forgotPasswordText = translations(
  'Forgot Password Instruction',
  { loginType: auth.loginType === loginTypes.USERNAME ? 'username' : 'email' }
)

class ForgotPasswordScreen extends Component {
  state = {
    submitted: false
  }

  onSubmissionComplete = () => {
    this.setState({ submitted: true })
  }

  renderContent = () => {
    const { classes } = this.props
    if (this.state.submitted) {
      return <div>
        <div className={classes.checkContainer}>
          <img className={classes.logo} src={assets.checkcircle} />
        </div>
        <div className={classes.header}>
          <Heading component={'h2'}>{translations('Submitted')}</Heading>
        </div>
        <div className={classes.textContainer}>
          <p>{translations('An email has been sent to the email address associated with your username.')}</p>
        </div>
      </div>
    } else {
      return <div>
        <div className={classes.textContainer}>
          <P value={forgotPasswordText} />
        </div>
        {
          forgotPasswordFormEnabled
          ? <ForgotPasswordForm onSubmissionComplete={this.onSubmissionComplete} />
          : null
        }
      </div>
    }
  }

  render = () => {
    const { classes } = this.props
    return (
      <div className={classes.container}>
        <div className={classes.formContainer}>
          <MainLogo />
          {this.renderContent()}
          <div className={classes.forgotLinkWrapper}>
            <Link to='/'>
              <P value={translations('Back')} />
            </Link>
          </div>
        </div>
      </div>
    )
  }
}

export default withStyles(style)(ForgotPasswordScreen)
