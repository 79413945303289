import { colors } from '../../config/theme'

export default (theme) => ({
  columns: {
    display: 'grid',
    gridColumnGap: '20px'
  },
  column: {
  },
  columnContainer: {
    listStyle: 'none',
    margin: 0,
    padding: 0
  },
  columnTitleBox: {
    height: 55,
    display: 'flex',
    justifyContent: 'center',
    alignItems: 'center',
    border: `1px solid ${colors.border}`
  },
  columnTitle: {
    textAlign: 'center',
    textTransform: 'uppercase',
    fontSize: 13
  },
  ordersContainerMobile: {
    marginTop: 15
  },
  columnHeaders: {
    position: 'absolute',
    zIndex: 1,
    top: 0,
    left: 0,
    right: 0,
    overflowY: 'scroll',
    paddingBottom: '0 !important'
  },
  columnContents: {
    paddingTop: '0 !important',
    position: 'relative',
    zIndex: 0,
    overflowY: 'scroll',
    flex: 1
  },
  container: {
    position: 'relative',
    display: 'flex',
    flexDirection: 'column'
  }
})
