import { colors } from '../../../config/theme'

export default theme => ({
  container: {
    overflow: 'hidden',
    padding: 8
  },
  clickable: {
    display: 'flex',
    flex: '1 1 auto',
    cursor: 'pointer',
    margin: 0,
    padding: 0,
    '&:hover': {
      backgroundColor: colors.tableHoverGrey
    }
  },
  gridItem: {
    display: 'flex',
    padding: '8px'
  }
})
