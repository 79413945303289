import React from 'react'
import PropTypes from 'prop-types'
import IconButton from '@material-ui/core/IconButton'
import { withStyles } from '@material-ui/core/styles'

import BackIcon from '@material-ui/icons/ArrowBack'
import ForwardIcon from '@material-ui/icons/ArrowForward'
import RefreshIcon from '@material-ui/icons/Refresh'

import style from './style'
import { colors } from '../../../config/theme'

const HistoryNavigationHeader = ({ goBack, goForward, refresh, canGoback, invert, classes }) => {
  // mixture of classes and styles here
  // the conditional makes the pre loading of the classes difficult
  return (
    <div className={classes.container} style={{ backgroundColor: invert ? colors.offsetAccent : colors.accent }}>
      <IconButton onClick={goBack} disabled={!canGoback}>
        <BackIcon color={invert ? colors.accent : colors.offsetAccent} />
      </IconButton>
      <IconButton onClick={goForward}>
        <ForwardIcon color={invert ? colors.accent : colors.offsetAccent} />
      </IconButton>
      <IconButton onClick={refresh}>
        <RefreshIcon color={invert ? colors.accent : colors.offsetAccent} />
      </IconButton>
    </div>
  )
}
HistoryNavigationHeader.defaultProps = {
  invert: false
}
HistoryNavigationHeader.propTypes = {
  invert: PropTypes.bool,
  goBack: PropTypes.func.isRequired,
  goForward: PropTypes.func.isRequired,
  refresh: PropTypes.func.isRequired,
  canGoback: PropTypes.bool.isRequired
}

export default withStyles(style)(HistoryNavigationHeader)
