import { colors } from '../../../../config/theme'

export default theme => ({
  image: {
    height: 38,
    width: 38,
    border: `1px solid ${colors.border}`,
    backgroundSize: 'contain',
    backgroundRepeat: 'no-repeat',
    backgroundPosition: 'center center',
    backgroundColor: 'white'
  }
})
