const styles = theme => ({
  container: {
    display: 'flex',
    alignItems: 'center',
    flexDirection: 'column',
    marginBottom: '10px'
  },
  filterContainer: {
    position: 'relative',
    zIndex: 1,
    margin: 10,
    fontSize: '13px',
    minWidth: '70px',
    [theme.breakpoints.down('xs')]: {
      margin: 0
    },
    // backgroundColor: 'black'
  },
})

export default styles
