import { colors } from '../../../../../config/theme'

export default (theme) => ({
  container: {
    border: `1px solid ${colors.border}`,
    display: 'flex',
    flexDirection: 'row',
    justifyContent: 'space-between',
    [theme.breakpoints.up('sm')]: {
      padding: '13px 17px'
    },
    [theme.breakpoints.down('xs')]: {
      flexDirection: 'column',
      marginTop: 15,
      padding: '13px 15px'
    }
  },
  inputContainer: {
    flex: 1
  },
  formControl: {
    width: '100%',
    maxWidth: 400
  },
  input: {
    width: '100%'
  },
  buttonsContainer: {
    [theme.breakpoints.down('xs')]: {
      marginTop: 10
    }
  },
  label: {
    opacity: 0.5
  },
  noteNotEditing: {
    fontWeight: 'bold'
  }
})
