import React, { useState } from 'react'

import { VideoProvider } from './VideoProvider'
import VideoPanel from './VideoPanel'
import VideoHeaderControls from './VideoHeaderControls'
import VideoChat from './VideoChat'

const VideoApp = ({ isConsultant, appointmentId }) => {
  const [error, setError] = useState()
  return (
    <VideoProvider isConsultant={isConsultant} appointmentId={appointmentId} onError={setError}>
      <VideoPanel />
      <VideoChat />
      <VideoHeaderControls />
    </VideoProvider>
  )
}

export default VideoApp
