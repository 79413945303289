import _ from 'lodash'
import { withRouter } from 'react-router'
import { compose, withPropsOnChange, withHandlers } from 'recompose'
import { connect } from 'react-redux'
import RootScreen from './RootScreen'
import { actions as appActions } from '../../../store/modules/app'
import { selectors as authSelectors } from '../../../store/modules/auth'
import { selectors as networkSelectors } from '../../../store/modules/network'
import { actions as configActions } from '../../../store/modules/config'
import { apps } from '../../../config'
import { matchPath } from 'react-router'

const mapStateToProps = state => ({
  appsAllowedForRole: authSelectors.getAppsAllowedForRole(state),
  networkConnectivity: networkSelectors.isConnected(state),
  isLoggedIn: authSelectors.getIsLoggedIn(state)
})

const isUnsecuredPage = (currentPath, paths) => {
  return !_.find(paths, path => {
    return matchPath(currentPath, { path: path })
  })
}

export default compose(
  withRouter,
  connect(mapStateToProps),
  withPropsOnChange(['location'], ({ location }) => {
    const currentUrl = location.pathname
    console.log(location.pathname)
    return {
      isUnsecuredPage: isUnsecuredPage(currentUrl, ['/login', '/forgot-password', '/select-store', '/select-department', '/consultations/virtual/:id/customer'])
    }
  }),
  withPropsOnChange(['location', 'appsAllowedForRole'], ({ location, appsAllowedForRole }) => {
    // Find the app id we are on based on the current location
    const basePath = '/' + location.pathname.split('/')[1]
    const currentApp = Object.entries(apps).reduce((acc, [appId, appContent]) => {
      const path = _.get(appContent, 'action.payload.args.0')
      if (path === basePath) {
        return appId
      }
      return acc
    }, null)

    // If route does not match an app, then allow - as a fail-safe to not block all apps
    const appNotSpecified = typeof appsAllowedForRole[currentApp] === 'undefined'

    return {
      isAppAllowedForRole: appNotSpecified || appsAllowedForRole[currentApp]
    }
  }),
  withHandlers({
    fetchConfig: ({ dispatch }) => () => {
      dispatch(configActions.fetchConfig())
    }
  })
)(RootScreen)
