import React, { useEffect, Fragment, useRef, useCallback } from 'react'
import _ from 'lodash'
import { useGet } from 'react-pouchdb'
import { useDispatch, useSelector } from 'react-redux'
import styled from 'styled-components'
import { Helmet } from 'react-helmet'
import { useMediaQuery } from 'react-responsive'

import { actions as currentAppointmentActions, selectors as currentAppointmentSelectors } from '../../../../store/modules/currentAppointment'
import { actions as appointmentsActions, selectors as appointmentsSelectors } from '../../../../store/modules/appointments'
import { actions as currentOrderActions } from '../../../../store/modules/currentOrder'
import { actions as chatActions } from '../../../../store/modules/chat'

import WaitingRoom from './WaitingRoom'
import InvalidAppointment from './InvalidAppointment'

import ActiveResourcePanel from '../ActiveResourcePanel'
import VideoApp from '../VideoApp'

import PouchDBHOC from '../../../../components/PouchDB'

import { translations, theme, environment, assets, a11y } from '../../../../config'

const BrandHeaderContainer = styled.div`
  display: flex;
  justify-content: center;
  align-items: center;
  height: 50px;
  width: 100%;
  background-color: #f9f6f6;
  box-shadow: 0 4px 4px 0 rgba(133, 109, 109, 0.2);
  position: relative;
  z-index: 1;
`

const BrandLogoImage = styled.img`
  height: 25px;
  user-select: none;
`

const VideoPanelContainer = styled.div`
  display: flex;
  flex: 1;
  background: #F3F3F3;
  align-items: center;
  justify-content: center;
  height: ${props => props.isPortrait && !props.fullScreen ? `calc(100% - ${theme.headerHeight}px - 200px - 50px)` : `calc(100% - 50px)`};
  width: ${props => props.isPortrait || props.fullScreen ? '100vw' : 'calc(100vw - 360px)'};
  position: fixed;
`

const CustomerScreen = (props) => {
  const paramId = _.get(props, 'match.params.id')
  const appointmentByExternalId = useSelector(appointmentsSelectors.getAppointmentByExternalId(paramId))
  const appointmentId = _.get(appointmentByExternalId, 'id') || paramId
  const dispatch = useDispatch()
  const doc = useGet({ id: appointmentId })
  const appointment = useSelector(currentAppointmentSelectors.getCurrentAppointment)
  const couchbaseAppointmentId = _.get(appointment, 'id')
  const selectedResource = useSelector(currentAppointmentSelectors.getAppointmentShowingContent)
  const appointmentStage = useSelector(currentAppointmentSelectors.getAppointmentStage)
  const appointmentStatus = useSelector(currentAppointmentSelectors.getAppointmentStatus)
  const isAppointmentStatusComplete = appointmentStatus === 'COMPLETE'
  const drawerIsShowing = selectedResource || appointmentStage || isAppointmentStatusComplete
  const initialSync = useRef(true)
  const isPortrait = useMediaQuery({ query: '(orientation: portrait)' })

  /* The consultation will begin for the Customer once the Consultant
   * starts the Consultation and couchbase has synched the appointment data to the Customer's screen. */
  const appointmentStarted = !!couchbaseAppointmentId

  const showWaitingRoom = !appointmentStarted
  const showAppointmentEnded =
    appointmentStatus === 'COMPLETE' ||
    appointmentStatus === 'EXPIRED' ||
    appointmentStatus === 'CANCELLED'
  const showVideoPanel = !!couchbaseAppointmentId

  useEffect(() => {
    dispatch(appointmentsActions.fetchAppointment({ appointmentId, isConsultant: false }))
  }, [appointmentId])

  useEffect(() => {
    const {
      currentAppointment,
      currentOrder,
      chatChannelId,
      source
    } = _.get(doc, 'sharedViewState', {})
    if (doc && (initialSync.current || source !== 'public')) {
      if (initialSync.current) {
        console.log('Performing initialSync with Couchbase')
      }
      initialSync.current = false
      dispatch(currentAppointmentActions.setCurrentAppointment({ currentAppointment, source }))
      dispatch(currentOrderActions.setCurrentOrder({ currentOrder, source }))
      dispatch(chatActions.setChatChannelId({ channelId: chatChannelId }))
    }
  }, [doc, initialSync.current])

  const VideoPanelContent = useCallback(() => {
    if (showAppointmentEnded) {
      return <InvalidAppointment status={appointmentStatus} />
    } else if (showWaitingRoom) {
      return <WaitingRoom isCustomer />
    } else if (showVideoPanel) {
      return <VideoApp isConsultant={false} appointmentId={appointmentId} />
    } else {
      return <></>
    }
  }, [showAppointmentEnded, showWaitingRoom, showVideoPanel])

  return (
    <Fragment>
      <h1 className='visuallyhidden'>{translations('Virtual Consultation')}</h1>
      <Helmet><title>{translations('Virtual Consultation')}</title></Helmet>
      <div>
        <BrandHeaderContainer>
          <BrandLogoImage src={assets.loginLogo} alt={a11y.mainLogoAltText} />
        </BrandHeaderContainer>
        <VideoPanelContainer fullScreen={!drawerIsShowing} isPortrait={isPortrait}>
          <VideoPanelContent />
        </VideoPanelContainer>
        {drawerIsShowing && (<ActiveResourcePanel />)}
      </div>
    </Fragment>
  )
}

export default PouchDBHOC(CustomerScreen)({
  tableName: 'appointments'
})
