import { PENDING, SUCCESS, FAIL } from '../../middleware/redux-promise'
import * as constants from './constants'

class ProductsReducers {
  fetchProduct = (state, action) => {
    switch (action.status) {
      case PENDING:
        return this.fetchProductPending(state, action)
      case SUCCESS:
        return this.fetchProductSuccess(state, action)
      case FAIL:
        return this.fetchProductFail(state, action)
      default:
        return state
    }
  }

  fetchProductPending = (state, action) => {
    return {
      ...state,
      status: PENDING,
      action: action.type,
      productGroupProducts: [], // clear productGroupProducts as they may no longer match the fetched product
      product: {}
    }
  }

  fetchProductSuccess = (state, action) => {
    return {
      ...state,
      product: action.result,
      status: SUCCESS,
      action: undefined,
      error: undefined
    }
  }

  fetchProductFail = (state, action) => {
    return {
      ...state,
      status: FAIL,
      action: undefined,
      error: action.error.message
    }
  }

  fetchProductGroupProducts = (state, action) => {
    switch (action.status) {
      case PENDING:
        return this.fetchProductGroupProductsPending(state, action)
      case SUCCESS:
        return this.fetchProductGroupProductsSuccess(state, action)
      case FAIL:
        return this.fetchProductGroupProductsFail(state, action)
      default:
        return state
    }
  }

  fetchProductGroupProductsPending = (state, action) => {
    return {
      ...state,
      status: PENDING,
      action: action.type,
      productGroupProducts: []
    }
  }

  fetchProductGroupProductsSuccess = (state, action) => {
    return {
      ...state,
      productGroupProducts: action.result.results,
      status: SUCCESS,
      action: undefined,
      error: undefined
    }
  }

  fetchProductGroupProductsFail = (state, action) => {
    return {
      ...state,
      status: FAIL,
      action: undefined,
      error: action.error.message
    }
  }

  changeProductBottomTab = (state, action) => {
    return {
      ...state,
      productTab: action.productTab
    }
  }

  putActiveMediaIndex = (state, action) => {
    return {
      ...state,
      mediaIndex: action.mediaIndex
    }
  }

  resetActiveMediaIndex = (state, action) => {
    return {
      ...state,
      mediaIndex: 0
    }
  }

  resetBottomTabs = (state, action) => {
    return {
      ...state,
      productTab: constants.PRODUCT_DETAILS_EXPANDED
    }
  }
}

export default new ProductsReducers()
