import _ from 'lodash'
import React, { useCallback } from 'react'
import { useDispatch, useSelector } from 'react-redux'
import Chat from '../../../../components/Chat'
import { matchPath } from 'react-router'
import { selectors as currentAppointmentSelectors } from '../../../../store/modules/currentAppointment'
import { selectors as routingSelectors } from '../../../../store/modules/routing'
import { actions as chatActions, selectors as chatSelectors } from '../../../../store/modules/chat'

import useVideoContext from './hooks/useVideoContext'

const VideoChat = () => {
  const {
    screenLocalParticipantIdentity: userIdentity
  } = useVideoContext()

  const dispatch = useDispatch()
  const appointmentId = useSelector(currentAppointmentSelectors.getAppointmentId)
  const isAppointmentEnded = useSelector(currentAppointmentSelectors.getIsAppointmentEnded)
  const isAppointmentComplete = useSelector(currentAppointmentSelectors.getIsAppointmentComplete)
  const path = useSelector(routingSelectors.getLocationPathname)
  const consultantName = useSelector(currentAppointmentSelectors.getConsultantName)
  const customerName = useSelector(currentAppointmentSelectors.getCustomerName)
  const isVCPath = matchPath(path, { path: '/consultations/virtual/:id', exact: false })
  const isCheckoutPath = matchPath(path, { path: '/checkout', exact: false })
  const isAllowedForPath = !!isVCPath || !!isCheckoutPath
  const existingChannelId = useSelector(chatSelectors.getChatChannelId)
  // it the user is the customer and there is no existing channel, then do not render the chat component
  // this is to ensure the consultant always creates the channel, and is therefore the admin of the channel in twilio
  const isChatReady = userIdentity === 'consultant' || !!existingChannelId

  const onChannelJoined = useCallback(({ channelId }) => {
    dispatch(chatActions.channelJoined({ channelId }))
  }, [appointmentId])

  const senderName = _.includes(userIdentity, 'consultant') ? consultantName : customerName
  const recipiantName = _.includes(userIdentity, 'customer') ? consultantName : customerName

  return (isAllowedForPath && appointmentId && !isAppointmentEnded && !isAppointmentComplete && isChatReady)
    ? (<Chat
      channelId={appointmentId}
      userIdentity={userIdentity}
      onChannelJoined={onChannelJoined}
      senderName={senderName}
      recipiantName={recipiantName}
    />)
    : null
}

export default VideoChat
