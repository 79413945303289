import { connect } from 'react-redux'
import { compose, withHandlers } from 'recompose'
import _ from 'lodash'
import CustomerRegistrationForm from './CustomerRegistrationForm'
import { actions as customerDetailsActions } from '../../../../store/modules/customerDetails'
import { selectors as authSelectors } from '../../../../store/modules/auth'
import { selectors as appSelectors } from '../../../../store/modules/app'

const mapStateToProps = state => ({
  customerModeUpdate: appSelectors.getCustomerModeStatus(state),
  storeId: authSelectors.getUserSelectedStoreId(state),
  userId: authSelectors.getActiveUserId(state)
})

const addressKeys = ['postCode', 'address1', 'address2', 'city', 'county', 'country']

const enhance = withHandlers({
  onSubmit: props => data => {
    const {
      dispatch,
      submitAction = customerDetailsActions.createCustomer,
      storeId,
      userId,
      afterSubmit
    } = props
    const { postCode, marketingStoreIds, storeMarketing } = data
    const params = _.omit(data, [...addressKeys, 'terms', 'storeIds', 'addressSearch', 'marketingStoreIds', 'noPush'])
    if (postCode) {
      const address = _.pick(data, addressKeys)
      params.address = address
    }
    // do this check incase stores were selected but the store marketing was unchecked
    if (marketingStoreIds && marketingStoreIds.length && storeMarketing) {
      params.marketingStoreIds = marketingStoreIds
    }
    params.registeredById = userId
    params.registeredAtId = storeId
    return dispatch(submitAction(params))
      .then((result) => {
        if (_.isFunction(afterSubmit)) {
          afterSubmit(result)
        }
      })
  }
})

export default compose(
  connect(mapStateToProps),
  enhance
)(CustomerRegistrationForm)
