import { connect } from 'react-redux'
import _ from 'lodash'

import TitleBar from './TitleBar'
import { actions as productsActions, selectors as productsSelectors } from '../../../../../store/modules/products'
import { actions as currentOrderActions } from '../../../../../store/modules/currentOrder'
import * as currentOrderCombinedSelectors from '../../../../../store/modules/combinedSelectors/currentOrderCombinedSelectors'

const mapStateToProps = state => {
  const total = productsSelectors.getTotal(state)
  const query = productsSelectors.getResultsQuery(state)
  const sort = productsSelectors.getSort(state)
  const isLoading = productsSelectors.getIsLoading(state)

  const order = currentOrderCombinedSelectors.currentOrderSelector(state)

  return {
    sort,
    total,
    query,
    isLoading,
    ..._.pick(order, [
      'editMode',
      'orderNumber'
    ])
  }
}

const mapDispatchToProps = dispatch => ({
  searchProducts: ({ sort }) => dispatch(productsActions.searchProductsFresh({ sort })),
  stopEditingOrder: () => { dispatch(currentOrderActions.stopEditingOrder()) }
})

export default connect(
  mapStateToProps,
  mapDispatchToProps
)(TitleBar)
