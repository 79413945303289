import React from 'react'
import BasicModalOverlay from '../../../../../components/BasicModalOverlay'

const UnsupportedBrowserModal = () => (
  <BasicModalOverlay
    title={'Browser unsupported'}
    text={`
      Your browser is unsupported. Please use the following browser dependent on your device:\n
      iOS - Safari
      Android - Chrome
      Mac - Chrome/Firefox
      Windows - Chrome/Firefox
      Linux - Chrome/Firefox
    `}
  />
)

export default UnsupportedBrowserModal
