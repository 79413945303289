import React, { Component, Fragment } from 'react'
import _ from 'lodash'
import classNames from 'classnames'
import { withStyles } from '@material-ui/core/styles'
import Grid from '@material-ui/core/Grid'

import { translations } from '../../../config'
import SearchInput from '../../../components/SearchInput'
import BackBar from '../../../components/BackBar'
import SubHeader from '../../../components/SubHeader'
import Button from '../../../components/Button'
import BottomBar from '../../../components/BottomBar'
import ButtonsContainer from '../../../components/ButtonsContainer'

import style from './style'
import CustomerSearchResults from './CustomerSearchResults'

class CustomerSearch extends Component {
  state = {
    query: this.props.query
  }

  searchCustomer = _.debounce(({ query }) => {
    const { searchCustomer } = this.props
    searchCustomer({ query })
  }, 300)

  updateQuery = (e) => {
    const query = e.target.value
    this.setState({ query })
    this.searchCustomer({ query })
  }

  clearQuery = () => {
    this.setState({ query: '' })
    this.searchCustomer({ query: '' })
  }

  renderBackBar () {
    const { query, onBackClick } = this.props
    return (
      <BackBar
        onClick={
          query
            ? this.clearQuery
            : onBackClick
        }
      />
    )
  }

  renderSearchInput () {
    return (
      <Grid container spacing={0}>
        <Grid item xs={12}>
          <SearchInput
            value={this.state.query}
            placeholder={translations('Look up customers')}
            onChange={this.updateQuery}
            autoFocus={false}
          />
        </Grid>
      </Grid>
    )
  }

  renderContent () {
    const { onCustomerClick, children } = this.props
    return (
      this.state.query && this.state.query.length >= 3
        ? <CustomerSearchResults onCustomerClick={onCustomerClick} />
        : (children || null)
    )
  }

  render () {
    const { classes, className, registerCustomer } = this.props
    const containerClass = classNames(classes.container, className)
    return (
      <div className={containerClass}>
        <SubHeader
          leftContent={this.renderBackBar()}
          centerContent={this.renderSearchInput()}
          fixed
        />
        {this.renderContent()}
        {registerCustomer && (
          <BottomBar>
            <ButtonsContainer align='center'>
              <Button
                buttonType='primary'
                onClick={registerCustomer}
                big
              >
                {translations('Register customer')}
              </Button>
            </ButtonsContainer>
          </BottomBar>
        )}
      </div>
    )
  }
}

export default withStyles(style)(CustomerSearch)
