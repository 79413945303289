import React, { Component, Fragment } from 'react'
import { withStyles } from '@material-ui/core/styles'
import _ from 'lodash'
import moment from 'moment'

import { translations, dateFormat, timeFormat } from '../../../../config'
import TabbedContent from '../../../../components/TabbedContent'
import P from '../../../../components/P'
import BottomBar from '../../../../components/BottomBar'

import StaffCharts from '../../StaffReporting/StaffCharts'
import StoreCharts from '../../StoreReporting/StoreCharts'
import ChartsContainer from './ChartsContainer'
import style from './style'

const dateAndTimeFormat = `${timeFormat} - ${dateFormat}`

const tabs = [
  // {
  //   label: translations('Today'),
  //   content: null,
  //   value: 'today'
  // },
  // {
  //   label: translations('This Week'),
  //   content: null,
  //   value: 'thisWeek'
  // },
  // {
  //   label: translations('This Month'),
  //   content: null,
  //   value: 'thisMonth'
  // }
]

class RetailAnalyticsContent extends Component {
  render () {
    const { user, store, timePeriod, setTimePeriod, classes } = this.props

    if (!user && !store) return null

    const ChartsComponent = user ? StaffCharts : StoreCharts

    const instance = user || store

    const lastUpdated = _.get(instance, 'reporting.lastProcessed')
    const lastUpdatedText = (
      lastUpdated &&
      _.template(translations('Last Updated'))({ lastUpdated: moment(lastUpdated).fromNow() })
    )
    const dateRange = _.get(instance, `reporting.${timePeriod}.dateRange`)
    const dateRangeText = (
      dateRange && dateRange.start && dateRange.end &&
      _.template(translations('Date Range'))({
        start: moment(dateRange.start).format(dateAndTimeFormat),
        end: moment(dateRange.end).format(dateAndTimeFormat)
      })
    )

    const userDataWarningText = translations('User Retails Analytics Warning')

    return <div className={classes.container}>
      <div className={classes.tabsContainer}>
        <TabbedContent
          tabs={tabs}
          tabValue={timePeriod}
          handleTabChange={setTimePeriod}
          noDivider
        />
      </div>
      <ChartsContainer>
        <ChartsComponent
          user={user}
          store={store}
          timePeriod={timePeriod}
        />
      </ChartsContainer>
      <BottomBar absolute>
        <div className={classes.meta}>
          <P value={dateRangeText} />
          <P value={lastUpdatedText} />
          {
            user
              ? <P value={userDataWarningText} />
              : null
          }
        </div>
      </BottomBar>
    </div>
  }
}

export default withStyles(style)(RetailAnalyticsContent)
