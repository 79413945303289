import _ from 'lodash'
import { createSelector } from 'reselect'
import { translations } from '../../../config'

export const getCurrentOrderRaw = (state) => state.currentOrder
export const getCurrentOrderEditMode = (state) => _.get(state, 'currentOrder.editMode')
export const getCurrentOrderConsultationMode = (state) =>
  _.get(state, 'currentOrder.consultationMode')
export const getAttachContentMode = (state) => _.get(state, 'currentOrder.attachContentMode')
export const getCurrentOrderCustomer = (state) => _.get(state, 'currentOrder.customer')
export const getCurrentOrderProducts = (state) => _.get(state, 'currentOrder.products', [])
export const getCurrentOrderDetails = (state) => _.get(state, 'currentOrder.details', {})

export const getOrderProductsWithQuantities = createSelector(
  [getCurrentOrderProducts],
  (orderProducts = []) =>
    _(orderProducts)
      .groupBy('externalProductId')
      .map((items, externalProductId) => ({ externalProductId, orderQuantity: items.length }))
      .value()
)

export const getEditType = state => _.get(state, 'currentOrder.editType')
export const getDiscountType = state => _.get(state, 'currentOrder.details.discount.type')
export const getDiscount = state => _.get(state, 'currentOrder.details.discount')
export const getCurrentOrderBasketReviewStatus = state => _.get(state, 'currentOrder.customerHasReviewedBasket', false)
export const getConsultationServiceCategoryId = state => _.get(state, 'products.consultation.serviceCategoryId')
export const getConsultationQuickTricksCategoryId = state => _.get(state, 'products.consultation.quickTricksCategoryId')
export const getAddedBasketConfigurationProducts = state => _.get(state, 'currentOrder.addedBasketConfigurationProducts', false)
export const getAddedInspirationProducts = state => _.get(state, 'currentOrder.addedInspirationProducts', false)
export const getStripePaymentSuccess = state => _.get(state, 'currentOrder.stripePaymentSuccess', false)
export const getCustomerEmail = state => _.get(state, 'currentOrder.customer.email')

// used on public vc screen only
export const getPaymentIntentToken = state => _.get(state, 'currentOrder.paymentIntentToken')
export const getStripePublicKey = state => _.get(state, 'currentOrder.stripePublicKey')

export const getCurrentOrderProductTotal = createSelector([
  getCurrentOrderProducts
], (orderProducts = []) => {
  return orderProducts.length
})

export const getCurrentOrderProductsForConsultation = createSelector([
  getCurrentOrderProducts, getConsultationServiceCategoryId, getConsultationQuickTricksCategoryId
], (orderProducts = [], serviceCategoryId, quickTricksCategoryId) => {
  const products = (serviceCategoryId) ? _.filter(orderProducts, product => {
    const productCategoryId = _.get(product, 'category.id') || _.get(product, 'categoryId')
    return productCategoryId !== serviceCategoryId && productCategoryId !== quickTricksCategoryId
  })
    : orderProducts

  return products
})

export const attachVariantToProduct = ({ product, variantId }) => {
  if (product.variant) {
    return product
  } else if (product.variants) {
    const { variants, ...productRest } = product
    const variant = variants.find((variant) => variant.id === variantId)
    const variantPrice = _.get(variant, 'price')
    return {
      ...productRest,
      price: variantPrice || productRest.price,
      discount: variantPrice
        ? variant.discount
        : productRest.discount,
      variant: variant ? _.omit(variant, 'productId') : null
    }
  } else {
    return {
      ...product,
      variant: null
    }
  }
}

export const attachVariantToService = ({ product }) => {
  if (product.variant) {
    return product
  } else if (product.variants) {
    const { variants, ...productRest } = product
    const variant = _.get(variants, '[0]')
    return {
      ...productRest,
      variant: variant ? _.omit(variant, 'productId') : null
    }
  } else {
    return {
      ...product,
      variant: null
    }
  }
}

export const getConsultationCustomer = createSelector([getCurrentOrderCustomer], (customer) => {
  const selected = !!customer
  const defaultLabel = 'Customer Selected'
  const selectedLabel = 'Add a customer'
  const detail = selected ? `${customer.firstName} ${customer.lastName}` : undefined
  const id = _.get(customer, 'id')
  const photoOptIn = _.get(customer, 'photoOptIn') || _.get(customer, 'details.photoOptIn')
  const salesAssistant = _.get(customer, 'preferredSalesAssistant')
  const salesAssistantName = salesAssistant
    ? `${_.get(salesAssistant, 'firstName')} ${_.get(salesAssistant, 'lastName').substring(0, 1)}`
    : null
  const storeMarketing = _.get(customer, 'storeMarketing')
  const postMarketing = _.get(customer, 'postMarketing')
  return {
    ...customer,
    id,
    selected,
    detail,
    photoOptIn,
    salesAssistantName,
    storeMarketing,
    postMarketing
  }
})

export const getCurrentOrderInspiration = state => _.get(state, 'currentOrder.details.inspiration')
export const getCurrentOrderPhotos = state => _.get(state, 'currentOrder.details.photos')

export const getConsultationInspiration = createSelector([
  getCurrentOrderInspiration
], (inspiration = {}) => {
  const selected = !_.isEmpty(inspiration)
  const detail = selected ? inspiration.title : undefined
  const label = 'Select a Look'
  let activeLabel = null

  if (selected) {
    activeLabel = inspiration.title
  }

  return { selected, label, detail, activeLabel }
})

export const getConsultationBeforePhoto = createSelector([
  getCurrentOrderPhotos
], (photos) => {
  const beforePhoto = _.find(photos, photo => photo.type === 'beforePhoto')
  const selected = !!beforePhoto
  const label = selected ? 'Before Photo Selected' : 'Take a Before Photo'
  const detail = selected && beforePhoto ? _.get(beforePhoto, 'file.fileName') : undefined
  return { selected, label, detail }
})

export const getConsultationAfterPhoto = createSelector([
  getCurrentOrderPhotos
], (photos) => {
  const afterPhoto = _.find(photos, photo => photo.type === 'afterPhoto')
  const selected = !!afterPhoto
  const label = selected ? 'After Photo Selected' : 'Take an After Photo'
  const detail = selected && afterPhoto ? _.get(afterPhoto, 'file.fileName') : undefined
  return { selected, label, detail }
})

export const getConsultationAfterPhotoPreview = createSelector([
  getCurrentOrderPhotos
], (photos) => {
  const afterPhoto = _.find(photos, photo => photo.type === 'afterPhoto')
  return _.get(afterPhoto, 'preview')
})

export const getLatestAfterPhoto = createSelector([getCurrentOrderCustomer], (customer) => {
  if (!customer) return undefined

  const latestOrderPhotos = _.chain(customer.orders)
    .map((order) => {
      return {
        orderDate: order.orderDate,
        photos: _.get(order, 'details.photos', [])
      }
    })
    .filter((order) => !!order.photos.length)
    .sortBy('orderDate')
    .last()
    .value() || { photos: [] }

  const latestAfterPhoto = _.find(latestOrderPhotos.photos, (photo) => photo.type === 'afterPhoto')
  return latestAfterPhoto ? latestAfterPhoto.src : undefined
})

export const getConsultationProducts = createSelector(
  [getCurrentOrderProductsForConsultation],
  (products) => {
    const selected = !_.isEmpty(products)
    const label = `${translations('Add products to consultation')} *`
    let activeLabel = null

    if (selected) {
      activeLabel = `${products.length} Item${products.length > 1 ? 's' : ''} Added`
    }

    return { selected, label, activeLabel }
  }
)

export const getSelectedConsultationServiceProduct = createSelector([
  getCurrentOrderProducts,
  getConsultationServiceCategoryId,
  getConsultationQuickTricksCategoryId
], (orderProducts = [], serviceCategoryId, quickTricksCategoryId) => {
  return _.find(orderProducts, (product) => {
    const prodCategoryId = _.get(product, 'category.id') || product.categoryId
    return prodCategoryId === serviceCategoryId || prodCategoryId === quickTricksCategoryId
  })
})

export const getConsultationGuestMode = createSelector([getCurrentOrderRaw], (currentOrder) => {
  return _.get(currentOrder, 'guestConsultationMode', false)
})

export const getAllAfterPhotos = createSelector([getCurrentOrderCustomer], (customer) => {
  if (!customer) return []
  const orders = _.get(customer, 'orders', [])
  const photos = orders.map((order) => {
    const photos = _.get(order, 'details.photos', [])
    const afterPhoto = _.find(photos, { type: 'afterPhoto' })

    return _.get(afterPhoto, 'src')
  })

  return _.compact(photos)
})

export const getConsulationCustomerBasketReview = (state) => {
  const selected = getCurrentOrderBasketReviewStatus(state)
  const defaultLabel = 'Review with customer'
  const selectedLabel = 'Items Reviewed'

  return { selected, defaultLabel, selectedLabel }
}

export const getCurrentOrderCustomerNameDetails = createSelector(
  [getCurrentOrderCustomer],
  (customer) => {
    if (!customer) return undefined
    const { firstName, lastName } = customer
    const fullName = _.compact([firstName, lastName]).join(' ')
    const firstInitial = firstName.substring(0, 1)
    const lastInitial = lastName.substring(0, 1)

    return { fullName, initials: `${firstInitial}${lastInitial}` }
  }
)

export const getPaymentDetails = createSelector([
  getCurrentOrderRaw
], (currentOrder = {}) => {
  return currentOrder.payment
})
