import { colors } from '../../../../config/theme'

const styles = {
  multiSelectContainerSpacer: {
    height: 91,
    flexShrink: 0
  },
  hasSelectedProducts: {
    height: 155
  },
  multiSelectContainer: {
    position: 'fixed',
    bottom: 0,
    left: 0,
    width: '100%',
    backgroundColor: colors.lightGrey
  },
  totalContainer: {
    display: 'flex',
    flex: 1,
    justifyContent: 'center',
    alignItems: 'center',
    textTransform: 'uppercase'
  },
  total: {
    fontSize: 17,
    lineHeight: '24px'
  },
  buttonContainer: {
    display: 'flex',
    flex: 1,
    justifyContent: 'center',
    alignItems: 'center',
    padding: 20,
    borderTop: '2px solid #F3F3F3'
  }
}

export default theme => styles
