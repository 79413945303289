import React from 'react'
import _ from 'lodash'
import cx from 'classnames'
import { withStyles } from '@material-ui/core/styles'


import ButtonLink from '../ButtonLink'

import style from './style'

const KeyValueCard = props => {
  const { data = [], classes, className, columns, onClick, ...rest } = props
  // data should be an array that looks like:
  // [[key, value], [key, value], ...]
  // or an object, which will be transformed into such an array

  const keyValuePairs = (
    _.isArray(data)
    ? data
    : _.map(data, (value, key) => ([key, value]))
  )
    .map(([key, value, keyValuePairProps]) => {
      const {
        className: keyValuePairClassName,
        clickHandler,
        ...restKeyValuePairProps
      } = keyValuePairProps || {}

      return <div
        key={key}
        className={cx(
          classes.keyValuePairContainer,
          keyValuePairClassName
        )}
        {...restKeyValuePairProps}
      >
        <dt className={classes.key}>{key}</dt>
        <dd className={classes.value}>{clickHandler ?
            <ButtonLink className={classes.value} onClick={onClick}>{value}</ButtonLink> :
            value}
        </dd>
      </div>
    })

  return <dl className={cx(classes.card, className)} onClick={onClick} {...rest}>
    {keyValuePairs}
  </dl>
}

export default withStyles(style)(KeyValueCard)
