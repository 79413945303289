import React from 'react'
import { useDispatch } from 'react-redux'
import { push } from 'connected-react-router'
import _ from 'lodash'

import ListPage from '../../../layouts/ListPage'
import { translations } from '../../../config'
import { useResults, useIsLoading, useError } from '../../../hooks/storesHooks'
import { actions as storesActions } from '../../../store/modules/stores'

const StoresSearchScreen = ({ onStoreClick, onBackClick, hideButton }) => {
  let onRowClick
  const results = useResults()
  const isLoading = useIsLoading()
  const error = useError()
  const dispatch = useDispatch()

  if (onStoreClick) {
    onRowClick = onStoreClick
  } else {
    onRowClick = React.useCallback((cell) => {
      const store = cell.row.original
      dispatch(push(`/stores/${store.id}`))
    }, [])
  }

  const onClickAddStore = React.useCallback(() => {
    dispatch(push(`/stores/new`))
  }, [])

  React.useEffect(() => {
    if (!results || !results.length) {
      dispatch(storesActions.fetchAllStores({ includes: ['region'], sort: 'name' }))
    }
  }, [])

  const columns = React.useMemo(() => ([
    {
      Header: translations('Name'),
      accessor: 'name',
      onClick: onRowClick
    },
    {
      Header: translations('External ID'),
      accessor: 'externalStoreId'
    },
    {
      Header: translations('Region'),
      accessor: 'regionName'
    },
    {
      Header: translations('Catalogue'),
      accessor: 'catalogue'
    }
  ]), [])

  const mappedResults = React.useMemo(() => {
    return results.map(result => ({
      ...result,
      regionName: _.get(result, 'region.name')
    }))
  }, [results])

  const actionButtons = hideButton ? [] : [
    {
      label: translations('Create Store'),
      onClick: onClickAddStore
    }
  ]

  return (
    <ListPage
      title={translations('Page Title - Stores')}
      onRowClick={onRowClick}
      onBackClick={onBackClick}
      columns={columns}
      isLoading={isLoading}
      results={mappedResults}
      error={error}
      actionButtons={actionButtons}
    />
  )
}

export default StoresSearchScreen
