import React from 'react'
import styled from 'styled-components'

import { translations } from '../../../../config'

const Container = styled.div`
  min-height: calc(100vh - 50px - env(safe-area-inset-top));
  background-color: #F3F3F3;
  color: #340C0C;
  flex: 1;
  justify-content: center;
  align-items: center;
  display: flex;
  text-align: center;
  padding: 0 20px;
  flex-direction: column;
  > div { max-width: 700px; }
`

const InvalidAppointment = ({ status }) => {
  const invalidAppointmentMessage = (status) => ({
    COMPLETE: 'Appointment completed - headline',
    EXPIRED: 'Appointment expired - headline',
    CANCELLED: 'Appointment does not exist - headline'
  })[status] || 'Appointment does not exist - headline'
  return (
    <Container>
      <div>
        <p style={{ fontWeight: 'bold' }}>{translations(invalidAppointmentMessage(status))}</p>
      </div>
    </Container>
  )
}

export default InvalidAppointment
