import _ from 'lodash'
import { PENDING } from '../../middleware/redux-promise'
import { communicationOptions } from '../../../config'

const formatMarketingValues = (result, keys) => {
  // remove keys which are false
  // leaves an array of keys that were true
  // this is the format which the checklist requires
  return _.chain(result)
    .pick(keys)
    .omitBy((value, key) => !value)
    .keys()
    .value()
}

export const addMarketingValuesToCustomer = customer => {
  const customerMarketingChannels = formatMarketingValues(customer, communicationOptions.channelKeys)
  const customerMarketingPreferences = formatMarketingValues(customer, communicationOptions.preferenceKeys)
  const customerOther = _.omit(customer, [...communicationOptions.channelKeys, ...communicationOptions.preferenceKeys])
  return {
    ...customerOther,
    customerMarketingChannels,
    customerMarketingPreferences
  }
}

const formatWaitlistEntry = entry => {
  return {
    ...entry,
    customer: addMarketingValuesToCustomer(entry.customer)
  }
}

export const getEventWaitlist = state => state.eventWaitlist.results ? _.map(state.eventWaitlist.results, entry => formatWaitlistEntry(entry)) : []

export const getIsLoading = state => state.eventWaitlist.status === PENDING

const formatCustomerWaitlistEntry = entry => {
  return {
    type: 'event',
    ...entry.event
  }
}

export const getEventWaitlistForCustomer = state => state.eventWaitlist.results ? { waitlist: _.map(state.eventWaitlist.results, entry => formatCustomerWaitlistEntry(entry)) } : {}
