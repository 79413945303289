import React from 'react'
import Messenger from '../../../../components/Messenger'
import NotificationEditorOptions from './NotificationEditorOptions'
import NotificationEditorRecipients from './NotificationEditorRecipients'
import NotificationEditorText from './NotificationEditorText'
import _ from 'lodash'
import { notificationContentOptions } from '../../../../config'

const NotificationEditorForm = (props) => {
  const notificationRecipientName = (user) => [{ id: user.id, name: user.firstName + ' ' + user.lastName }];
  const groupLength = (data) => data.length > 2 ? `(+ ${data.length - 2} more)` : ''
  const groupName = _.get(props, 'group.groupName', null)
  const stores = _.size(groupName && groupName.stores)
  const departments = _.size(groupName && groupName.departments)
  const formatGroupName = () => (
    notificationRecipientName({
      id: 'Group',
      firstName: `${groupName.departments.slice(0, 2).join(', ')} ${groupLength(groupName.departments)} 
      ${departments && stores ? 'in' : ''} ${groupName.stores.slice(0, 2).join(', ')} ${groupLength(groupName.stores)}`,
      lastName: ''
    })
  )
  return(
  <Messenger
    {...props}
    noPreview
    maxContent={1}
    contentOptions={notificationContentOptions}
    renderOptions={() => (
      <NotificationEditorOptions {...props} />
    )}
    renderRecipients={() => (
      <NotificationEditorRecipients
      onUserSelect={props.onUserSelect}
      currentRecipients={props.selectedUser && props.group && groupName === 'All'
        ? notificationRecipientName({ id: 'All', firstName: 'All users', lastName: '' })
        : props.group && props.group.groupName !== 'All'
          ? formatGroupName()
          : props.selectedUser
            ? notificationRecipientName(props.selectedUser)
            : undefined}
      />
    )}
    renderText={() => (
      <NotificationEditorText hasRecipient={!!props.selectedUser} />
    )}
  />)
    }

export default NotificationEditorForm
