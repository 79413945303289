export const notificationObject = ({ formData = {}, media = {}, users = {}, storeIds = {}, departmentIds = {}, scheduledDate }) => {
  // if we have file attachments, then media.id and media.type should be set to null
  // and the contents of formData should be set as attachments on notification
  // object. afterwards they will be stored in `details` property
  const hasFileAttachments = ['image', 'file'].includes(media.type)

  return {
      message: formData.text,
      media: {
        id: hasFileAttachments ? null : media.id,
        type: hasFileAttachments ? null : media.type,
        name: media.name || media.title
      },
      targetUser: {
        id: users.id,
        name: users.fullName
      },
      storeIds,
      departmentIds,
      scheduledDate: scheduledDate.toDate(),
      isActionable: formData.type === 'task',
      attachments: hasFileAttachments ? formData.content : []
  };
};