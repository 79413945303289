import _ from 'lodash'
import { mix } from 'polished'

import { colors } from '../../../config/theme'

import getGroupNames from './getGroupNames'
import getGroupedItems from './getGroupedItems'
import getTargetData from './getTargetData'
import getAggregateData from './getAggregateData'
import { parseOrZero, getItemValue } from './helpers'

const getChartColor = (i, total) => {
  total = total <= 1 ? 2 : total
  const transparency = (i / (total - 1)) * 0.8
  const color = mix(transparency, '#fff', colors.primary)
  return color
}

const getChartData = props => {
  const { items, reportSchema } = props
  if (!(items && reportSchema)) return
  const { type: chartType, data } = reportSchema
  const { groupBy, sumBy, aggregateFrom, vsTarget } = data

  if (groupBy) {
    if (chartType === 'BAR' || chartType === 'DONUT' || chartType === 'PIE' || chartType === 'COUNT') {
      const groupedItems = getGroupedItems(props)
      // make an array: [ { name, value, color } ]
      const groupNames = getGroupNames(props)
      const chartData = groupNames.map((groupName, i) => {
        const group = groupedItems[groupName]
        const value = group.reduce((total, item) => {
          return (
            total +
            parseOrZero(
              sumBy ? getItemValue(item, sumBy, props) : 1
            )
          )
        }, 0)
        const color = getChartColor(i, groupNames.length)
        return { name: groupName, value, color }
      })
      return chartData
    }
  }

  if (aggregateFrom || vsTarget) {
    const values = (
      aggregateFrom
      ? getAggregateData(props)
      : vsTarget
      ? getTargetData(props)
      : {}
    )

    return _.chain(values)
      .keys()
      .filter(key => key !== '__TARGET')
      .map((key, i, keys) => {
        return {
          name: key,
          value: values[key],
          color: getChartColor(i, keys.length)
        }
      })
      .value()
  }
}

export default getChartData
