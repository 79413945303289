import { change } from 'redux-form'
import _ from 'lodash'
import uuid from 'uuid/v4'

import modalService from '../../../services/modalService'

import { translations } from '../../../config'
import ImageUploadModal from '../../ImageUploadModal'
import FileUploadModal from '../../FileUploadModal'

export default (() => {
  const addProductToField = ({ product, currentContent, dispatch, formName, fieldName }) => {
    const { id, name, brand, link, images, price, discount, externalProductId, variants } = product
    const modifiedProduct = {
      type: 'product',
      externalProductId,
      id,
      brand,
      link,
      images,
      name,
      price,
      discount,
      variants
    }
    const newContent = [modifiedProduct].concat(currentContent)
    dispatch(change(formName, fieldName, newContent))
    modalService.close()
  }

  const addInspirationToField = ({ inspiration, currentContent, dispatch, formName, fieldName }) => {
    const { id, title, description, images, tags } = inspiration
    const modifiedInspiration = {
      type: 'inspiration',
      id,
      title,
      description,
      images,
      tags
    }
    const newContent = [modifiedInspiration].concat(currentContent)
    dispatch(change(formName, fieldName, newContent))
    modalService.close()
  }

  const addFileToField = ({ url, fileName, currentContent, dispatch, formName, fieldName }) => {
    const modifiedFile = {
      fileName,
      type: 'file',
      url,
      local: true,
      id: uuid()
    }
    const newContent = [modifiedFile].concat(currentContent)
    dispatch(change(formName, fieldName, newContent))
    modalService.close()
  }

  const addImageToField = ({ url, currentContent, dispatch, formName, fieldName }) => {
    const modifieldImage = {
      type: 'image',
      url,
      local: true,
      id: uuid()
    }
    const newContent = [modifieldImage].concat(currentContent)
    dispatch(change(formName, fieldName, newContent))
    modalService.close()
  }

  const addCustomerToField = ({ customer, currentContent, dispatch, formName, fieldName }) => {
    const modifieldCustomer = {
      type: 'customer',
      id: customer.id,
      name: `${customer.firstName} ${customer.lastName}`,
      firstName: customer.firstName,
      lastName: customer.lastName
    }
    const newContent = [modifieldCustomer].concat(currentContent)
    dispatch(change(formName, fieldName, newContent))
    modalService.close()
  }

  const openProductsScreenModal = ({ dispatch, formName, fieldName, currentContent, productsModalComponent }) => {
    const currentContentIds = currentContent.map(c => c.id)
    modalService.open({
      component: productsModalComponent,
      fullScreen: true,
      exit: () => modalService.close(),
      onProductClick: product => addProductToField({ product, currentContent, dispatch, formName, fieldName }),
      onButtonClick: product => addProductToField({ product, currentContent, dispatch, formName, fieldName }),
      buttonValue: translations('Select Product'),
      productMapper: product => {
        const disabled = !!currentContentIds.find(currentContentItemId => currentContentItemId === product.id)
        return {
          ...product,
          disabled
        }
      },
      noRouting: true
    })
  }

  const deleteProduct = ({ dispatch, formName, fieldName, currentContent, id }) => {
    const newContent = currentContent.filter(product => id !== product.id)
    dispatch(change(formName, fieldName, newContent))
  }

  const openDeleteModal = ({ dispatch, formName, fieldName, currentContent, id, itemTypeName = 'Product' }) => {
    modalService.action({
      title: translations(`Delete ${itemTypeName}`),
      text: translations(`Delete ${itemTypeName} Text`),
      actions: [
        {
          success: true,
          text: translations('Delete'),
          onClick: () => deleteProduct({ dispatch, formName, fieldName, currentContent, id }),
          primary: true
        },
        {
          text: translations('Cancel'),
          primary: true
        }
      ]
    })
  }

  const openInspirationsScreenModal = ({ dispatch, formName, fieldName, currentContent, inspirationsModalComponent }) => {
    modalService.open({
      component: inspirationsModalComponent,
      fullScreen: true,
      goBack: () => modalService.close(),
      onInspirationClick: inspiration => addInspirationToField({ inspiration, currentContent, dispatch, formName, fieldName }),
      buttonValue: translations('Select Inspiration'),
      inModal: true
    })
  }

  const openImageUploadModal = ({ dispatch, formName, fieldName, currentContent }) => {
    modalService.open({
      component: ImageUploadModal,
      onPhotoAdded: url => {
        const blobUrl = _.get(url, 'blobUrl')
        addImageToField({ url: blobUrl, currentContent, dispatch, formName, fieldName })
      }
    })
  }

  const openFileUploadModal = ({ dispatch, formName, fieldName, currentContent }) => {
    modalService.open({
      component: FileUploadModal,
      onFileAdded: url => {
        const blobUrl = _.get(url, 'blobUrl')
        const fileName = _.get(url, 'fileName')
        addFileToField({ url: blobUrl, fileName, currentContent, dispatch, formName, fieldName })
      },
      onPhotoAdded: url => {
        const blobUrl = _.get(url, 'blobUrl')
        addImageToField({ url: blobUrl, currentContent, dispatch, formName, fieldName })
      }
    })
  }

  const openCustomerModal = ({ dispatch, formName, fieldName, currentContent, customerModalComponent }) => {
    modalService.open({
      component: customerModalComponent,
      fullScreen: true,
      onCustomerClick: (customer) => {
        addCustomerToField({ customer, currentContent, dispatch, formName, fieldName })
      },
      onBackClick: () => {
        modalService.close()
      }
    })
  }

  return {
    openProductsScreenModal,
    openDeleteModal,
    openInspirationsScreenModal,
    openImageUploadModal,
    openFileUploadModal,
    openCustomerModal
  }
})()
