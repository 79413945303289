import { connect } from 'react-redux'
import { compose, withHandlers } from 'recompose'
import { actions as eventWaitlistActions, selectors as eventWaitlistSelectors } from '../../../store/modules/eventWaitlist'
import { actions as createMessageActions } from '../../../store/modules/createMessage'

import EventWaitlistScreen from './EventWaitlistScreen'
import modalService from '../../../services/modalService'
import { translations } from '../../../config'

const mapStateToProps = state => {
  return {
    waitlist: eventWaitlistSelectors.getEventWaitlist(state),
    isLoading: eventWaitlistSelectors.getIsLoading(state)
  }
}

export default compose(
  connect(mapStateToProps),
  withHandlers({
    onClickDelete: ({ dispatch }) => eventId => {
      modalService.action({
        title: translations(`Delete registration of interest entry`),
        text: translations(`Remove this entry from the registration of interest?`),
        actions: [
          {
            success: true,
            text: translations('Delete'),
            onClick: () => dispatch(eventWaitlistActions.deleteEntryFromWaitlist(eventId)),
            primary: true
          },
          {
            text: translations('Cancel'),
            primary: false
          }
        ]
      })
    },
    onClickSendMessage: ({ dispatch }) => customer => {
      dispatch(createMessageActions.updateReceipient(customer))
    },
    loadMore: ({ dispatch }) => () => {
      dispatch(eventWaitlistActions.fetchEventWaitlistNext())
    }
  })
)(EventWaitlistScreen)
