import { colors } from '../../../config/theme'

export default {
  container: {
    display: 'flex',
    justifyContent: 'center',
    alignItems: 'center',
    borderBottom: `0.5px solid ${colors.border}`,
    '&:last-child': {
      borderBottom: 'none'
    }
  },
  inner: {
    maxWidth: 380,
    width: '100%',
    margin: '21px 30px 25px',
    '& br': {
      fontSize: '0.5em'
    }
  },
  title: {
    marginBottom: 16,
    textTransform: 'uppercase',
    textAlign: 'center'
  },
  br: {
    height: '0.5em'
  }
}
