import React, { useCallback, useRef } from 'react'
import VideocamIcon from '@material-ui/icons/Videocam'
import VideocamOffIcon from '@material-ui/icons/VideocamOff'
import CircularProgress from '@material-ui/core/CircularProgress'

import ToggleButton from './ToggleButton'

import useLocalVideoToggle from '../../hooks/useLocalVideoToggle'
import useVideoContext from '../../hooks/useVideoContext'


const ToggleVideoButton = () => {
  const [isVideoEnabled, toggleVideoEnabled] = useLocalVideoToggle()
  const lastClickTimeRef = useRef(0)

  const { isAcquiringLocalVideoTracks } = useVideoContext()

  const toggleVideo = useCallback(() => {
    if (Date.now() - lastClickTimeRef.current > 500) {
      lastClickTimeRef.current = Date.now()
      toggleVideoEnabled()
    }
  }, [toggleVideoEnabled])

  return (
    <ToggleButton active={!isVideoEnabled} onClick={toggleVideo}>
      {isAcquiringLocalVideoTracks
        ? <CircularProgress size={25} />
        : isVideoEnabled
          ? <VideocamIcon />
          : <VideocamOffIcon /> }
    </ToggleButton>
  )
}

export default ToggleVideoButton
