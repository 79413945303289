import _ from 'lodash'
import { useEffect, useState } from 'react'

// Accepts 'camera' | 'microphone'
const usePermissions = (name) => {
  const permissionDescriptor = { name }
  const [state, setState] = useState()

  useEffect(() => {
    let mounted = true
    let permissionStatus = null

    const onChange = (newPermissionStatus) => {
      if (!mounted) {
        return
      }
      console.log(`${permissionDescriptor.name} permission new state is`, newPermissionStatus)
      setState(() => newPermissionStatus)
    }

    if (!_.get(navigator, 'permissions')) return

    navigator.permissions
      .query(permissionDescriptor)
      .then((status) => {
        permissionStatus = status
        permissionStatus.onchange = (e) => {
          const newPermissionStatus = _.get(e, 'target.state')
          onChange(newPermissionStatus)
        }
        onChange(permissionStatus.state)
      })

    return () => {
      permissionStatus && removeEventListener('change', onChange)
      mounted = false
      permissionStatus = null
    }
  }, [permissionDescriptor])

  return state
}

export default usePermissions
