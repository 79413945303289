import _ from 'lodash'

import colors from './configFiles/theme/colors'
import base from './configFiles/theme/base'
import text from './configFiles/theme/text'
import _theme from './configFiles/theme/theme'

const visibility = theme => ({
  hideForExtraSmall: {
    [theme.breakpoints.down('xs')]: {
      display: 'none'
    }
  },
  hideForSmall: {
    [theme.breakpoints.down('sm')]: {
      display: 'none'
    }
  }
})

const recursivelySubstitute = (input, references) => {
  const referenceNames = Object.keys(references)
  return _.mapValues(input, value => {
    if (typeof value === 'object') {
      return recursivelySubstitute(value, references)
    } else if (typeof value === 'string') {
      let matchingReference
      referenceNames.forEach(referenceName => {
        if (value.includes(`${referenceName}.`)) {
          matchingReference = referenceName
        }
      })
      if (matchingReference) {
        return _.get(
          references[matchingReference],
          value.split('.').slice(1)
        )
      } else {
        return value
      }
    } else {
      return value
    }
  })
}

const theme = recursivelySubstitute(
  { base, ...text, ..._theme },
  { colors, base }
)

theme.overrides = {
  MuiInput: {
    underline: {
      '&.MuiInput-formControl:after' : {
        borderBottomColor: colors.accent
      }
    }
  },
  MuiFormLabel: {
    root: {
      '&.Mui-focused': {
        color: colors.accent
      }
    }
  }
}

const addColorToMUITheme = colorKey => {
  const color = colors[colorKey]
  theme.palette[colorKey] = {
    main: color,
    light: color,
    dark: color
  }
}

theme.palette = {}
addColorToMUITheme('primary')
addColorToMUITheme('accent')
export { theme, colors, visibility }
export default theme
