import React from 'react'
import PropTypes from 'prop-types'

import { withStyles } from '@material-ui/core/styles'
import FormGroup from '@material-ui/core/FormGroup'
import FormControl from '@material-ui/core/FormControl'
import FormControlLabel from '@material-ui/core/FormControlLabel'
import ReactMarkdown from 'react-markdown'

import * as validators from '../../../../validators'

import Form from '../../../../components/Form'

import FormBody from '../../../../components/Form/FormBody'
import FormError from '../../../../components/Form/FormError'
import FormSubmit from '../../../../components/Form/FormSubmit'
import personalDetailsSchema from '../../../../components/Form/commonSchemas/registerCustomerPersonalDetails'

import PersonalDetails from '../../../../components/Form/FormSections/PersonalDetails'
import Address from '../../../../components/Address'
import BottomBar from '../../../../components/BottomBar'
import CommunicationOptions from './CommunicationOptions'

import { Checkbox } from '../../../../components/Fields'
import { translations, registerCustomerTermsCheckboxEnabled } from '../../../../config'

import { formStyle } from './style'

import './style.css'

export const formId = 'customer-registration'

const CustomerRegistrationForm = Form(formId)

const renderLink = (link) => <a href={link.href} target='_blank'>{link.children}</a>

const renderTerms = (props) => {
  const { classes } = props

  const text = <ReactMarkdown
    source={translations('Register Customer Terms Markdown')}
    renderers={{ link: renderLink }}
  />

  if (registerCustomerTermsCheckboxEnabled) {
    const formControlLabelClasses = {
      root: classes.termsLabelRoot,
      label: classes.termsLabelLabel
    }
    const control = <Checkbox
      name='terms'
      validate={validators.required}
    />
    return <FormGroup row>
      <FormControl>
        <FormControlLabel
          classes={formControlLabelClasses}
          control={control}
          label={text}
        />
      </FormControl>
    </FormGroup>
  } else {
    return <div className={classes.termsText}>{text}</div>
  }
}

const CustomerRegistrationFormWrapper = ({
  onSubmit,
  error,
  submitting,
  invalid,
  classes,
  customerModeUpdate
}) => {
  // props on the form allow the initial values to be updated
  return (
    <div id='customer-registration-container'>
      <CustomerRegistrationForm onSubmit={onSubmit} submitLabel={translations('Create Customer')} initialValues={{ marketingStoreIds: [] }}>
        <FormBody>
          <div>
            <PersonalDetails formId={formId} schema={personalDetailsSchema} />
            <Address
              formName='customer-registration'
              align='right'
            />
            <CommunicationOptions />
            {renderTerms({ classes })}
          </div>
        </FormBody>
        <FormError />
        {!customerModeUpdate &&
          <FormSubmit
            ContainerComponent={BottomBar}
            label={translations('Create Customer')}
            buttonType='primary'
            big
          />
        }
      </CustomerRegistrationForm>
    </div>
  )
}

CustomerRegistrationFormWrapper.propTypes = {
  onSubmit: PropTypes.func.isRequired,
  error: PropTypes.string,
  submitting: PropTypes.bool,
  invalid: PropTypes.bool
}

export default withStyles(formStyle)(CustomerRegistrationFormWrapper)
