import * as constants from './constants'
import * as selectors from './selectors'
import digitalStoreSdk from '../../../digitalStoreSdk'

class NotificationsActions {
  fetchPersonalNotificationsFresh = ({ isFlagged = false, toDo = false }) => ({
    type: constants.FETCH_PERSONAL_NOTIFICATIONS_FRESH,
    promise: (dispatch, getState) => {
      return digitalStoreSdk.users
        .fetchPersonalNotifications({ page: 1, size: 20, isFlagged, toDo })
    }
  })
  fetchPersonalNotificationsNext = ({ isFlagged = false, toDo = false }) => ({
    type: constants.FETCH_PERSONAL_NOTIFICATIONS_NEXT,
    promise: (dispatch, getState) => {
      const state = getState()
      const page = selectors.getNotificationsPage(state)
      const size = selectors.getNotificationsSize(state)
      return digitalStoreSdk.users
        .fetchPersonalNotifications({ page: page + 1, size, isFlagged, toDo })
        .then(({ total, results }) => ({ total, results }))
    }
  })

  fetchUserNotificationsFresh = ({ isFlagged = false, toDo = false, id }) => ({
    type: constants.FETCH_USER_NOTIFICATIONS_FRESH,
    promise: (dispatch, getState) => {
      return digitalStoreSdk.notifications
        .fetchNotificationsForUser({ page: 1, size: 20, isFlagged, toDo, id })
    },
    selectedUserId: id,
    // in case multiple pages have already been viewed
    page: 1
  })
  fetchUserNotificationsNext = ({ isFlagged = false, toDo = false, selectedUserId }) => ({
    type: constants.FETCH_USER_NOTIFICATIONS_NEXT,
    promise: (dispatch, getState) => {
      const state = getState()
      const page = selectors.getNotificationsPage(state)
      const size = selectors.getNotificationsSize(state)
      return digitalStoreSdk.users
      .fetchNotificationsForUser({ page: page + 1, size, isFlagged, toDo, id: selectedUserId })
        .then(({ total, results }) => ({ total, results }))
    }
  })
  
  setNotificationUserStatus = ({ id, status }) => ({
    type: constants.SET_NOTIFICATION_USER_STATUS,
    promise: () => digitalStoreSdk.users.setNotificationUserStatus({ id, status }),
    payload: { id, status }
  })
  setTotalNotificationsNotViewed = ({ total }) => ({
    type: constants.SET_TOTAL_NOTIFICATIONS_NOT_VIEWED,
    payload: { total }
  })
  getTotalNotificationsNotViewed = () => ({
    type: constants.GET_TOTAL_NOTIFICATIONS_NOT_VIEWED,
    promise: digitalStoreSdk.users.getTotalNotificationsNotViewed
  })
  setNotificationActionedStatus = ({ id, isActioned }) => ({
    type: constants.SET_NOTIFICATION_ACTIONED_STATUS,
    promise: () => digitalStoreSdk.notifications.changeActionedStatus({ id, isActioned }),
    payload: { id, isActioned }
  })
  updateNotificationFilters = filters => ({
    type: constants.UPDATE_NOTIFICATION_FILTERS,
    filters
  })
  setBroadcastGroup = group => ({
    type: constants.SET_BROADCAST_GROUP,
    group
  })
  resetBroadcastGroup = () => ({
    type: constants.RESET_BROADCAST_GROUP
  })
  removeNotificationFromList = ({ id }) => ({
    type: constants.REMOVE_NOTIFICATION_FROM_LIST,
    payload: { id }
  })
  resetStore = () => ({
    type: constants.RESET_STORE
  })
  sendUserToUserNotification = ({ message, media = {}, targetUser, scheduledDate, isActionable, attachments }) => ({
    type: constants.SEND_USER_TO_USER_NOTIFICATION,
    promise: (_, getState) => {
      const user = getState().auth.user
      return digitalStoreSdk.scheduledTasks.createScheduledTask({
        type: 'userToUserNotification',
        referenceType: media.type, // inspiration | product
        referenceId: media.id,
        details: {
          userIdFrom: user.id,
          userIdTo: targetUser.id,
          message,
          isActionable,
          fromUserName: user.firstName + ' ' + user.lastName,
          toUserName: targetUser.name,
          regardingText: media.name,
          attachments
        },
        scheduledDate
      })
    }
  })

  sendUserToManyNotification = ({ message, media = {}, storeIds, departmentIds, scheduledDate, isActionable, attachments }) => ({
    type: constants.SEND_USER_TO_MANY_NOTIFICATION,
    promise: (_, getState) => {
      const user = getState().auth.user
      return digitalStoreSdk.scheduledTasks.createScheduledTask({
        type: 'userToManyNotification',
        referenceType: media.type,
        referenceId: media.id,
        details: {
          userIdFrom: user.id,
          storeIds: storeIds,
          departmentIds: departmentIds,
          message,
          isActionable,
          fromUserName: user.firstName + ' ' + user.lastName,
          regardingText: media.name,
          attachments
        },
        scheduledDate
      })
    }
  })

  sendUserToAllNotification = ({ message, media = {}, scheduledDate, isActionable, attachments }) => ({
    type: constants.SEND_USER_TO_ALL_NOTIFICATION,
    promise: (_, getState) => {
      const user = getState().auth.user
      return digitalStoreSdk.scheduledTasks.createScheduledTask({
        type: 'userToAllNotification',
        referenceType: media.type,
        referenceId: media.id,
        details: {
          userIdFrom: user.id,
          message,
          isActionable,
          fromUserName: user.firstName + ' ' + user.lastName,
          regardingText: media.name,
          attachments
        },
        scheduledDate
      })
    }
  })
}

export default new NotificationsActions()
