import { connect } from 'react-redux'
import { compose } from 'recompose'

import { selectors as authSelectors } from '../../../../store/modules/auth'
import { selectors as storeDetailsSelectors, actions as storeDetailsActions } from '../../../../store/modules/storeDetails'
import withFullScreenLoader from '../../../../components/FullScreenLoader'

import RetailAnalyticsContent from './RetailAnalyticsContent'

const mapStateToProps = state => ({
  store: storeDetailsSelectors.getStore(state),
  isLoading: storeDetailsSelectors.getIsFetchStoreLoading(state),
  user: storeDetailsSelectors.getViewUser(state),
  timePeriod: storeDetailsSelectors.getTimePeriod(state)
})

const mapDispatchToProps = dispatch => ({
  setTimePeriod: (e, timePeriod) => dispatch(storeDetailsActions.setTimePeriod(timePeriod)),
})

export default compose(
  connect(mapStateToProps, mapDispatchToProps),
  withFullScreenLoader
)(RetailAnalyticsContent)
