import { colors } from '../../../../config/theme'

const styles = theme => ({
  container: {
    background: colors.pollQuestionDaily,
    borderTopRightRadius: 10,
    borderBottomRightRadius: 10,
    borderBottomLeftRadius: 10,
    padding: 20,
    position: 'relative'
  },
  maxheight: {
    height: 250,
    maxHeight: 250,
    overflow: 'hidden'
  },
  boxShadow: {
    boxShadow: '0 1px 5px 0 rgba(0,0,0,0.1)'
  },
  header: {
    color: colors.whiteText,
    textAlign: 'center'
  },
  headerContainer: {
    paddingBottom: 15
  },
  answerContainer: {
    paddingBottom: 15
  },
  buttonContainer: {
    paddingTop: 16
  },
  buttonCell: {
    flex: 1
  },
  button: {
    width: '100%'
  },
  submittingOverlay: {
    backgroundColor: '#fff',
    position: 'absolute',
    opacity: 0.3,
    top: 0,
    bottom: 0,
    left: 0,
    right: 0
  },
  submittedOverlay: {
    backgroundColor: '#fff',
    position: 'absolute',
    top: 0,
    bottom: 0,
    left: 0,
    right: 0
  }
})

export default styles
