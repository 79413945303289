import React from 'react'
import _ from 'lodash'

import ReportFullList from '../../../../Reporting/ReportFullList'

const StoreFullList = props => {
  const { store, reportSchema, match } = props
  return <ReportFullList
    items={[store]}
    reportSchema={reportSchema}
    timePeriod={_.get(match, 'params.timePeriod')}
  />
}

export default StoreFullList
