import digitalStoreSdk from '../../../digitalStoreSdk'
import * as constants from './constants'

class VariantsStock {
  fetchVariantStoreStocks = ({ variantId }) => {
    return {
      type: constants.FETCH_VARIANTS_STORE_STOCKS,
      variantId,
      promise: () => digitalStoreSdk.variantsStock.fetchVariantStoreStocks({ variantId })
    }
  }
}

export default new VariantsStock()
