import theme, { colors } from '../../config/theme'

const styles = {
  root: {
    margin: 0,
    padding: 0
  },
  h1: {
    color: colors.text,
    fontFamily: theme.base.fontFamily,
    fontSize: theme.h1.fontSize,
    fontWeight: theme.h1.fontWeight
  },
  h2: {
    ...theme.h2,
    color: colors.text
  },
  h3: {
    color: colors.text,
    fontFamily: theme.base.fontFamily,
    fontSize: theme.h3.fontSize,
    fontWeight: theme.h3.fontWeight
  },
  uppercase: {
    textTransform: 'uppercase'
  },
  alignCenter: {
    textAlign: 'center'
  }
}

export default theme => styles