import React from 'react'
import _ from 'lodash'
import CommentIcon from '@material-ui/icons/Comment'

import { translations, noPrices, apps } from '../../../../config'
import { getCustomerFullName } from '../../../../helpers'
import currencyFormatter from '../../../../formatters/currencyFormatter'

import { InChart as Generic } from '../Generic'
import withMessageCustomer from './withMessageCustomer'

const getBubbleText = (
  noPrices
    ? ({ totalUnits }) => `${totalUnits || 0} ${translations('units')}`
    : ({ totalSpend, totalUnits }) => _.template(translations('Customer Spending Info'))({
      totalSpend: currencyFormatter.format(totalSpend),
      totalUnits: totalUnits || 0
    })
)

let InChart = props => {
  const { reporting, messageCustomer, anonymised } = props
  const totalSpend = _.get(reporting, 'lifetime.profit.totalSpend', {})
  const totalUnits = _.get(reporting, 'lifetime.profit.totalUnits')

  const mainText = getCustomerFullName(props)

  const bubbleText = totalUnits === undefined ? currencyFormatter.format(totalSpend) : getBubbleText({ totalSpend, totalUnits })

  const rightContent = (
    apps.MESSAGING && !anonymised
      ? <CommentIcon onClick={messageCustomer} />
      : null
  )

  return <Generic
    mainText={mainText}
    bubbleText={bubbleText}
    rightContent={rightContent}
  />
}

InChart = withMessageCustomer(InChart)

export { InChart }
