import React, { Component, Fragment } from 'react'
import _ from 'lodash'
import FormControl from '@material-ui/core/FormControl'
import { withStyles } from '@material-ui/core/styles'
import { getPriceValue } from '@redant/digital-store-prices-beaverbrooks'

import { translations } from '../../../../config'
import currencyFormatter from '../../../../formatters/currencyFormatter'
import { Input, Dropdown } from '../../../../components/Fields'
import Form from '../../../../components/Form'
import BasicModalOverlay from '../../../../components/BasicModalOverlay'
import { required } from '../../../../validators'

import style from './style'
import ButtonGroup from '../../../../components/ButtonGroup'
import currencies from 'currency-formatter/currencies.json'

export const formId = 'add-discount-modal'
const ThisForm = Form(formId)

export const discountTypes = {
  percent: 'PERCENT_DISCOUNT',
  amount: 'AMOUNT_DISCOUNT'
}

class AddDiscountModal extends Component {
  typeOptions = [
    { label: translations('Percent'), value: discountTypes.percent },
    { label: translations('Amount'), value: discountTypes.amount }
  ]

  initialValues = {
    applyTo: 'ALL'
  }

  state = {
    selectedType: ''
  }

  validateAmount = amount => {
    const {
      nowPrice,
      discountType,
      products = [],
      orderDiscount = false
    } = this.props

    const num = Number(amount)
    // NOTE: the old messages are commented out rather than deleted
    // because there is a high chance that actually we do want them,
    // or want similar messages that obey similar logic
    if (discountType === discountTypes.percent) {
      if (!num) {
        // return `Please enter a percentage (> 0)`
        return `Please enter a valid discount amount`
      }
      if (num > 100) {
        // return `Please enter a percentage (<= 100)`
        return `Please enter a valid discount amount`
      }
    } else if (discountType === discountTypes.amount && !orderDiscount) {
      const isHigherThanLowestCost = products.some((product) => {
        const price = _.get(product, 'nowPrice')

        return Math.abs(parseInt(price.value)) < num
      })
      if (isHigherThanLowestCost) {
        return `You cannot apply this discount to one or more products in the basket, please review and try again`
      }
    } else if (discountType === discountTypes.amount) {
      const nowValue = getPriceValue(nowPrice)
      const isNegative = nowValue < 0
      if (!num) {
        // return `Please enter an amount (> 0)`
        return `Please enter a valid discount amount`
      }
      // We need opposite validation if the number is a negative or positive
      if (isNegative ? num < nowValue : num > nowValue) {
        // return `Please enter an amount (<= ${currencyFormatter.format({ value: nowValue })}`
        return `Please enter a valid discount amount`
      }
    } else {
      return required(amount)
    }
  }

  onSubmit = (...args) => {
    const [formFields, ...theRest] = args
    const { discountType, success } = this.props

    const withDiscountTypeType = { ...formFields, type: discountType }
    const newArgs = [withDiscountTypeType, ...theRest]

    success(...newArgs)
  }

  render () {
    const { actions, discountType, currencyCode, classes, setDiscountType, orderDiscount = false } = this.props

    const currencySymbol = _.get(currencies, [currencyCode, 'symbol'])

    const buttons = [
      { text: '%', onSelect: () => setDiscountType(discountTypes.percent) },
      { text: currencySymbol, onSelect: () => setDiscountType(discountTypes.amount) }
    ]

    return <ThisForm onSubmit={this.onSubmit} initialValues={this.initialValues}>
      <BasicModalOverlay
        title={translations('Add Discount Modal Title')}
        confirmButtonText={translations('Done')}
        actions={actions}
      >
        <div className={classes.formContainer}>
          <FormControl className={classes.formControl}>
            <div className={classes.buttonGroup}>
              <ButtonGroup buttons={buttons} />
            </div>
          </FormControl>
          <FormControl className={classes.formControl}>
            <Input
              currencyCode={currencyCode}
              name='amount'
              label={translations('Discount Amount')}
              type={discountType === discountTypes.amount ? 'price' : 'number'}
              validate={this.validateAmount}
              shrink
            />
          </FormControl>
          {orderDiscount ? (
            <p className={classes.discountDetailText}>{translations('Order discount modal detail text')}</p>
          ) : (
              <p className={classes.discountDetailText}>{translations('Item discount modal detail text')}</p>
            )}
        </div>
      </BasicModalOverlay>
    </ThisForm>
  }
}

export default withStyles(style)(AddDiscountModal)
