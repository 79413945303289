import StoreFullList from './StoreFullList'
import { connect } from 'react-redux'

import { selectors as storeDetailsSelectors } from '../../../../../store/modules/storeDetails'

const mapStateToProps = state => {
  const store = storeDetailsSelectors.getStore(state)
  return {
    store
  }
}

export default connect(mapStateToProps)(StoreFullList)
