import _ from 'lodash'
import React from 'react'
import Publication from './Publication'

import usePublications from '../hooks/usePublications'
import useSubscribe from '../hooks/useSubscribe'

/*
 *  The object model for the Room object (found here: https://www.twilio.com/docs/video/migrating-1x-2x#object-model) shows
 *  that Participant objects have TrackPublications, and TrackPublication objects have Tracks.
 *
 *  The React components in this application follow the same pattern. This ParticipantTracks component renders Publications,
 *  and the Publication component renders Tracks.
 */

const ParticipantTracks = ({
  participant,
  isLocalParticipant,
  videoOnly,
  audioOnly
}) => {
  if (!participant) return <></> // Don't render this component if there is no participant.

  const publications = usePublications(participant)
  /*
   * 'useSubscribe' is registering the trackSubscribed, trackUnsubscribed events to the participant.
   * We use the hook to display useful information when a participant subscribes/unsubscribes to a publication.
   */
  const tracks = useSubscribe(participant)

  return (
    <>
      {_.map(publications, (publication) => (
        <Publication
          key={publication.kind}
          publication={publication}
          participant={participant}
          isLocalParticipant={isLocalParticipant}
          videoOnly={videoOnly}
          audioOnly={audioOnly}
        />
      ))}
    </>
  )
}

export default ParticipantTracks
