const styles = theme => ({
  text: {
    whiteSpace: 'pre-line',
    overflowY: 'auto'
  },
  title: {
    fontWeight: 'bold',
    fontSize: '19px',
    fontFamily: 'Nunito',
    marginBottom: '21px'
  },
  container: {
    position: 'relative',
    background: '#000000',
    width: '100%',
    paddingTop: '56.25%'
  },
  buttonsContainer: {
    marginTop: 20
  },
  iframeStyle: {
    position: 'absolute',
    top: 0,
    left: 0,
    width: '100%',
    height: '100%'
  },
  loader: {
    position: 'absolute',
    top: 0,
    left: 0,
    width: '100%',
    height: '100%',
    display: 'flex',
    alignItems: 'center',
    justifyContent: 'center',
    color: '#FFF'
  }
})

export default styles
