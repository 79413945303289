import { connect } from 'react-redux'
import { compose, withHandlers } from 'recompose'
import { push } from 'connected-react-router'
import _ from 'lodash'

import { selectors as notificationsSelectors, actions as notificationsActions } from '../../../store/modules/notifications'
import { selectors as authSelectors } from '../../../store/modules/auth'
import { actions as usersActions } from '../../../store/modules/users'
import * as combinedNotificationsSelectors from '../../../store/modules/combinedSelectors/notificationsUserSelector'
import analyticsService from '../../../services/analyticsService'
import NotificationsScreen from './NotificationsScreen'
import * as authCombinedSelectors from '../../../store/modules/combinedSelectors/authCombinedSelectors'

const mapStateToProps = state => {
  const filters = notificationsSelectors.getNotificationsFilters(state)
  const filtersFalse = !filters.toDo && !filters.isFlagged
  const activeUserId = authSelectors.getActiveUserId(state)
  const notificationUserId = notificationsSelectors.getSelectedUserId(state)
  const selectedUserId = notificationUserId || activeUserId
  const currentUserSelected = !notificationUserId || (notificationUserId === activeUserId)
  return {
    notifications: combinedNotificationsSelectors.getNotificationsWithActionedValues(state),
    hasMore: notificationsSelectors.getNotificationsHasMore(state),
    isLoading: notificationsSelectors.getNotificationsIsLoadingInitial(state),
    canViewOtherUsersNotifications: authSelectors.getHasViewOtherUsersNotifications(state),
    filtersFalse,
    filters,
    userOptions: authCombinedSelectors.getUserOptionsIncludingCurrentUser(state),
    selectedUserId,
    currentUserSelected
  }
}

const mapDispatchToProps = dispatch => ({
  searchUsers: params => dispatch(usersActions.searchUsersFresh(params)),
  fetchNotifications: params => dispatch(notificationsActions.fetchUserNotificationsFresh(params)),
  dispatch
})

const handleNotificationClick = ({ meta, type, dispatch }) => {
  const customerId = _.get(meta, 'customerId')
  const orderNumber = _.get(meta, 'orderNumber')
  const referenceType = _.get(meta, 'referenceType')
  const referenceId = _.get(meta, 'referenceId')
  const analyticsData = {
    type,
    customerId
  }
  if (orderNumber) analyticsData.orderNumber = orderNumber
  analyticsService.sendCustomEvent({ type: 'notificationClickThrough', analyticsData })
  let path
  if (type === 'birthday') {
    path = `/customers/${customerId}`
  } else if (type === 'pendingQuestions') {
    path = '/polls'
  } else if (type === 'receivedMessage') {
    path = `/customers/${customerId}/messages`
  } else if (type === 'orderStatus') {
    path = `/orders/${orderNumber}`
  } else if (type === 'userToUser' || type === 'scheduledNotificationSent') {
    if (referenceType === 'customer') {
      path = `/customers/${referenceId}`
    } else if (referenceType === 'product') {
      path = `/product/${referenceId}`
    } else if (referenceType === 'inspiration') {
      path = `/inspirations/${referenceId}`
    }
  }
  path && dispatch(push(path))
}

export default compose(
  connect(mapStateToProps, mapDispatchToProps),
  withHandlers({
    onFiltersChange: ({ dispatch, filters, selectedUserId }) => status => {
      const newFilters = {
        ...filters,
        ...status
      }
      dispatch(notificationsActions.updateNotificationFilters(newFilters))
      selectedUserId
        ? dispatch(notificationsActions.fetchUserNotificationsFresh({ ...newFilters, id: selectedUserId }))
        : dispatch(notificationsActions.fetchPersonalNotificationsFresh(newFilters))
    },
    onUserInputChange: ({ searchUsers }) => _.debounce((inputValue) => {
      searchUsers({ query: inputValue })
    }, 500),
    onClick: ({ dispatch }) => ({ meta, type }) => {
      handleNotificationClick({ meta, type, dispatch })
    },
    onToggleStatus: ({ dispatch }) => ({ id, status }) => {
      dispatch(notificationsActions.setNotificationUserStatus({ id, status }))
    },
    fetchFreshNotifications: ({ dispatch, filters }) => e => {
      const userId = _.get(e, 'target')
      ? _.get(e.target, 'value')
      : e
      dispatch(notificationsActions.fetchUserNotificationsFresh(({ ...filters, id: userId })))
    },
    fetchNextNotifications: ({ dispatch, filters, selectedUserId }) => () => {
      dispatch(notificationsActions.fetchUserNotificationsNext({ ...filters, selectedUserId }))
    },
    onToggleActioned: ({ dispatch }) => ({ id, isActioned }) => {
      dispatch(notificationsActions.setNotificationActionedStatus({ id, isActioned }))
    }
  })
)(NotificationsScreen)
