import _ from 'lodash'
import { useCallback } from 'react'

import useIsTrackEnabled from './useIsTrackEnabled'
import useVideoContext from './useVideoContext'

const useLocalAudioToggle = () => {
  const { localTracks } = useVideoContext()
  const audioTrack = localTracks.find(({ kind }) => kind === 'audio')
  const isEnabled = useIsTrackEnabled(audioTrack)

  const toggleAudioEnabled = useCallback(() => {
    if (audioTrack) {
      audioTrack.isEnabled ? audioTrack.disable() : audioTrack.enable()
    }
  }, [audioTrack])

  return [isEnabled, toggleAudioEnabled]
}

export default useLocalAudioToggle
