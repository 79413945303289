import { useEffect, useState } from 'react'
import useVideoContext from '../hooks/useVideoContext'

/*
  Room state types:
  - disconnected
  - connected
  - reconnecting
*/

export default function useRoomState() {
  const { room } = useVideoContext()
  const [state, setState] = useState('disconnected')

  useEffect(() => {
    if (room) {
      const setRoomState = () => setState(room.state)
      if (room.state === 'connected') {
        setState(room.state)
      }
      room.on('disconnected', setRoomState)
      room.on('reconnected', setRoomState)
      room.on('reconnecting', setRoomState)
      return () => {
        room.off('disconnected', setRoomState)
        room.off('reconnected', setRoomState)
        room.off('reconnecting', setRoomState)
      }
    }
  }, [room])

  return state
}
