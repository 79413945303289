import _ from 'lodash'
import { useCallback, useEffect, useState } from 'react'
import useVideoContext from './useVideoContext'

const useLocalVideoToggle = () => {
  const [isPublishing, setIspublishing] = useState(false)
  const {
    room,
    localTracks,
    getLocalVideoTrack,
    removeLocalVideoTrack,
    onError
  } = useVideoContext()

  const localParticipant = _.get(room, 'localParticipant')
  const videoTrack = localTracks.find(({ kind }) => kind === 'video')

  const toggleVideoEnabled = useCallback(() => {
    if (!isPublishing) {
      if (videoTrack) {
        const localTrackPublication = localParticipant.unpublishTrack(videoTrack)
        // TODO: remove when SDK implements this event. See: https://issues.corp.twilio.com/browse/JSDK-2592
        localParticipant.emit('trackUnpublished', localTrackPublication)
        removeLocalVideoTrack()
      } else {
        setIspublishing(true)
        getLocalVideoTrack({ name: `${localParticipant.identity}-video-${Date.now()}` })
          .then((track) => {
            return localParticipant.publishTrack(track)
          })
          .catch(onError)
          .finally(() => {
            setIspublishing(false)
          })
      }
    }
  }, [videoTrack, localParticipant, getLocalVideoTrack, isPublishing, onError, removeLocalVideoTrack])

  return [!!videoTrack, toggleVideoEnabled]
}

export default useLocalVideoToggle
