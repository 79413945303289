import { colors } from '../../config/theme'

const style = theme => ({
  buttonContainer: {
    position: 'fixed',
    bottom: 0,
    left: 0,
    right: 0,
    width: '100%',
    height: theme.bottomBarHeight,
    background: colors.lightGrey,
    display: 'flex',
    flexDirection: 'row',
    alignItems: 'center',
    justifyContent: 'center',
    padding: '0 5%',
    [theme.breakpoints.down('xs')]: {
      padding: '0 8px'
    },

    '& button': {
      [theme.breakpoints.down('xs')]: {
        padding: 11
      }
    },
    '& button > span': {
      [theme.breakpoints.down('xs')]: {
        fontSize: 11,
        lineHeight: '14px',
        overflow: 'hidden',
        textOverflow: 'ellipsis',
        whiteSpace: 'normal'
      }
    }
  },
  spacing: {
    height: theme.bottomBarHeight
  },
  absolute: {
    position: 'absolute'
  }
})

export default style
