import actionBarStyle from '../../../components/ActionBar/style'

const styles = {
  requiredFieldsText: {
    fontFamily: 'Nunito',
    fontSize: '13px',
    lineHeight: '20px',
    margin: '25px 0'
  },
  contentContainer: {
    padding: '0 7.5% 20px'
  },
  subHeaderContainer: {
    display: 'flex',
    flexDirection: 'row',
    justifyContent: 'space-between',
    alignItems: 'center'
  },
  customerModeButtonContainer: {
    margin: 0
  },
  actionBar: {
    width: '100%',
    background: 'black',
    color: 'white',
    height: '56px',
    display: 'flex',
    alignItems: 'center',
    justifyContent: 'flex-end',
    padding: '15px'
  }
}

export default theme => ({
  ...actionBarStyle,
  ...styles
})
