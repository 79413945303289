import React, { useCallback } from 'react'
import PropTypes from 'prop-types'
import { useDispatch } from 'react-redux'
import { push } from 'connected-react-router'
import styled from 'styled-components'
import _ from 'lodash'
import moment from 'moment'
import copy from 'copy-to-clipboard'
import Grid from '@material-ui/core/Grid'
import PlayCircleFilledWhiteIcon from '@material-ui/icons/PlayCircleFilledWhite'
import { getImage } from '../../../../../components/Images'
import Chip from '../../../../../components/Chip'
import media from '../../../../../config/media'
import { translations } from '../../../../../config'
import modalService from '../../../../../services/modalService'
import toastService from '../../../../../services/toastService/toastService'
import AppointmentEditScreen from '../AppointmentEditScreen'
import { actions as appointmentsActions } from '../../../../../store/modules/appointments'
import { actions as createMessageActions } from '../../../../../store/modules/createMessage'
import { actions as currentAppointmentActions } from '../../../../../store/modules/currentAppointment'
import { actions as currentOrderActions } from '../../../../../store/modules/currentOrder'

const virtualAppointmentIcon = getImage('virtualAppointmentIcon')
const storeAppointmentIcon = getImage('storeAppointmentIcon')
const notesIcon = getImage('notesIcon')

const AppointmentEntry = styled(Grid)`
  position: relative;
`

const AppointmentCell = styled(Grid)`
  display: flex;
  flex-direction: column;
  padding: 12px;
  min-height: initial;
  font-size: 13px;
  &.infoCell {
    > b { display: block; }
    > img {
      position: absolute;
      right: 12px;
    }
  }
  &.notesCell {
    > img { margin-right: 5px; }
    color: ${({ hasNotes }) => hasNotes ? 'initial' : `rgba(0, 0, 0, 0.37)`};
  }
  &.statusCell,
  &.notesCell {
    display: flex;
    flex-direction: row;
    flex: .5;
    align-items: center;
    padding-top: 0;
  }
  &.notesCell { justify-content: flex-start; }
  &.statusCell { justify-content: flex-end; }

  ${media.greaterThan('md')`
    flex-direction: row;
    justify-content: flex-start;
    width: 100%;
    min-height: 56px;
    align-items: center;
    &.infoCell {
      > b { display: inline; margin-right: 5px; }
      > img {
        position: initial;
        margin-right: 12px;
      }
    }
    &.notesCell,
    &.statusCell {
      flex: 1;
      padding-top: 10px;
      align-items: center;
    }
  `}
`

const CustomerName = styled.div`
  font-weight: bold;
  > span { margin-right: 5px; }
`

const NewCustomerChip = styled(Chip)`
  margin-right: 5px;
`

const AbusiveCustomerChip = styled(Chip)`
  margin-right: 5px;
`

const getFormatedDate = (groupName, startDateTime, duration) => ({
  today: `today at ${moment(startDateTime).format('HH:mm')} ${duration ? `(${duration} minutes)` : ''}`,
  tomorrow: `tomorrow at ${moment(startDateTime).format('HH:mm')} ${duration ? `(${duration} minutes)` : ''}`
})[groupName] || `${moment(startDateTime).format('ddd DD/MM/YYYY')} at ${moment(startDateTime).format('HH:mm')} ${duration ? `(${duration} minutes)` : ''}`

const AppointmentsItem = ({ id, externalAppointmentId, type, startDateTime, customer, user, notes, service, details, status, group, bookingFirstName, bookingLastName, bookingNotes, bookingTelephone, calendar, editable }) => {
  const hideButton = typeof editable !== 'undefined' && !editable
  const dispatch = useDispatch()
  const userFullName = `${_.get(user, 'firstName', '')} ${_.get(user, 'lastName')}`
  const customerFullName = `${bookingFirstName || _.get(customer, 'firstName')} ${bookingLastName || _.get(customer, 'lastName')}`
  const when = getFormatedDate(group, startDateTime, _.get(details, 'duration'))
  const customerIsNew = _.get(details, 'customerIsNew')
  const customerIsAbusive = _.get(customer, 'details.is_this_an_abusive_customer')
  const calendarName = _.get(calendar, 'name')
  const onStartAppointmentClick = useCallback((id, externalAppointmentId, type) => {
    (type === 'VIRTUAL_CONSULTATION')
      ? dispatch(push(`/consultations/virtual/${externalAppointmentId || id}`))
      : dispatch(push(`/consultations/store/${externalAppointmentId || id}`))
  })

  const onAppointmentClick = useCallback(() => {
    modalService.open({
      id,
      externalAppointmentId,
      type,
      userFullName,
      customerFullName,
      when,
      startDateTime,
      customer,
      user,
      notes,
      service,
      bookingNotes,
      bookingTelephone: bookingTelephone || _.get(customer, 'telephone'),
      calendarName,
      component: AppointmentEditScreen,
      onCustomerMessageClick: customer => {
        modalService.close()
        dispatch(createMessageActions.updateReceipient(customer))
      },
      onCustomerProfileClick: customer => {
        modalService.close()
        dispatch(push(`/customers/${customer.id}`))
      },
      onCopyLinkClick: (url) => {
        const copiedLink = copy(url)
        if (copiedLink) {
          toastService.action({
            type: 'success',
            message: translations('Consultation link successfully copied'),
            verticalPosition: 'top',
            horizontalPosition: 'right'
          })
        }
      },
      onSaveAppointmentClick: (formData) => {
        if (formData) {
          dispatch(appointmentsActions.updateAppointment({
            id: _.get(formData, 'id'),
            userId: _.get(formData, 'user.id'),
            notes: _.get(formData, 'notes', null) || null
          }))
            .then(newAppointment => {
              toastService.action({
                type: 'success',
                message: translations('Appointment updated'),
                verticalPosition: 'top',
                horizontalPosition: 'right'
              })
            })
            .catch(error => {
              console.log(error)
              toastService.action({
                type: 'fail',
                message: translations('Failed to update appointment'),
                verticalPosition: 'top',
                horizontalPosition: 'right'
              })
            })
        }
        modalService.close()
      },
      onCancelAppointmentClick: () => {
        dispatch(appointmentsActions.cancelAppointment(externalAppointmentId || id))
          .then(newAppointment => {
            if (newAppointment.status === 'CANCELLED') {
              toastService.action({
                type: 'success',
                message: translations('The appointment was cancelled'),
                verticalPosition: 'top',
                horizontalPosition: 'right'
              })
            } else {
              throw new Error('Failed to cancel appointment')
            }
          }).catch(error => {
            console.log({ error })
            toastService.action({
              type: 'fail',
              message: translations('Failed to cancel appointment'),
              verticalPosition: 'top',
              horizontalPosition: 'right'
            })
          })
        modalService.close()
      }
    })
  })
  const withString = (user) && `${translations('with')} ${userFullName},`
  return (
    <AppointmentEntry key={id} container onClick={!hideButton ? onAppointmentClick : null }>
      <AppointmentCell classes={{ root: 'infoCell' }} item xs={12} sm={6} md={6} alignItems='flex-start'>
        {type === 'VIRTUAL_CONSULTATION' ? <img src={virtualAppointmentIcon} /> : <img src={storeAppointmentIcon} />}
        <CustomerName>
          <span>{customerFullName}</span>
          {customerIsNew && (
            <NewCustomerChip
              variant={'outlined'}
              type={'info'}
              clickable={false}
              style={{ fontSize: 10, marginRight: 5, width: 40, height: 20 }}>
              {_.upperCase(translations('New'))}
            </NewCustomerChip>
          )}
            {customerIsAbusive && (
              <AbusiveCustomerChip
                variant={'outlined'}
                type={'danger'}
                clickable={false}
                style={{ fontSize: 10, marginRight: 5, height: 20 }}>
                {_.upperCase(translations('Consultation - abusive customer'))}
              </AbusiveCustomerChip>
            )}
        </CustomerName>
        <p>{withString} {when}</p>
      </AppointmentCell>
      <AppointmentCell classes={{ root: 'notesCell' }} hasNotes={notes} item xs={12} sm={4} md={4} alignItems='flex-start'>
        <img src={notesIcon} />
        {notes || `No notes`}
      </AppointmentCell>
      <AppointmentCell classes={{ root: 'statusCell' }} item xs={12} sm={2} md={2} alignItems='flex-end'>
        {status === 'IN_PROGRESS' && !hideButton && (
          <Chip
            type={'success'}
            style={{ fontSize: 13, width: 95, height: 26 }}
            onClick={(e) => {
              e.stopPropagation()
              modalService.action({
                title: translations('Are you sure?'),
                text: translations('Are you sure you want to continue? This will remove the current user from the consultation'),
                actions: [
                  {
                    success: false,
                    text: translations('Cancel')
                  },
                  {
                    text: translations('Continue'),
                    primary: true,
                    onClick: () => {
                      onStartAppointmentClick(id, externalAppointmentId, type)
                    }
                  }
                ]
              })
            }}
          >
            {translations('In progress')}
          </Chip>
        )}
        {(status !== 'IN_PROGRESS' && status !== 'COMPLETE' && group === 'today' && !hideButton ) && (
          <Chip
            type={'normal'}
            style={{ fontSize: 13, width: 95, height: 26 }}
            onClick={(e) => {
              e.stopPropagation()
              dispatch(currentAppointmentActions.clearAppointment())
              dispatch(currentOrderActions.clearOrder({ showToast: false }))
              onStartAppointmentClick(id, externalAppointmentId, type)
            }}
            icon={<PlayCircleFilledWhiteIcon style={{ color: '#ffffff' }} />}
          >
            {translations('Start now')}
          </Chip>
        )}
      </AppointmentCell>
    </AppointmentEntry>
  )
}

AppointmentsItem.propTypes = {
  id: PropTypes.string.isRequired,
  externalAppointmentId: PropTypes.string,
  type: PropTypes.string.isRequired,
  startDateTime: PropTypes.instanceOf(Date).isRequired,
  customer: PropTypes.object.isRequired,
  user: PropTypes.object.isRequired,
  notes: PropTypes.string,
  service: PropTypes.string,
  details: PropTypes.object,
  status: PropTypes.string,
  group: PropTypes.string.isRequired
}

export default AppointmentsItem
