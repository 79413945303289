import React from 'react'
import PropTypes from 'prop-types'
import { withStyles } from '@material-ui/core/styles'
import FormHelperText from '@material-ui/core/FormHelperText';
import InputLabel from '@material-ui/core/InputLabel'

import Dropdown from '../Dropdown'
import Input from '../Input'

import style from './style'

const PhoneNumber = ({
  label,
  value,
  onChange,
  onBlur,
  options,
  passedProps,
  meta: { error, touched },
  touchTelephoneField,
  classes
}) => {
  return ([
    <InputLabel
      // FormControlClasses
      classes={{
        focused: classes.floatingLabelFocusStyle
      }}
      htmlFor={label}
      key='label'
      shrink
    >
      {label}
    </InputLabel>,
    <div key='inputs' className={classes.fieldContainer}>
      <div className={classes.dropdownContainer}><Dropdown value={value.code} onChange={(e) => {
        touchTelephoneField()
        onChange(e, 'code')
      }} onBlur={(e) => onBlur(e, 'code')
      } options={options} /></div>
      <div className={classes.inputContainer}><Input type='number' onChange={(e) => onChange(e, 'number')} onBlur={(e) => onBlur(e, 'number')} value={value.number} /></div>
    </div>,
    <FormHelperText className={classes.errorStyle} key='helper-text'>{touched && error}</FormHelperText>
  ])
}

PhoneNumber.propTypes = {
  value: PropTypes.shape({
    code: PropTypes.string,
    number: PropTypes.string
  }),
  touchTelephoneField: PropTypes.func.isRequired
}

export default withStyles(style)(PhoneNumber)
