import {
  TOGGLE_SIDE_MENU,
  TOGGLE_BASKET,
  OPEN_WEBVIEW,
  APP_READY,
  TOGGLE_CUSTOMER_MODE,
  ON_API_FAIL,
  ON_API_DISMISS,
  CHANGE_CLIENT_BOOK_TAB,
  CHANGE_SNAPSHOT_METRIC_TAB,
  CHANGE_SNAPSHOT_CAROUSEL_TAB,
  CHANGE_POLLS_TAB,
  CHANGE_STOREROOM_KANBAN_SELECTED_COLUMN,
  CHANGE_INSPIRATIONS_TAB,
  GO_TO_FEEDBACK,
  TOGGLE_CUSTOMER_REVIEW_BASKET,
  LOCK_SCREEN
} from './constants'

class AppActions {
  toggleSideMenu = ({ isOpen }) => ({
    type: TOGGLE_SIDE_MENU,
    isOpen
  })

  toggleBasket = ({ isOpen }) => ({
    type: TOGGLE_BASKET,
    isOpen
  })

  toggleCustomerReviewBasket = ({ isOpen }) => ({
    type: TOGGLE_CUSTOMER_REVIEW_BASKET,
    isOpen
  })

  openWebView = ({ url }) => {
    window.webkit.messageHandlers.reactNative.postMessage({
      event: OPEN_WEBVIEW,
      data: { url }
    })
  }

  appReady = ({ ready }) => ({
    type: APP_READY,
    ready
  })

  customerModeToggle = ({ isOn }) => ({
    type: TOGGLE_CUSTOMER_MODE,
    isOn
  })

  onApiFail = () => ({
    type: ON_API_FAIL
  })

  onApiDismiss = () => ({
    type: ON_API_DISMISS
  })

  changeClientBookTab = ({ clientBookTab }) => ({
    type: CHANGE_CLIENT_BOOK_TAB,
    clientBookTab
  })
  changeSnapshotMetricTab = ({ snapshotMetricTab }) => ({
    type: CHANGE_SNAPSHOT_METRIC_TAB,
    snapshotMetricTab
  })
  changeSnapshotCarouselTab = ({ snapshotCarouselTab }) => ({
    type: CHANGE_SNAPSHOT_CAROUSEL_TAB,
    snapshotCarouselTab
  })
  changePollsTab = ({ pollsTab }) => ({
    type: CHANGE_POLLS_TAB,
    pollsTab
  })

  changeStoreroomKanbanSelectedColumn = ({ selectedColumn }) => ({
    type: CHANGE_STOREROOM_KANBAN_SELECTED_COLUMN,
    selectedColumn
  })
  
  changeInspirationsTab = ({ tab }) => ({
    type: CHANGE_INSPIRATIONS_TAB,
    tab
  })

  lockScreen = ({ isLocked }) => ({
    type: LOCK_SCREEN,
    isLocked
  })
}

export default new AppActions()
