import { connect } from 'react-redux'
import { getFormValues } from 'redux-form'
import _ from 'lodash'

import { formId } from '../CustomerRegistrationForm'
import { selectors as storesSelectors } from '../../../../../store/modules/stores'

import CommunicationOptions from './CommunicationOptions'

const mapStateToProps = state => {
  const formValues = getFormValues(formId)(state)
  const isStoreMarketingSelected = _.get(formValues, 'storeMarketing')
  return {
    storesOptions: storesSelectors.getAllStoresAsOptions(state),
    isStoreMarketingSelected
  }
}

export default connect(mapStateToProps)(CommunicationOptions)
