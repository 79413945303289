import { colors } from '../../config/theme'

export default theme => ({
  buttonReset: {
    border: 'none',
    margin: 0,
    padding: 0,
    width: 'auto',
    overflow: 'visible',
    background: 'transparent',
    position: 'relative',
    color: 'inherit',
    font: 'inherit',
    lineHeight: 'normal',
    '-webkit-font-smoothing': 'inherit',
    '-moz-osx-font-smoothing': 'inherit',
    '-webkit-appearance': 'none',
    '&::-moz-focus-inner': {
        border: 0,
        padding: 0
    },
    '&:focus': {
      outline: 'none',
      '&:before': {
        opacity: 1
      }
    },
    '&:before': {
      display: 'block',
      pointerEvents: 'none',
      transition: 'opacity 0.3s',
      opacity: 0,
      border: `3px solid ${colors.primary}`,
      content: '""',
      position: 'absolute',
      top: 0,
      left: 0,
      bottom: 0,
      right: 0,
      zIndex: 1
    },
    '&:active': {
      '&:before': {
        opacity: 0
      }
    }
  }
})
