import { colors } from '../../config/theme'

const style = {
  text: {
    marginTop: 5,
    whiteSpace: 'nowrap',
    overflow: 'hidden',
    textOverflow: 'ellipsis',
    width: '100%',
    cursor: 'pointer',
    color: colors.tagGrey,
    fontSize: 13,
    fontStyle: 'italic',
    textDecoration: 'underline'
  },
  inlineText: {
    display: 'inline-block',
    padding: '5px 10px 5px 0',
    whiteSpace: 'nowrap',
    overflow: 'hidden',
    textOverflow: 'ellipsis',
    cursor: 'pointer',
    color: colors.tagGrey,
    fontSize: 13,
    fontStyle: 'italic',
    textDecoration: 'underline'
  }
}

export default style
