import _ from 'lodash'
import { matchPath } from 'react-router'
import { SUCCESS } from '../../middleware/redux-promise'
import { history } from '../../../store'
import * as currentOrderConstants from '../currentOrder/constants'
import * as chatConstants from '../chat/constants'
import * as currentAppointmentSelectors from './selectors'
import currentAppointmentActions from './actions'
import * as currentAppointmentConstants from './constants'
import { selectors as customerDetailsSelectors, actions as customerDetailActions, constants as customerDetailsConstants } from '../customerDetails'
import toastService from '../../../services/toastService/toastService'
import { translations } from '../../../config'

class CurrentAppointmentMiddleware {
  syncConsultationMiddleware = ({ dispatch, getState }) => next => (action) => {
    next(action)
    const shouldSync = action.status ? action.status === 'SUCCESS' : true
    if (shouldSync) {
      if (
        action.type === currentOrderConstants.ADD_PRODUCT_TO_ORDER ||
        action.type === currentOrderConstants.REMOVE_PRODUCT_FROM_ORDER ||
        action.type === currentOrderConstants.CHANGE_PRODUCT_QUANTITY_IN_ORDER ||
        action.type === currentOrderConstants.UPDATE_ORDER ||
        action.type === currentOrderConstants.CLEAR_ORDER ||
        action.type === currentOrderConstants.ADD_MANUAL_DISCOUNT_TO_PRODUCT ||
        action.type === currentOrderConstants.REMOVE_MANUAL_DISCOUNT_FROM_PRODUCT ||
        action.type === currentOrderConstants.EDIT_ORDER ||
        action.type === currentOrderConstants.SET_DISCOUNT ||
        action.type === currentOrderConstants.POPULATE_ORDER ||
        action.type === currentOrderConstants.TOGGLE_REFUND_ITEM ||
        action.type === currentOrderConstants.SET_STRIPE_PAYMENT_SUCCESS ||
        action.type === currentAppointmentConstants.START_APPOINTMENT ||
        action.type === currentAppointmentConstants.CLEAR_APPOINTMENT ||
        action.type === currentAppointmentConstants.ADD_CONTENT ||
        action.type === currentAppointmentConstants.REMOVE_CONTENT ||
        action.type === currentAppointmentConstants.TOGGLE_CONTENT_IS_SHOWING ||
        action.type === currentAppointmentConstants.TOGGLE_CONTENT_IS_LOVED ||
        action.type === currentAppointmentConstants.CUSTOMER_TOGGLE_CONTENT_IS_LOVED ||
        action.type === currentAppointmentConstants.SET_STAGE ||
        action.type === currentAppointmentConstants.SET_ORDER_NUMBER ||
        action.type === currentAppointmentConstants.SET_CUSTOMER ||
        action.type === currentAppointmentConstants.UPDATE_CURRENT_APPOINTMENT ||
        action.type === currentAppointmentConstants.END_APPOINTMENT ||
        action.type === chatConstants.CHANNEL_JOINED ||
        action.type === currentAppointmentConstants.SET_CUSTOMER_NAME
      ) {
        const state = getState()
        const isAppointmentActive = currentAppointmentSelectors.getIsAppointmentActive(state)
        const isAppointmentVirtual = currentAppointmentSelectors.getIsAppointmentVirtual(state)
        const appointmentId = currentAppointmentSelectors.getAppointmentId(state)
        const isPublic = matchPath(history.location.pathname, { path: '/consultations/virtual/:id/customer', exact: true, strict: true })
        const isPrivate = matchPath(history.location.pathname, { path: '/consultations/virtual/:id', exact: true, strict: true })
        const source = isPublic ? 'public' : (isPrivate ? 'private' : 'unknown')

        if (isAppointmentActive && appointmentId && isAppointmentVirtual) {
          const debouncedUpdatedSharedViewState = _.debounce(() => {
            dispatch(currentAppointmentActions.updateAppointmentSharedViewState({
              appointmentId,
              source
            }))
          }, 250, { maxWait: 500 })
          debouncedUpdatedSharedViewState()
        }
      }
    }
  }

  // this is for when the customer adds to wishlist
  // the network request needs to be done on the user view, as the customer view is not authenticated
  updateWishlistOnBehalfOfCustomer = ({ dispatch, getState }) => next => action => {
    if (action.source === 'public') {
      if (action.type === currentAppointmentConstants.SET_CURRENT_APPOINTMENT) {
        const state = getState()
        const currentAppointmentState = _.get(state, 'currentAppointment', {})
        const newAppointmentState = _.get(action, 'currentAppointment', {})

        const currentCustomerWishlist = customerDetailsSelectors.getCustomerWishlist(state)

        const customerWishlistUpdate = this._getCustomerWishlistUpdate({ currentAppointmentState, newAppointmentState, currentCustomerWishlist })

        if (customerWishlistUpdate) {
          const customerId = currentAppointmentSelectors.getAppointmentCustomerId(state)

          dispatch(customerDetailActions.updateCustomerWishlist({ id: customerId, details: { wishlist: customerWishlistUpdate } }))
        }
      }
    }
    next(action)
  }

  showToastWhenCustomerAddsToBasket = ({ getState }) => next => action => {
    if (action.type === currentOrderConstants.SET_CURRENT_ORDER) {
      const state = getState()
      const { products: currentOrderProducts } = _.get(state, 'currentOrder', {})
      const { products: newOrderProducts } = _.get(action, 'currentOrder', {})

      if (_.size(currentOrderProducts) < _.size(newOrderProducts)) {
        // item was added
        const currentOrderProductsIds = _.map(currentOrderProducts, (product) => product.id)
        const productAdded = _.find(newOrderProducts, (product) => !_.includes(currentOrderProductsIds, product.id))
        if (productAdded) {
          // if productAdded is undefined, then this was a quantity update - no need to show toast
          toastService.action({
            type: 'success',
            message: translations('Customer added {productName} to basket', { productName: productAdded.groupName || productAdded.name }),
            verticalPosition: 'top',
            horizontalPosition: 'right'
          })
        }
      } else if (_.size(currentOrderProducts) > _.size(newOrderProducts)) {
        // item was removed
        const newOrderProductsIds = _.map(newOrderProducts, (product) => product.id)
        const productRemoved = _.find(currentOrderProducts, (product) => !_.includes(newOrderProductsIds, product.id))
        if (productRemoved) {
          // if productRemoved is undefined, then this was a quantity update - no need to show toast
          toastService.action({
            type: 'success',
            message: translations('Customer removed {productName} from basket', { productName: productRemoved.groupName || productRemoved.name }),
            verticalPosition: 'top',
            horizontalPosition: 'right'
          })
        }
      }
    }
    next(action)
  }

  addCustomerMiddleware = ({ dispatch, getState }) => next => (action) => {
    if (action.type === currentOrderConstants.UPDATE_ORDER && action.customer) {
      const isAppointmentActive = currentAppointmentSelectors.getIsAppointmentActive(getState())
      if (isAppointmentActive) {
        dispatch(currentAppointmentActions.setCustomer({
          customerId: _.get(action, 'customer.id')
        }))
      }
    }
    next(action)
  }

  clearBasketMiddleware = ({ dispatch, getState }) => next => (action) => {
    if (action.type === currentOrderConstants.CLEAR_ORDER && action.status === 'SUCCESS') {
      const isAppointmentActive = currentAppointmentSelectors.getIsAppointmentActive(getState())
      if (isAppointmentActive && action.clearAppointment) {
        console.log(`clearing appointment`)
        dispatch(currentAppointmentActions.clearAppointment())
      }
    }
    next(action)
  }

  removeProductFromOrderMiddleware = ({ dispatch, getState }) => next => (action) => {
    if (action.type === currentOrderConstants.REMOVE_PRODUCT_FROM_ORDER && action.product && action.removeFromAppointment) {
      const isAppointmentActive = currentAppointmentSelectors.getIsAppointmentActive(getState())
      if (isAppointmentActive) {
        dispatch(currentAppointmentActions.removeContent({ type: 'product', details: action.product }))
      }
    }
    next(action)
  }

  addContentCheckIsLovedMiddleware = ({ dispatch, getState }) => next => (action) => {
    next(action)
    if (action.type === currentAppointmentConstants.ADD_CONTENT) {
      const state = getState()
      const contentId = _.get(action, 'content.details.id')
      const customerWishlist = customerDetailsSelectors.getCustomerWishlist(state)

      const itemIsInCustomerWishlist = _.find(customerWishlist, (wishlistItem) => wishlistItem.id === contentId)
      if (itemIsInCustomerWishlist) {
        dispatch(currentAppointmentActions.toggleContentIsLoved(_.get(action, 'content', {})))
      }
    }
  }

  setCustomerToCurrentAppointment = ({ dispatch, getState }) => next => (action) => {
    next(action)
    if (action.type === customerDetailsConstants.FETCH_FULL_CUSTOMER && action.status === SUCCESS) {
      if (currentAppointmentSelectors.getAppointmentId(getState())) {
        const customer = _.get(action, 'result.customer')
        dispatch(currentAppointmentActions.setCustomerDetails({ customer }))
      }
    }
    
  }

  _getItemsForWishlist = ({ contents = [] }) => {
    const lovedItems = _.filter(contents, (item) => _.get(item, 'details.isLoved', false))
    return lovedItems.map((item) => {
      return {
        ...item.details,
        type: item.type
      }
    })
  }

  _getCustomerWishlistUpdate = ({ currentAppointmentState, newAppointmentState, currentCustomerWishlist }) => {
    const currentAppointmentContents = _.get(currentAppointmentState, 'contents', [])
    const newAppointmentContents = _.get(newAppointmentState, 'contents', [])

    const currentAppointmentWishlist = this._getItemsForWishlist({ contents: currentAppointmentContents })
    const newAppointmentWishlist = this._getItemsForWishlist({ contents: newAppointmentContents })

    if (currentAppointmentWishlist.length !== newAppointmentWishlist.length) {
      return [...currentCustomerWishlist, ...newAppointmentWishlist]
    } else {
      return undefined
    }
  }
}

export default new CurrentAppointmentMiddleware()
