import React from 'react'
import styled from 'styled-components'

const Button = styled.div`
  display: flex;
  justify-content: center;
  align-items: center;
  border-radius: 100%;
  width: 55px;
  height: 55px;
  background-color: ${({ active }) => !active
    ? '#1a1a1a'
    : '#f44336'
  };
  color: white;
  margin: 0 10px;
  cursor: pointer;
`

const ToggleButton = ({ children, ...rest }) => <Button {...rest}>{children}</Button>

export default ToggleButton
