import { colors } from '../../../../config/theme'

const styles = {
  selectInput: {
    minWidth: 48
  },
  selectInputUnderline: {
    '&:after': {
      borderBottom: `2px solid ${colors.accent}`
    }
  }
}

export default theme => styles
