import { connect } from 'react-redux'
import { change } from 'redux-form'
import { goBack } from 'connected-react-router'

import { actions as rolesActions, selectors as rolesSelectors } from '../../../store/modules/roles'
import modalService from '../../../services/modalService'

import CustomerSearchScreen from '../../../containers/Customer/CustomerSearch'

import SelectCustomer from './SelectCustomer'

const mapStateToProps = state => ({ state })
const mapDispatchToProps = dispatch => ({ dispatch })

const mergeProps = ({ state }, { dispatch }, ownProps) => {
  const { formName, name, customerRegistrationComponent } = ownProps
  return {
    ...ownProps,
    onSearchClick: () => {
      const rolesStoreIsNotLoaded = rolesSelectors.getIsInitial(state)
      const rolesStoreHasError = rolesSelectors.getHasError(state)
      if (rolesStoreIsNotLoaded || rolesStoreHasError) {
        dispatch(rolesActions.fetchAllRoles())
      }
      modalService.open({
        modalIndex: 2,
        component: CustomerSearchScreen,
        hideBottomBar: true,
        fullScreen: true,
        inModal: true,
        registerCustomer: () => {
          if (customerRegistrationComponent) {
            modalService.open({
              modalIndex: 3,
              component: customerRegistrationComponent,
              hideBottomBar: true,
              fullScreen: true,
              inModal: true,
              onBackClick: () => {
                dispatch(goBack())
                modalService.close({ modalIndex: 3 })
              },
              afterSubmit: customer => {
                const { id, firstName, lastName, createdAt } = customer
                const value = {
                  id,
                  firstName,
                  lastName,
                  type: 'customer',
                  createdAt
                }
                dispatch(change(formName, name, value))
                dispatch(goBack())
                modalService.close({ modalIndex: 2 })
                modalService.close({ modalIndex: 3 })
              }
            })
          }
        },
        onCustomerClick: customer => {
          const { id, firstName, lastName, createdAt } = customer
          const value = {
            id,
            firstName,
            lastName,
            type: 'customer',
            createdAt
          }
          dispatch(change(formName, name, value))
          modalService.close({ modalIndex: 2 })
        },
        onBackClick: () => modalService.close({ modalIndex: 2 })
      })
    },
    onRemoveUserClick: () => dispatch(change(formName, name, ''))
  }
}

export default connect(mapStateToProps, mapDispatchToProps, mergeProps)(SelectCustomer)
