import _ from 'lodash'
import { useCallback, useState } from 'react'
import Video from 'twilio-video'
import {
  DEFAULT_VIDEO_CONSTRAINTS,
  SELECTED_AUDIO_INPUT_KEY,
  SELECTED_VIDEO_INPUT_KEY,
  getDeviceInfo,
  isPermissionDenied
} from '../helpers'

const useLocalTracks = () => {
  const [audioTrack, setAudioTrack] = useState()
  const [videoTrack, setVideoTrack] = useState()
  const [isAcquiringLocalAudioTracks, setIsAcquiringLocalAudioTracks] = useState(false)
  const [isAcquiringLocalVideoTracks, setIsAcquiringLocalVideoTracks] = useState(false)

  const getLocalAudioTrack = useCallback((deviceId) => {
    const options = {}

    if (deviceId) {
      options.deviceId = { exact: deviceId }
    }

    setIsAcquiringLocalAudioTracks(true)

    return Video.createLocalAudioTrack(options)
      .then(newTrack => {
        setAudioTrack(newTrack)
        return newTrack
      })
      .finally(() => setIsAcquiringLocalAudioTracks(false))
  }, [])

  const getLocalVideoTrack = useCallback(async ({ name }) => {
    const selectedVideoDeviceId = window.localStorage.getItem(SELECTED_VIDEO_INPUT_KEY)

    setIsAcquiringLocalVideoTracks(true)

    const { videoInputDevices } = await getDeviceInfo()

    const hasSelectedVideoDevice = videoInputDevices.some(
      device => selectedVideoDeviceId && device.deviceId === selectedVideoDeviceId
    )

    const options = {
      ...(DEFAULT_VIDEO_CONSTRAINTS || {}),
      name,
      ...(hasSelectedVideoDevice && { deviceId: { exact: selectedVideoDeviceId } })
    }

    return Video.createLocalVideoTrack(options)
      .then(newTrack => {
        setVideoTrack(newTrack)
        return newTrack
      })
      .finally(() => { setIsAcquiringLocalVideoTracks(false) })
  }, [])

  const publishLocalAudioTrack = useCallback(async (trackName, localParticipant) => {
    const {
      audioInputDevices,
      hasAudioInputDevices
    } = await getDeviceInfo()

    if (!hasAudioInputDevices) return Promise.resolve()
    if (isAcquiringLocalAudioTracks || audioTrack) return Promise.resolve()

    setIsAcquiringLocalAudioTracks(true)

    const selectedAudioDeviceId = window.localStorage.getItem(SELECTED_AUDIO_INPUT_KEY)

    const hasSelectedAudioDevice = audioInputDevices.some(
      device => selectedAudioDeviceId && device.deviceId === selectedAudioDeviceId
    )

    // In Chrome, it is possible to deny permissions to only audio or only video.
    // If that has happened, then we don't want to attempt to acquire the device.
    const isMicrophonePermissionDenied = await isPermissionDenied('microphone')

    const shouldAcquireAudio = hasAudioInputDevices && !isMicrophonePermissionDenied

    if (!shouldAcquireAudio) {
      if (isMicrophonePermissionDenied) {
        throw new Error('CameraPermissionsDenied')
      } else {
        const error = new Error()
        error.name = 'NotAllowedError'
        throw error
      }
    }

    const options = {
      ...shouldAcquireAudio && {
        name: trackName,
        ...(hasSelectedAudioDevice && { deviceId: { exact: selectedAudioDeviceId } })
      }
    }

    return Video.createLocalAudioTrack(options)
      .then(newAudioTrack => {
        if (newAudioTrack) {
          setAudioTrack(newAudioTrack)
          localParticipant.publishTrack(newAudioTrack)
        }

        // These custom errors will be picked up by the MediaErrorSnackbar component.
        if (isMicrophonePermissionDenied) {
          throw new Error('MicrophonePermissionsDenied')
        }
      })
      .catch((err) => {
        if (err.name === 'NotAllowedError') {
          throw new Error('MicrophonePermissionsDenied')
        }
      })
      .finally(() => setIsAcquiringLocalAudioTracks(false))
  }, [audioTrack, isAcquiringLocalAudioTracks])

  const publishLocalVideoTrack = useCallback(async (trackName, localParticipant) => {
    const {
      videoInputDevices,
      hasVideoInputDevices
    } = await getDeviceInfo()

    if (!hasVideoInputDevices) return Promise.resolve()
    if (isAcquiringLocalVideoTracks || videoTrack) return Promise.resolve()

    setIsAcquiringLocalVideoTracks(true)

    const selectedVideoDeviceId = window.localStorage.getItem(SELECTED_VIDEO_INPUT_KEY)

    const hasSelectedVideoDevice = videoInputDevices.some(
      device => selectedVideoDeviceId && device.deviceId === selectedVideoDeviceId
    )

    // In Chrome, it is possible to deny permissions to only audio or only video.
    // If that has happened, then we don't want to attempt to acquire the device.
    const isCameraPermissionDenied = await isPermissionDenied('camera')

    const shouldAcquireVideo = hasVideoInputDevices && !isCameraPermissionDenied

    if (!shouldAcquireVideo) {
      if (isCameraPermissionDenied) {
        throw new Error('CameraPermissionsDenied')
      } else {
        const error = new Error()
        error.name = 'NotAllowedError'
        throw error
      }
    }

    const options = {
      ...shouldAcquireVideo && {
        ...(DEFAULT_VIDEO_CONSTRAINTS || {}),
        name: trackName,
        ...(hasSelectedVideoDevice && { deviceId: { exact: selectedVideoDeviceId } })
      }
    }

    return Video.createLocalVideoTrack(options)
      .then(newVideoTrack => {
        setVideoTrack(newVideoTrack)
        if (newVideoTrack) {
          setVideoTrack(newVideoTrack)
          localParticipant.publishTrack(newVideoTrack)
          // Save the deviceId so it can be picked up by the VideoInputList component. This only matters
          // in cases where the user's video is disabled.
          // window.localStorage.setItem(
          //   SELECTED_VIDEO_INPUT_KEY,
          //   newVideoTrack.mediaStreamTrack.getSettings().deviceId ?? ''
          // )
        }

        // These custom errors will be picked up by the MediaErrorSnackbar component.
        if (isCameraPermissionDenied) {
          throw new Error('CameraPermissionsDenied')
        }
      })
      .catch((err) => {
        if (err.name === 'NotAllowedError') {
          throw new Error('CameraPermissionsDenied')
        }
      })
      .finally(() => setIsAcquiringLocalVideoTracks(false))
  }, [videoTrack, isAcquiringLocalVideoTracks])

  const toggleLocalAudioTrack = useCallback(() => {
    if (audioTrack) {
      return audioTrack.isEnabled ? audioTrack.disable() : audioTrack.enable()
    }
    return undefined
  }, [audioTrack])

  const removeLocalVideoTrack = useCallback(() => {
    if (videoTrack) {
      videoTrack.stop()
      setVideoTrack(undefined)
    }
  }, [videoTrack])

  const localTracks = [audioTrack, videoTrack].filter(track => track !== undefined)

  return {
    localTracks,
    getLocalVideoTrack,
    getLocalAudioTrack,
    isAcquiringLocalAudioTracks,
    isAcquiringLocalVideoTracks,
    toggleLocalAudioTrack,
    removeLocalVideoTrack,
    publishLocalAudioTrack,
    publishLocalVideoTrack
  }
}

export default useLocalTracks
