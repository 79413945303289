import { useEffect, useState } from 'react'
import _ from 'lodash'

import { notify } from '../helpers'

const usePublications = (participant) => {
  const [publications, setPublications] = useState([])

  useEffect(() => {
    if (!_.get(participant, 'tracks')) return

    // Set the Participant's initial track publications
    setPublications(Array.from(participant.tracks.values()))

    const publicationAdded = (publication) => {
      setPublications(prevPublications => [...prevPublications, publication])
    }
    const publicationRemoved = (publication) => {
      setPublications(prevPublications => prevPublications.filter(p => p !== publication))
    }

    console.log(`Registering 'Publishing' events for participant: ${participant.identity}`)

    participant.on('trackPublished', publicationAdded)
    participant.on('trackUnpublished', publicationRemoved)
    return () => {
      participant.off('trackPublished', publicationAdded)
      participant.off('trackUnpublished', publicationRemoved)
    }
  }, [participant])

  return publications
}

export default usePublications
