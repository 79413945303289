import { withPropsOnChange } from 'recompose'

const formatOptions = options => {
  return options.map(option => {
    if (typeof option === 'object') return option
    return { label: option, value: option }
  })
}

const OptionsFormatter = withPropsOnChange(
  ['options'],
  ({ options }) => ({
    options: formatOptions(options)
  })
)

export default OptionsFormatter
