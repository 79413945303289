import { connect } from 'react-redux'
import { compose, withPropsOnChange, withHandlers } from 'recompose'
import _ from 'lodash'
import Filters from './Filters'
import { actions as ordersActions, selectors as ordersSelectors } from '../../../../../../store/modules/orders'
import { selectors as usersSelectors, actions as usersActions } from '../../../../../../store/modules/users'
import { selectors as storesSelectors } from '../../../../../../store/modules/stores'
import { selectors as authSelectors } from '../../../../../../store/modules/auth'
import * as authCombinedSelectors from '../../../../../../store/modules/combinedSelectors/authCombinedSelectors'

const mapStateToProps = state => {
  const isLoading = ordersSelectors.getIsLoading(state)

  const activeUserId = authSelectors.getActiveUserId(state)
  const activeUser = authSelectors.getCurrentUser(state)

  // filter dropdown options
  const allStores = storesSelectors.getAllStores(state)
  const allUsers = usersSelectors.getResults(state)
  const orderTypeOptions = ordersSelectors.getOrderTypesAsOptions(state)
  const isLoadingUsers = usersSelectors.getIsLoading(state)

  // filters state
  const startDate = ordersSelectors.getStartDate(state)
  const endDate = ordersSelectors.getEndDate(state)
  const userId = ordersSelectors.getUserId(state)
  const storeId = ordersSelectors.getStoreId(state)
  const orderType = ordersSelectors.getOrderType(state)

  return {
    isLoading,
    activeUserId,
    activeUser,
    allStores,
    allUsers,
    isLoadingUsers,
    startDate,
    endDate,
    userId,
    storeId,
    orderTypeOptions,
    orderType,
    userOptions: authCombinedSelectors.getUserOptionsIncludingCurrentUser(state)
  }
}

const mapDispatchToProps = dispatch => ({
  searchOrders: params => dispatch(ordersActions.searchOrders(params)),
  searchUsers: params => dispatch(usersActions.searchUsersFresh(params))
})

const optionFromStore = (store) => ({
  label: store.name,
  value: store.id
})
const getStoreOptions = ({ allStores = [] }) => ({
  storeOptions: _.chain(allStores)
    .sortBy('name')
    .map(optionFromStore)
    .value()
})

export default compose(
  connect(mapStateToProps, mapDispatchToProps),
  withPropsOnChange(
    ['allStores'],
    getStoreOptions
  ),
  withHandlers({
    onUserInputChange: ({ searchUsers }) => _.debounce((inputValue) => {
      searchUsers({ query: inputValue })
    }, 1000)
  })
)(Filters)
