import theme, { colors } from '../../config/theme'

const styles = {
  h1: {
    ...theme.h1,
    color: colors.text
  }
}

export default theme => styles
