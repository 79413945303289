const styles = theme => ({
  content: {
    width: '100%',
    marginTop: 30,
    textAlign: 'center',
    fontSize: 19,
    fontWeight: 'bold'
  },
  actionButtons: {
    display: 'flex',
    alignItems: 'flex-end'
  },
  hideExtraSmall: {
    [theme.breakpoints.down('xs')]: {
      display: 'none',
      backgroundColor: 'green'
    }
  }
})

export default styles
