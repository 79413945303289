import { colors } from '../../../config/theme'

const styles = {
  floatingLabelFocusStyle: { color: colors.text },
  underlineFocusStyle: { borderColor: colors.text },
  errorStyle: { color: colors.error },
  errorText: { color: colors.error },
  borderFocussed: { borderColor: colors.lightGrey },
  inputInkbar: {
    '&:after': {
      backgroundColor: colors.text
    }
  }
}

export default theme => styles
