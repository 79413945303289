import { useEffect, useRef } from 'react'

const AudioTrack = ({ track }) => {
  const audioEl = useRef()

  useEffect(() => {
    audioEl.current = track.attach()
    audioEl.current.setAttribute('audio-track', track.name)
    document.body.appendChild(audioEl.current)
    return () =>
      track.detach().forEach(audioEl => {
        audioEl.remove()

        // This addresses a Chrome issue where the number of WebMediaPlayers is limited.
        // See: https://github.com/twilio/twilio-video.js/issues/1528
        audioEl.srcObject = null
      })
  }, [track])

  return null
}

export default AudioTrack
