import { useEffect, useRef } from 'react'
import { useDeviceSelectors } from '../../../../../../hooks/deviceDetect'

import useLocalVideoToggle from '../../hooks/useLocalVideoToggle'
import useVideoContext from '../../hooks/useVideoContext'

/*
  This component adds a visibilitychange handler to the document when
  the user is using a mobile device. When the handler detects that
  the browser has been backgrounded, it unpublishes the customer local
  video track. The browser cannot send video to the room when it has
  been backgrounded, so unpublishing the track stops video capture
  on the device, and triggers a UI update to the cosnultant
  to show that this customer's video track has been turned off.
*/

const AttachVisibilityHandler = () => {
  const { room } = useVideoContext()
  const [selectors] = useDeviceSelectors()
  const [isVideoEnabled, toggleVideoEnabled] = useLocalVideoToggle()
  const shouldRepublishVideoOnForeground = useRef(false)

  useEffect(() => {
    if (room && selectors.isMobile) {
      const handleVisibilityChange = () => {
        // We don't need to unpublish the local video track if it has already been unpublished
        if (document.visibilityState === 'hidden' && isVideoEnabled) {
          shouldRepublishVideoOnForeground.current = true
          toggleVideoEnabled()

          // Don't publish the local video track if it wasn't published before the app was backgrounded
        } else if (shouldRepublishVideoOnForeground.current) {
          shouldRepublishVideoOnForeground.current = false
          toggleVideoEnabled()
        }
      }

      document.addEventListener('visibilitychange', handleVisibilityChange)
      return () => {
        document.removeEventListener('visibilitychange', handleVisibilityChange)
      }
    }
  }, [isVideoEnabled, room, toggleVideoEnabled])

  return null
}

export default AttachVisibilityHandler
