import { createSelector } from 'reselect'
import _ from 'lodash'
import { amendOrder } from '@redant/digital-store-prices-beaverbrooks'

import * as regionsCombinedSelectors from './regionsCombinedSelectors'
import * as currentAppointmentCombinedSelectors from './currentAppointmentCombinedSelectors'
import orderFormatter from '../../../formatters/orderFormatter'

export const currentOrderSelector = createSelector([
  currentAppointmentCombinedSelectors.getRecomposedConsultationOrderForCheckout,
  regionsCombinedSelectors.getVatPercentAndCurrency
], (currentOrder, { vatPercent, currencyCode }) => {
  const amendedOrder = amendOrder(currentOrder, vatPercent)
  const order = orderFormatter.format(amendedOrder)
  const orderCurrencyCode = _.get(order, 'subTotal.code', null)
  if (!orderCurrencyCode) {
    order.subTotal = { value: 0, code: currencyCode }
  }
  return order
})

export const getCurrentOrderProducts = createSelector(
  currentOrderSelector,
  order => order.products
)

export const getCurrentOrderNumberOfProducts = createSelector(
  currentOrderSelector,
  order => order.numberOfProducts
)

export const getCurrentOrderNumberOfPurchasedProducts = createSelector(
  getCurrentOrderNumberOfProducts,
  products => {
    return _.filter(products, product => !product.unsold)
  }
)

export const getCurrentOrderHasPreviewProducts = createSelector(
  currentOrderSelector,
  order => order.hasPreviewProducts
)

export const getCurrentOrderGroupedProducts = createSelector(
  currentOrderSelector,
  order => order.groupedProducts
)

export const getBasketGroupedProducts = createSelector(
  currentOrderSelector,
  order => order.basketGroupedProducts
)

export const getCurrentOrderPurchasedGroupedProducts = createSelector(
  getCurrentOrderGroupedProducts,
  products => _.filter(products, product => !product.unsold)
)

export const getBasketType = createSelector([
  getCurrentOrderProducts
], products => {
  const containsRefund = products.some(product => product.refund)
  const containsRefundAndNormal = containsRefund && products.some(product => !product.refund)

  if (containsRefundAndNormal) return 'exchange'
  if (containsRefund) return 'refund'
  return 'default'
})
