import { connect } from 'react-redux'
import { matchPath } from 'react-router'
import { compose } from 'recompose'
import { actions as appActions, selectors as appSelectors } from '../../store/modules/app'
import { selectors as currentAppointmentSelectors } from '../../store/modules/currentAppointment'
import Header from './Header'
import { history } from '../../store'

const mapStateToProps = state => {
  const isInsideConsultation = matchPath(history.location.pathname, { path: '/consultations/:any' })
  const isAppointmentComplete = isInsideConsultation && currentAppointmentSelectors.getIsAppointmentComplete(state)
  return {
    customerModeUpdate: appSelectors.getCustomerModeStatus(state),
    isAppointmentActive: currentAppointmentSelectors.getIsAppointmentActive(state),
    isInsideConsultation,
    isAppointmentComplete
  }
}

const mapDispatchToProps = {
  customerModeToggle: () => appActions.customerModeToggle({ isOn: true }),
  openMenu: () => appActions.toggleSideMenu({ isOpen: true })
}

export default compose(
  connect(mapStateToProps, mapDispatchToProps)
)(Header)
