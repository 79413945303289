export const getIsMenuOpen = state => state.app.isMenuOpen
export const getIsBasketOpen = state => state.app.isBasketOpen
export const getIsCustomerReviewBasketOpen = state => state.app.isCustomerReviewBasketOpen
export const appReady = state => state.app.ready
export const getCustomerModeStatus = state => state.app.customerMode
export const getApiFailStatus = state => state.app.apiFail
export const getClientBookTab = state => state.app.clientBookTab
export const getSnapshotMetricTab = state => state.app.snapshotMetricTab
export const getSnapshotCarouselTab = state => state.app.snapshotCarouselTab
export const getPollsTab = state => state.app.pollsTab
export const getStoreroomKanbanSelectedColumn = state => state.app.storeroomKanbanSelectedColumn
export const getInspirationsTab = state => state.app.inspirationsTab
export const getIsScreenLocked = state => state.app.screenLocked
