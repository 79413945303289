import { colors } from '../../../config/theme'

const style = theme => ({
  listContainerTest: {
    background: colors.lightGrey,
    padding: '20px 30px',
    borderTop: `1px solid ${colors.border}`,
    height: `calc(100vh - ${theme.headerHeight + theme.subheaderHeight}px)`,
    overflow: 'auto'
  }
})

export default style
