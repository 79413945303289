import * as validators from '../../../validators'
import {
  countries,
  countryPhoneCodes,
  translations
} from '../../../config'

import CustomerEmailField from '../../../containers/Customer/CustomerEditableFields/Email'
import CustomerPhoneNumberField from '../../../containers/Customer/CustomerEditableFields/PhoneNumber'

const smallTextLengthValidator = validators.maxLength(50, 'Text')
const telephoneLengthValidator = validators.maxLength(25, 'Phone Number')
const dobAgeValidator = validators.dobMinAge(16)

export default [{
  id: 'title',
  field: 'Dropdown',
  props: {
    label: translations('Title'),
    name: 'title',
    options: ['Mr', 'Mrs', 'Ms', 'Dr', 'Sir', 'Madam'],
    validate: [smallTextLengthValidator],
    required: true
  }
}, {
  id: 'firstName',
  field: 'Input',
  props: {
    label: translations('First Name'),
    name: 'firstName',
    validate: [smallTextLengthValidator],
    required: true
  }
}, {
  id: 'lastName',
  field: 'Input',
  props: {
    label: translations('Last Name'),
    name: 'lastName',
    validate: [smallTextLengthValidator],
    required: true
  }
}, {
  id: 'email',
  component: CustomerEmailField,
  props: {
    label: translations('Email'),
    name: 'email',
    validate: [validators.email, smallTextLengthValidator],
    required: true,
    showEmailButton: true
  }
}, {
  id: 'dob',
  field: 'DatePicker',
  props: {
    label: translations('Date Of Birth'),
    name: 'dob',
    validate: [validators.date, dobAgeValidator],
    placeholder: translations('Date Of Birth - input placeholder')
  }
}, {
  id: 'telephone',
  component: CustomerPhoneNumberField,
  props: {
    label: translations('Phone Number'),
    name: 'telephone',
    options: countryPhoneCodes,
    validate: [telephoneLengthValidator, validators.phoneNumber],
    showSmsButton: true
  }
}, {
  id: 'address1',
  field: 'Input',
  props: {
    label: translations('Address Line One'),
    name: 'address.address1',
    validate: [smallTextLengthValidator]
  }
}, {
  id: 'address2',
  field: 'Input',
  props: {
    label: translations('Address Line Two'),
    name: 'address.address2',
    validate: [smallTextLengthValidator]
  }
}, {
  id: 'city',
  field: 'Input',
  props: {
    label: translations('City'),
    name: 'address.city',
    validate: [smallTextLengthValidator]
  }
}, {
  id: 'county',
  field: 'Input',
  props: {
    label: translations('County'),
    name: 'address.county',
    validate: [smallTextLengthValidator]
  }
}, {
  id: 'country',
  field: 'Dropdown',
  props: {
    label: translations('Country'),
    name: 'address.country',
    validate: [smallTextLengthValidator],
    options: countries
  }
}, {
  id: 'postCode',
  field: 'Input',
  props: {
    label: translations('Customer Postcode'),
    name: 'address.postCode',
    validate: [smallTextLengthValidator]
  }
}]
