import theme, { colors } from '../../config/theme'

const styles = {
  root: {
    margin: 0,
    padding: 0
  },
  content: {
    flexWrap: 'nowrap',
    backgroundColor: colors.offsetAccent,
    color: colors.primary
  },
  success: {
    color: colors.success
  },
  error: {
    color: colors.error
  },
  info: {
    color: colors.info
  },
  warning: {
    color: colors.warning
  },
  default: {
    color: colors.darkGrey
  },
  close: {
    backgroundColor: 'transparent'
  },
  SnackbarContent: {
    backgroundColor: 'transparent'
  },
  icon: {
    fontSize: 20
  },
  iconVariant: {
    opacity: 0.9,
    marginRight: 10
  },
  message: {
    display: 'flex',
    alignItems: 'center'
  },
  action: {
    paddingLeft: 10
  }
}

export default theme => styles
