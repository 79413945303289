import _ from 'lodash'
import { useEffect, useState } from 'react'
import useVideoContext from './useVideoContext'

import { notify } from '../helpers'

/*
 * The useParticipant hook return the 'Remote Participant' of the room.
 * It does that by tracking the room events 'participantConnected', 'participantDisconnected' to update the local state.
 * Also, it tracks the 'room.participants' for updates since the room may contain existing participants when we attempt to re-connect (usually happens on screen refresh).
 * 
 * NOTE (FUTURE WORK): Our app at the moment is build to support One-to-One consultant/customer rooms.
 * However, it is likely to expect One-to-Many consultant/customers rooms in the future.
 * Thus, the code bellow expects multiple participants as state but returns only one.
 * If that change we need just simply return all participants here and render them as a list.
 */
const useParticipant = () => {
  const { room } = useVideoContext()
  const [participants, setParticipants] = useState(
    _.get(room, 'participants')
      ? Array.from(room.participants.values() || [])
      : []
  )

  // The Room is not always ready on the first render, therefore,
  // the useState initialisation might not set the current participants of the room in this components local state.
  // To prevent this from happening we need to track the room participants using the useEffect hook and update the local state accordingly
  useEffect(() => {
    if (!_.get(room, 'participants')) return // Do nothing if no participants yet available.

    setParticipants(
      Array.from(room.participants.values() || [])
    )
  }, [_.get(room, 'participants')])

  useEffect(() => {
    if (room) {
      const participantConnected = (participant) => {
        setParticipants(prevParticipants => [...prevParticipants, participant])
        notify({
          type: 'success',
          message: `Successfully connected with ${participant.identity}`
        })
      }
      const participantDisconnected = (participant) => {
        setParticipants(prevParticipants => prevParticipants.filter(p => p !== participant))
        notify({
          type: 'info',
          message: `${_.capitalize(participant.identity)} disconnected from virtual consultation`
        })
      }
      room.on('participantConnected', participantConnected)
      room.on('participantDisconnected', participantDisconnected)
      return () => {
        room.off('participantConnected', participantConnected)
        room.off('participantDisconnected', participantDisconnected)
      }
    }
  }, [room])
  // NOTE (FUTURE WORK): return all participants if for one-to-many rooms.
  return participants[0]
}

export default useParticipant
