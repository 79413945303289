import { colors } from '../../config/theme'

const styles = theme => ({
  container: {
    marginBottom: 1
  },
  accordionHeader: {
    padding: 15,
    backgroundColor: colors.primary,
    color: colors.offsetAccent,
    fontWeight: 'bold',
    display: 'flex',
    flexDirection: 'row',
    justifyContent: 'space-between'
  },
  headerIcon: {
    width: 16,
    height: 16
  },
  headerIconOpen: {
    width: 16,
    height: 16,
    transform: 'rotate(180deg)',
    pointerEvents: 'none'
  }
})

export default theme => styles(theme)
