import { colors } from '../../config/theme'

const reusedStyles = theme => ({
  fromContainer: {
    padding: '15px',
    backgroundColor: colors.lightGrey,
    border: `1px solid ${colors.mediumGrey}`
  },
  fromContainerBreakpoint: {
    display: 'flex',
    flexDirection: 'column'
  },
  containerBreakpoint: {
    display: 'flex',
    alignItems: 'center',
    order: 1
  },
  buttonContainer: {
    display: 'flex',
    padding: '0 20px 10px 20px',
    width: '100%',
    justifyContent: 'center',
    marginRight: '-3px',
    [theme.breakpoints.up('sm')]: {
      justifyContent: 'flex-start'
    },
    [theme.breakpoints.up('md')]: {
      padding: '0 0 10px 0',
      justifyContent: 'flex-end',
      marginTop: 10
    }
  }
})

const style = theme => {
  const { fromContainer, fromContainerBreakpoint, containerBreakpoint, buttonContainer } = reusedStyles(theme)

  return {
    container: {
      whiteSpace: 'pre-line',
      display: 'flex',
      alignItems: 'flex-start'
    },
    paragraphSpacing: {
      marginBottom: 5
    },
    headerSpacing: {
      marginTop: 10
    },
    fromContainer: {
      ...fromContainer,
      [theme.breakpoints.up('md')]: {
        ...fromContainerBreakpoint,
        order: 1,
        borderRight: 'none'
      }
    },
    fromContainerFlipped: {
      ...fromContainer,
      [theme.breakpoints.up('md')]: {
        ...fromContainerBreakpoint,
        paddingLeft: 40,
        order: 2
      }
    },
    contentContainer: {
      flexDirection: 'column',
      alignSelf: 'stretch',
      border: `1px solid ${colors.mediumGrey}`,
      borderTop: 'none',
      [theme.breakpoints.up('md')]: {
        ...containerBreakpoint,
        borderTop: `1px solid ${colors.mediumGrey}`,
        order: 2
      }
    },
    innerContentContainer: {
      display: 'flex',
      flexDirection: 'column',
      width: '100%',
      minHeight: '100%'
    },
    contentContainerFlipped: {
      border: `1px solid ${colors.mediumGrey}`,
      [theme.breakpoints.up('md')]: {
        ...containerBreakpoint,
        order: 1
      }
    },
    contentWrapper: {
      width: '100%',
      padding: '20px',
      flex: 1
    },
    buttonContentWrapper: {
      padding: '10px 20px',
      borderTop: `1px solid ${colors.mediumGrey}`
    },
    multipleNoteSpacing: {
      marginBottom: 20,
      [theme.breakpoints.up('md')]: {
        marginBottom: 40
      }
    },
    readMoreLink: {
      color: colors.darkGrey,
      fontWeight: 900,
      fontSize: '13px',
      textTransform: 'uppercase',
      textAlign: 'right',
      display: 'block'
    },
    button: {
      minWidth: '120px',
      backgroundColor: '#FFFFFF',
      color: '#000000',
      fontSize: 13,
      border: '1px solid',
      '&:hover': {
        backgroundColor: '#ffffff'
      },
      margin: '0 3px'
    },
    buttonContainer: {
      ...buttonContainer,
      [theme.breakpoints.up('md')]: {
        display: 'none'
      }
    },
    buttonContainerLargeScreen: {
      ...buttonContainer,
      [theme.breakpoints.down('sm')]: {
        display: 'none'
      }
    },
    flippedButtonContainer: {
      justifyContent: 'flex-start'
    },
    contentLink: {
      fontSize: 13,
      fontWeight: 'bold',
      display: 'block',
      marginTop: 5
    },
    textButton: {
      cursor: 'pointer',
      textDecoration: 'underline',
      marginRight: 20,
      display: 'inline-block'
    },
    textButtonContainer: {
      display: 'inline-block'
    },
    arrowRight: {
      width: 0,
      height: 0,
      borderTop: '5px solid transparent',
      borderBottom: '5px solid transparent',
      borderLeft: '5px solid black',
      display: 'inline-block',
      marginRight: 5
    },
    disabledTextButton: {
      textDecoration: 'none'
    },
    flaggedContainer: {
      background: 'black',
      padding: 10,
      display: 'flex',
      alignItems: 'center'
    },
    flaggedText: {
      color: 'white',
      display: 'inline'
    },
    icon: {
      width: 26,
      height: 26,
      color: colors.offsetAccent,
      marginRight: 5
    }
  }
}

export default theme => style(theme)
