import React, { Fragment } from 'react'
import PropTypes from 'prop-types'
import Grid from '@material-ui/core/Grid'
import { withStyles } from '@material-ui/core/styles'
import cx from 'classnames'
import { getPriceValue } from '@redant/digital-store-prices-beaverbrooks'
import _ from 'lodash'

import P from '../../../../../components/P'
import H2 from '../../../../../components/H2'
import Button from '../../../../../components/Button'
import { translations, noPrices, deliveryEnabled } from '../../../../../config'
import currencyFormatter from '../../../../../formatters/currencyFormatter'
import { getDeliveryText, getCustomerFullName, getFullName } from '../../../../../helpers'

import style from './style'

const formatOrderType = (orderType) => {
  switch (orderType) {
    case 'consultation':
      return 'Consultation'
    case 'storeroom':
      return 'Pick From Store'
    default:
      return 'Transaction'
  }
}

const OrderHeader = (order) => {
  const {
    orderNumber, salesChannel, status, orderDate, orderType,
    numberOfProducts, subTotal, totalDiscount, tax, total, deliveryOption,
    deliveryPrice, deliveryDetails, customer, goToCustomer, classes, user, _store, details
  } = order

  const noUser = !order.userId
  const noCustomer = (!order.customerId && (_.isEmpty(order.customer) || !order.customer))
  const firstContainerClasses = cx({
    [classes.itemContainer]: true,
    [classes.firstContainer]: true,
    [classes.noPrice]: noPrices
  })

  const firstContainerSizeSmall = noPrices ? 12 : 8
  const leftFirstContainerSize = 4
  const rightFirstContainerSize = 8
  const leftSecondContainerSize = 8
  const rightSecondContainerSize = 4

  const firstColData = []
  firstColData.push(['Order Date', orderDate])
  firstColData.push(['Order Number', orderNumber])
  firstColData.push(['Order Type', formatOrderType(orderType)])
  firstColData.push(['Number Of Items', numberOfProducts])
  firstColData.push(['Order Status', _.startCase(status)])
  firstColData.push(['Order Channel', salesChannel])
  if (deliveryEnabled && customer) firstColData.push(['Order Delivery Option', getDeliveryText(noCustomer ? null : deliveryOption)])
  if (deliveryEnabled && _.get(deliveryDetails, 'store') && customer) firstColData.push(['Order Delivery Store', deliveryDetails.store.name])
  if (_store) firstColData.push(['Order Store', _store.name])
  if (user) firstColData.push(['Order User', getFullName(user)])

  const secondColData = []
  secondColData.push(['Subtotal', subTotal])
  secondColData.push(['Order Discount', totalDiscount])
  if (getPriceValue(tax)) {
    secondColData.push(['Order Tax', tax])
  }
  if (deliveryEnabled && deliveryOption) secondColData.push(['Order Delivery Cost', deliveryPrice])
  secondColData.push(['Order Total', total, { bold: true }])

  return (
    <Grid container spacing={0}>
      <Grid item xs={12} className={classes.customerDetailContainer}>
        {noCustomer && noUser ? (
          <H2 value={translations('Unassigned')} />
        ) : (
          <React.Fragment>
            <H2 value={getCustomerFullName(customer).toUpperCase()} />
            {customer ? (
              <Button onClick={goToCustomer} buttonType='white'>View Profile</Button>
            ) : (
              <div>{_.template(translations('Reason:'))({ reason: details.anonymousReason })}</div>
            )}
          </React.Fragment>
        )}
      </Grid>
      <Grid item xs={12} sm={firstContainerSizeSmall} className={firstContainerClasses}>
        <Grid container spacing={0}>
          {firstColData.map(([label, value]) => {
            return <Fragment>
              <Grid item xs={leftFirstContainerSize}><dt><P value={translations(label) + ':'} /></dt></Grid>
              <Grid item xs={rightFirstContainerSize}><dd><P value={value} /></dd></Grid>
            </Fragment>
          })}
        </Grid>
      </Grid>
      {
        !noPrices
          ? (
            <Grid item xs={12} sm={4} className={classes.itemContainer}>
              <Grid container spacing={0}>
                {secondColData.map(([label, value, props]) => {
                  return <Fragment>
                    <Grid item xs={leftSecondContainerSize}><dt><P {...props} value={translations(label) + ':'} /></dt></Grid>
                    <Grid item xs={rightSecondContainerSize}><dd><P {...props} value={currencyFormatter.format(value)} /></dd></Grid>
                  </Fragment>
                })}
              </Grid>
            </Grid>
          )
          : null
      }

    </Grid>
  )
}

OrderHeader.propTypes = {
  orderNumber: PropTypes.string.isRequired,
  salesChannel: PropTypes.string.isRequired,
  status: PropTypes.string.isRequired,
  orderDate: PropTypes.string.isRequired,
  numberOfProducts: PropTypes.string.isRequired,
  subTotal: PropTypes.string.isRequired,
  totalDiscount: PropTypes.string.isRequired,
  tax: PropTypes.string.isRequired,
  total: PropTypes.string.isRequired,
  classes: PropTypes.shape({}).isRequired
}

export default withStyles(style)(OrderHeader)
