import React, { useEffect } from 'react'
import MicIcon from '@material-ui/icons/Mic'
import MicOffIcon from '@material-ui/icons/MicOff'

import ToggleButton from './ToggleButton'

import useVideoContext from '../../hooks/useVideoContext'
import useLocalAudioToggle from '../../hooks/useLocalAudioToggle'

const ToggleAudioButton = ({ disabled = false }) => {
  const [isAudioEnabled, toggleAudioEnabled] = useLocalAudioToggle()
  const { localTracks } = useVideoContext()
  const hasAudioTrack = localTracks.some(track => track.kind === 'audio')

  return (
    <ToggleButton
      active={!isAudioEnabled || disabled}
      onClick={toggleAudioEnabled}
    >
      {!hasAudioTrack
        ? <MicOffIcon />
        : isAudioEnabled
          ? <MicIcon />
          : <MicOffIcon />}
    </ToggleButton>
  )
}

export default ToggleAudioButton
