import _ from 'lodash'
import digitalStoreSdk from '../../digitalStoreSdk'

class FileUploadService {
  _fileTypes = {
    'image/jpeg': 'jpg',
    'image/png': 'png',
    'image/gif': 'gif',
    'application/pdf': 'pdf'
  }

  _loadBlob = (localFileurl) => {
    if (window.cordova) {
      return this._loadBlobCordova(localFileurl)
    } else {
      return this._loadBlobWeb(localFileurl)
    }
  }

  _loadBlobWeb = (localFileurl) => {
    return new Promise((resolve, reject) => {
      const xhr = new XMLHttpRequest()
      xhr.open('GET', localFileurl, true)
      xhr.responseType = 'blob'
      xhr.onload = () => resolve(xhr.response)
      xhr.onerror = () => reject(xhr.statusText)
      xhr.send()
    })
  }

  _loadBlobCordova = (localFileurl) => {
    return new Promise((resolve, reject) => {
      window.resolveLocalFileSystemURL(localFileurl, (fileEntry) => {
        fileEntry.file((fileObj) => {
          var reader = new FileReader()
          reader.onloadend = (e) => {
            const blob = new Blob([new Uint8Array(e.target.result)], { type: fileObj.type })
            resolve(blob)
          }
          reader.readAsArrayBuffer(fileObj)
        })
      })
    })
  }

  upload = (localFileUrl) => {
    return this._loadBlob(localFileUrl)
      .then((blob) => {
        const name = `${(new Date()).getTime()}.${this._fileTypes[blob.type]}`
        return new Promise((resolve, reject) => {
          digitalStoreSdk.auth
            .fetchImageUploadUrl({
              fileName: name,
              contentType: blob.type
            })
            .then(({ url: uploadUrl }) => {
              const xhr = new XMLHttpRequest()
              xhr.open('PUT', uploadUrl)
              xhr.addEventListener('load', () => {
                const fileUrl = uploadUrl.split('?')[0]
                resolve(fileUrl)
              })
              xhr.addEventListener('error', () => {
                reject('upload error')
              })
              xhr.addEventListener('abort', () => {
                reject('upload abort')
              })
              xhr.setRequestHeader('Content-Type', blob.type)
              xhr.send(blob)
            })
        })
      })
  }

  openImagePickerLibrary = (fileSelectedCordova) => {
    if (window.cordova) {
      navigator.camera.getPicture(fileSelectedCordova, _.noop, {
        quality: 25,
        destinationType: window.Camera.DestinationType.FILE_URI,
        sourceType: window.Camera.PictureSourceType.PHOTOLIBRARY,
        encodingType: window.Camera.EncodingType.JPEG,
        mediaType: window.Camera.MediaType.PICTURE,
        allowEdit: false,
        correctOrientation: true
      })
    }
  }

  openImagePickerCamera = (fileSelectedCordova) => {
    if (window.cordova) {
      navigator.camera.getPicture(fileSelectedCordova, _.noop, {
        quality: 25,
        destinationType: window.Camera.DestinationType.FILE_URI,
        sourceType: window.Camera.PictureSourceType.CAMERA,
        encodingType: window.Camera.EncodingType.JPEG,
        mediaType: window.Camera.MediaType.PICTURE,
        allowEdit: false,
        correctOrientation: true
      })
    }
  }
}

export default new FileUploadService()
