import _ from 'lodash'
import { push } from 'connected-react-router'
import { history } from '../store'

import appsConfig from './configFiles/apps'
import configSchema from './schemas/apps-schema'
import generalConfig from './configFiles/general'
import kanban from './configFiles/kanban/config.default.json'
import { translations } from './translations'

const valid = configSchema.validate(appsConfig)

if (valid.error) {
  throw new Error(valid.error)
}

// Default App Routes - can be override by specifying 'route' in the app config
const appActions = {
  CATALOG: '/products',
  CUSTOMER_MANAGEMENT: '/customers',
  RETAIL_ANALYTICS: '/retail-analytics',
  USER_MANAGEMENT: '/users',
  MESSAGING: '/messages',
  NOTIFICATIONS: '/notifications',
  RESOURCES: '/resources',
  ORDER_MANAGEMENT: '/orders',
  POLLS: '/polls',
  APP_FEEDBACK: '/feedback',
  STOREROOM_KANBAN: '/pick-from-store',
  INSPIRATIONS: '/inspirations',
  WAITLIST: '/waitlist',
  EVENTS: '/events',
  CONSULTATIONS: '/consultations',
  STORES: '/stores',
  VIRTUAL_CONSULTATIONS: '/virtual-consultations'
}

const apps = _.chain(appsConfig.apps)
  .toPairs()
  .filter(([id, config]) => config.enabled)
  .reduce((memo, next) => {
    const [id, config] = next
    const route = config.route
    const app = {
      id,
      route: route || appActions[id],
      action: route ? push(route) : push(appActions[id]) || _.noop,
      ..._.pick(config, ['title', 'image', 'icon', 'badgeType', 'config', 'allowOffline', 'textColor'])
    }
    return {
      ...memo,
      [id]: app
    }
  }, {})
  .value()

const dashboardEnabled = _.chain(appsConfig.apps)
  .toPairs()
  .filter(([id, config]) => config.dashboard)
  .map(([id, config]) => id)
  .value()

const menuEnabled = _.chain(appsConfig.apps)
  .toPairs()
  .filter(([id, config]) => config.menu)
  .map(([id, config]) => id)
  .value()

const dashboard = _.chain(dashboardEnabled || [])
  .map(key => apps[key])
  .compact()
  .value()

const menu = [
  { id: 'DASHBOARD', action: push('/'), title: translations('Dashboard'), allowOffline: true },
  ...(
    _.chain(menuEnabled || [])
      .map(key => apps[key])
      .compact()
      .value()
  )
]

const { height, width } = generalConfig.tileDimensions

const tileRatio = (height / width) * 100

const getAppConfig = (app, config, defaultVal) => {
  if (!config) { return _.get(apps, `${app}.config`) }
  return _.get(apps, `${app}.config.${config}`, defaultVal)
}

const getAppConfigForCurrentRoute = (config, defaultVal) => {
  const path = history.location.pathname
  return getAppConfigForRoute(path, config, defaultVal)
}

const getAppConfigForRoute = (path, config, defaultVal) => {
  const app = Object.keys(apps).find(key => apps[key] && apps[key].route && apps[key].route.startsWith(path))
  if (!app) { throw new Error(`No app configuration found for pathname ${path}`) }
  return getAppConfig(app, config, defaultVal)
}

export {
  apps,
  dashboard,
  menu,
  kanban,
  tileRatio,
  getAppConfig,
  getAppConfigForCurrentRoute,
  getAppConfigForRoute
}
