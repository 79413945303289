import { connect } from 'react-redux'
import { compose, withHandlers } from 'recompose'
import StockInOtherStores from './StockInOtherStores'
import modalService from '../../../../../services/modalService'
import StoreList from './StoreList'

export default compose(
  connect(),
  withHandlers({
    openStoresList: () => () => {
      modalService.open({
        component: StoreList,
        hideBottomBar: true,
        onBackClick: () => modalService.close()
      })
    }
  })
)(StockInOtherStores)
