import { createSelector } from 'reselect'
import _ from 'lodash'
import { PENDING, SUCCESS, INITIAL } from '../../middleware/redux-promise'

import { FETCH_FULL_CUSTOMER } from './constants'

import currencyFormatter from '../../../formatters/currencyFormatter'
import orderFormatter from '../../../formatters/orderFormatter'
import { communicationOptions } from '../../../config'
import { getFullName } from '../../../helpers'

const formatMarketingValues = (result, keys) => {
  // remove keys which are false
  // leaves an array of keys that were true
  // this is the format which the checklist requires
  return _.chain(result)
    .pick(keys)
    .omitBy((value, key) => !value)
    .keys()
    .value()
}

export const addMarketingValuesToCustomer = customer => {
  const customerMarketingChannels = formatMarketingValues(customer, communicationOptions.channelKeys)
  const customerMarketingPreferences = formatMarketingValues(customer, communicationOptions.preferenceKeys)
  const customerOther = _.omit(customer, [...communicationOptions.channelKeys, ...communicationOptions.preferenceKeys])
  return {
    ...customerOther,
    customerMarketingChannels,
    customerMarketingPreferences
  }
}

export const getCustomer = createSelector([
  state => state.customerDetails.result || {}
], addMarketingValuesToCustomer)

export const getCustomerDetailsError = state => state.customerDetails.error

export const getIsInitialState = state => state.customerDetails.status === INITIAL
export const getInitialCustomerDetailIsLoading = state => state.customerDetails.status === PENDING && state.customerDetails.action === FETCH_FULL_CUSTOMER
export const getInitialCustomerDetailIsLoaded = state => state.customerDetails.status === SUCCESS && state.customerDetails.action === FETCH_FULL_CUSTOMER

export const getCustomerNotes = createSelector([
  getCustomer
], (customer) => {
  if (customer && customer.notes) {
    return customer.notes.sort((a, b) => {
      return new Date(b.timestamp) - new Date(a.timestamp)
    })
  } else {
    return []
  }
})

export const getCustomerReporting = createSelector([
  getCustomer
], (customer) => {
  return _.get(customer, 'reporting', {})
})

export const getCustomerLoyaltyType = createSelector([
  getCustomer
], (customer) => {
  return _.get(customer, 'loyaltyType')
})

export const getCustomerTotalSpend = createSelector([
  getCustomer
], (customer) => {
  // gone from standard totalSpend to being part of a time period
  const lifetimeSpend = _.get(customer, 'reporting.totalSpend') || _.get(customer, 'reporting.lifetime.profit.totalSpend')
  return lifetimeSpend
    ? currencyFormatter.format(lifetimeSpend.value, lifetimeSpend.code)
    : undefined
})

export const getCustomerLatestMessage = createSelector([
  getCustomer
], (customer) => {
  return _.get(customer, 'latestMessage')
})

export const getCustomerId = createSelector([
  getCustomer
], (customer) => {
  return _.get(customer, 'id')
})

export const getCustomerUserRelationshipId = createSelector([
  getCustomer
], (customer) => {
  return _.get(customer, 'customerUserRelationshipId')
})

export const getCustomerFullName = createSelector([
  getCustomer
], (customer) => {
  const firstName = _.get(customer, 'firstName')
  const lastName = _.get(customer, 'lastName')
  return getFullName(firstName, lastName)
})

export const getCustomerWishlist = createSelector([
  getCustomer
], (customer) => {
  return _.get(customer, 'wishlist', [])
})

export const getCustomerRecommendedProducts = createSelector([
  getCustomer
], (customer) => {
  return _.get(customer, 'recommendedProducts', [])
})

export const getIsCustomerAnonymised = createSelector([
  getCustomer
], (customer) => {
  return _.get(customer, 'anonymised')
})

export const getIsCustomerFollowed = createSelector([
  getCustomer
], (customer) => {
  return _.get(customer, 'following')
})

export const getCustomerOrders = createSelector([
  getCustomer
], (customer) => {
  const formattedOrders = _.get(customer, 'orders', []).map(orderFormatter.format)
  return formattedOrders
})

export const getCustomerPhotoOptIn = createSelector([
  getCustomer
], (customer) => {
  return _.get(customer, 'photoOptIn', false)
})
