import React from 'react'
import { RemoteAudioTrack } from 'twilio-video'
import useTrack from '../hooks/useTrack'
import AudioTrack from './Tracks/AudioTrack'
import VideoTrack from './Tracks/VideoTrack'

import usePublicationIsTrackEnabled from '../hooks/usePublicationIsTrackEnabled'

const Publication = ({
  publication,
  participant,
  isLocalParticipant,
  videoOnly = false,
  audioOnly = false
}) => {
  const track = useTrack(publication)

  usePublicationIsTrackEnabled(publication, participant, isLocalParticipant)

  if (!track) return null

  const renderPublication = (kind) => ({
    video: !audioOnly
      ? <VideoTrack
        track={track}
        isLocalParticipant={isLocalParticipant} />
      : null,
    audio: !videoOnly
      ? <AudioTrack track={track} />
      : null
  })[kind] || null

  return renderPublication(track.kind)
}

export default Publication
