import _ from 'lodash'
import { compose, mapProps } from 'recompose'
import MessageEditorOptions from './MessageEditorOptions'

export default compose(
  mapProps(props => _.pick(props, [
    'communicationType',
    'communicationTypeOther',
    'currentRecipients',
    'messageTemplateDropdownOptions',
    'optedOutMarketingPreferences',
    'optedOutMarketingChannels',
    'contactDetail',
    'hasSingleRecipient',
    'hasRecipients',
    'smsDisabled',
    'emailDisabled',
    'whatsappDisabled',
    'communicationTypeOptions',
    'communicationTypeOtherOptions'
  ]))
)(MessageEditorOptions)
