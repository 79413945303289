import React from 'react'
import { pure } from 'recompose'

import ChartTile from '../../../../components/Charts/ChartTile'
import ReportChart from '../../../Reporting/ReportChart'
import { slugify } from '../../../../helpers'
import { staffReportingSchema as schema } from '../../../../config'

const StaffCharts = ({
  user,
  timePeriod
}) => {
  return (
    schema.map((reportSchema, i) => {
      const link = `/retail-analytics/staff/${user.id}/${timePeriod}/${i}-${slugify(reportSchema.title)}`
      const to = reportSchema.fullList && !(reportSchema.type === 'LIST') && link
      const seeAllLink = reportSchema.fullList && (reportSchema.type === 'LIST') && link

      return (
        <ChartTile to={to || undefined} key={link}>
          <ReportChart
            items={[user]}
            reportSchema={reportSchema}
            seeAllLink={seeAllLink}
            timePeriod={timePeriod}
          />
        </ChartTile>
      )
    })
  )
}

export default pure(StaffCharts)
