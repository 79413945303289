import React, { useEffect, useCallback, useState } from 'react'
import _ from 'lodash'
import styled from 'styled-components'
import { useDispatch, useSelector } from 'react-redux'
import { matchPath } from 'react-router'
import { selectors as routingSelectors } from '../../../../store/modules/routing'
import modalService from '../../../../services/modalService'

import MicIcon from '@material-ui/icons/Mic'
import MicOffIcon from '@material-ui/icons/MicOff'
import PhoneIcon from '@material-ui/icons/Phone'

import { selectors as checkoutFlowSelectors } from '../../../../store/modules/checkoutFlow'

import useVideoContext from './hooks/useVideoContext'
import useLocalAudioToggle from './hooks/useLocalAudioToggle'

const Container = styled.div`
  z-index: ${({ isCheckout }) => isCheckout ? 1301 : 1300};
`

const Controls = styled.div`
  position: fixed;
  top: 7px;
  right: ${({ isCheckout }) => isCheckout ? '7px' : '75px'};
  background-color: #F9F6F6;
  z-index: 101;
  height: 35px;
  border-radius: 15px;
  display: flex;
  flex-direction: row;
  border: 1px solid #340c0c;
`

const ControlButton = styled.div`
  display: flex;
  justify-content: center;
  align-items: center;
  border-radius: 100%;
  width: 25px;
  height: 25px;
  background-color: ${({ active }) => !active
    ? '#1a1a1a'
    : '#f44336'
};
  color: white;
  margin: 4px;
  cursor: pointer;
`

const VideoHeaderControls = () => {
  const {
    room,
    localTracks,
  } = useVideoContext()
  const [isAudioEnabled, toggleAudioEnabled] = useLocalAudioToggle()

  const isCheckout = useSelector(checkoutFlowSelectors.getIsCheckoutOpen)

  const handleClickEndCall = useCallback(() => {
    if (room) {
      modalService.action({
        title: 'End call',
        text: 'Are you sure you want to disconnect?',
        actions: [
          {
            success: true,
            text: 'Yes',
            onClick: () => {
              room.disconnect()
            },
            primary: true
          },
          {
            text: 'Cancel',
            primary: false
          }
        ]
      })
    }
  }, [room])

  const handleClickMicrophone = useCallback(() => {
    if (room) {
      const hasAudioTrack = localTracks.some(track => track.kind === 'audio')
      if (hasAudioTrack) {
        toggleAudioEnabled()
      }
    }
  }, [localTracks])

  const renderControls = () => (
    <Controls isCheckout={isCheckout}>
      <ControlButton active={!isAudioEnabled} onClick={handleClickMicrophone}>
        {isAudioEnabled ? <MicIcon fontSize={'inherit'} /> : <MicOffIcon fontSize={'inherit'} />}
      </ControlButton>
      <ControlButton active onClick={handleClickEndCall}>
        <PhoneIcon fontSize={'inherit'} />
      </ControlButton>
    </Controls>
  )

  return (
    <Container isCheckout={isCheckout}>
      {room && renderControls()}
    </Container>
  )
}

export default VideoHeaderControls
