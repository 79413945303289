import React, { useEffect, useState } from 'react'
import { isSupported } from 'twilio-video'
import _ from 'lodash'
import { useSelector } from 'react-redux'
import styled from 'styled-components'
import CircularProgress from '@material-ui/core/CircularProgress'

import { selectors as currentAppointmentSelectors } from '../../../../../store/modules/currentAppointment'

import ParticipantTracks from './ParticipantTracks'
import VideoOverlay from './VideoOverlay'
import VideoControls from './VideoControls/VideoControls'

import useRoomState from '../hooks/useRoomState'
import useVideoContext from '../hooks/useVideoContext'
import useParticipant from '../hooks/useParticipant'
import useLogEvent from '../hooks/useLogEvent'
import usePermissions from '../hooks/usePermissions'

import {
  isPermissionDenied,
  showUnsupportedBrowserModal,
  showPermissionsDeniedModal
} from '../helpers'

const VideoPanelContainer = styled.div`
  position: relative;
  overflow: hidden;
  display: flex;
  flex: 1;
  background-color: ${() => window.cordova ? 'transparent' : 'black'};
  min-height: ${({ fullHeight }) => !fullHeight ? 'calc(100vh - 75px - 75px - 48px - 180px - env(safe-area-inset-top))' : '100%'};
`

const LoadingRoomConnection = styled.div`
  width: 100%;
  display: flex;
  align-items: center;
  justify-content: center;
`

const RoomLoadingIndicator = () => <LoadingRoomConnection><CircularProgress color={'secondary'} size={45} /></LoadingRoomConnection>

const VideoPanel = () => {
  const {
    appointmentId,
    room,
    isConnecting,
    isConsultant,
    screenLocalParticipantIdentity,
    publishLocalAudioTrack,
    publishLocalVideoTrack
  } = useVideoContext()
  const localParticipant = _.get(room, 'localParticipant')
  const remoteParticipant = useParticipant()
  const roomState = useRoomState()
  const isAppointmentComplete = useSelector(currentAppointmentSelectors.getIsAppointmentComplete)
  const logEvent = useLogEvent(isConsultant, appointmentId)

  const microphonePermissionState = usePermissions('microphone')
  const cameraPermissionState = usePermissions('camera')

  useEffect(() => {
    if (microphonePermissionState === 'denied' || cameraPermissionState === 'denied') {
      logEvent(`User permissions denied. Camera permission ${cameraPermissionState}, Microphone permission ${microphonePermissionState}`)
      showPermissionsDeniedModal()
    }
  }, [microphonePermissionState, cameraPermissionState])

  useEffect(() => {
    // Check if Twilio Video is suported by the browser
    if (!isSupported) {
      logEvent('User is using an unsupported browser')
      showUnsupportedBrowserModal()
    }
  }, [isSupported])

  useEffect(() => {
    // 'publishInitialTracks' will publish audio or video of the participant when they first enter the consultation.
    const publishInitialTracks = async () => {
      const isCameraPermissionDenied = await isPermissionDenied('camera')
      const isMicrophonePermissionDenied = await isPermissionDenied('microphone')

      if (isCameraPermissionDenied || isMicrophonePermissionDenied) {
        logEvent(`User permissions denied. Camera denied: ${isCameraPermissionDenied}, Microphone denied: ${isMicrophonePermissionDenied}`)
        showPermissionsDeniedModal()
      } else {
        try {
          /*
          * IMPORTANT: We only pusblish audio using 'publishLocalAudioTrack' when the VC starts.
          * To enable video, uncomment the line with 'publishLocalVideoTrack'.
          *
          * await publishLocalVideoTrack(`${screenLocalParticipantIdentity}-video-${Date.now()}`, localParticipant)
          */
          await publishLocalAudioTrack(`${screenLocalParticipantIdentity}-audio-${Date.now()}`, localParticipant)
        } catch (error) {
          logEvent(`Failed to do initial track publication with error: ${JSON.stringify(error)}`)
          if (error.message === 'MicrophonePermissionsDenied' || error.message === 'CameraPermissionsDenied') {
            showPermissionsDeniedModal()
          }
        }
      }
      return {
        isCameraPermissionDenied,
        isMicrophonePermissionDenied
      }
    }

    if (room) {
      try {
        publishInitialTracks()
      } catch (error) {
        // TODO: Do something with the errors.
        console.log({ error })
      }
    }
  }, [room])

  return (
    <VideoPanelContainer fullHeight={!isConsultant || isAppointmentComplete}>
      {(isConnecting || !room || roomState === 'disconnected')
        ? <RoomLoadingIndicator />
        : (
          <>
            {localParticipant && <ParticipantTracks participant={localParticipant} isLocalParticipant />}
            {remoteParticipant && <ParticipantTracks participant={remoteParticipant} />}
            <VideoOverlay participant={remoteParticipant} />
            <VideoControls />
          </>
        )}
    </VideoPanelContainer>
  )
}

export default VideoPanel
