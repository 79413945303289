import { lighten } from 'polished'
import { colors } from '../../config/theme'

const style = theme => ({
  bar: {
    paddingTop: 'env(safe-area-inset-top)',
    minHeight: theme.headerHeight,
    backgroundColor: lighten(0.28, colors.headerBg),
    display: 'flex',
    flexDirection: 'row'
  },
  right: {
    justifyContent: 'flex-end'
  },
  left: {
    justifyContent: 'flex-start'
  },
  closeIcon: {
    width: 26,
    height: 26,
    color: colors.headerBgIconColor
  },
  drawerTitle: {
    display: 'flex',
    flex: 1,
    padding: '0 10px'
  },
  drawerTitleText: {
    justifySelf: 'flex-start',
    color: '#FFFFFF'
  }
})

export default style
