import {
  TOGGLE_SIDE_MENU,
  TOGGLE_BASKET,
  APP_READY,
  TOGGLE_CUSTOMER_MODE,
  CHANGE_CLIENT_BOOK_TAB,
  CLIENT_BOOK_CONTACT_DETAILS,
  ON_API_FAIL,
  ON_API_DISMISS,
  CHANGE_SNAPSHOT_METRIC_TAB,
  SNAPSHOT_THIS_MONTH,
  SNAPSHOT_PURCHASES,
  CHANGE_SNAPSHOT_CAROUSEL_TAB,
  CHANGE_POLLS_TAB,
  POLLS_QUESTIONS,
  CHANGE_STOREROOM_KANBAN_SELECTED_COLUMN,
  CHANGE_INSPIRATIONS_TAB,
  BRAND_INSPIRATIONS,
  GO_TO_FEEDBACK,
  TOGGLE_CUSTOMER_REVIEW_BASKET,
  LOCK_SCREEN
} from './constants'

import {
  EDIT_ORDER,
  STOP_EDITING_ORDER
} from '../currentOrder/constants'

import {
  RESET_STORE as RESET_INSPIRATIONS_STORE
} from '../inspirations/constants'

import reducers from './reducers'

const defaultState = {
  isMenuOpen: false,
  appReady: false,
  customerMode: false,
  apiFail: false,
  clientBookTab: CLIENT_BOOK_CONTACT_DETAILS,
  snapshotMetricTab: SNAPSHOT_THIS_MONTH,
  snapshotCarouselTab: SNAPSHOT_PURCHASES,
  pollsTab: POLLS_QUESTIONS,
  inspirationsTab: BRAND_INSPIRATIONS,
  storeroomKanbanSelectedColumn: 0,
  feedbackType: undefined
}

export default (state = defaultState, action) => {
  const newState = {
    ...defaultState,
    ...state
  } // this is for adding new keys to a persist-whitelisted module

  switch (action.type) {
    case TOGGLE_SIDE_MENU:
      return reducers.toggleSideMenu(newState, action)
    case TOGGLE_BASKET:
      return reducers.toggleBasket(newState, action)
    case TOGGLE_CUSTOMER_REVIEW_BASKET:
      return reducers.toggleCustomerReviewBasket(newState, action)
    case APP_READY:
      return reducers.appReady(newState, action)
    case TOGGLE_CUSTOMER_MODE:
      return reducers.toggleCustomerMode(newState, action)
    case ON_API_FAIL:
      return reducers.onApiFail(newState, action)
    case ON_API_DISMISS:
      return reducers.onApiDismiss(newState, action)
    case CHANGE_CLIENT_BOOK_TAB:
      return reducers.changeClientBookTab(newState, action)
    case CHANGE_SNAPSHOT_METRIC_TAB:
      return reducers.changeSnapshotMetricTab(newState, action)
    case CHANGE_SNAPSHOT_CAROUSEL_TAB:
      return reducers.changeSnapshotCarouselTab(newState, action)
    case EDIT_ORDER:
      return reducers.editOrder(newState, action)
    case CHANGE_POLLS_TAB:
      return reducers.changePollsTab(newState, action)
    case STOP_EDITING_ORDER:
      return reducers.stopEditingOrder(newState, action)
    case CHANGE_STOREROOM_KANBAN_SELECTED_COLUMN:
      return reducers.changeStoreroomKanbanSelectedColumn(newState, action)
    case CHANGE_INSPIRATIONS_TAB:
      return reducers.changeInspirationsTab(newState, action)
    case RESET_INSPIRATIONS_STORE:
      return reducers.inspirationsReset(newState, action)
    case LOCK_SCREEN:
      return reducers.lockScreen(newState, action)
    default:
      return state
  }
}
