import digitalStoreSdk from '../../../digitalStoreSdk'
import couchbaseService from '../../../services/couchbaseService'
import * as constants from './constants'

import { selectors as networkSelectors } from '../network'

class ProductsActions {
  fetchProduct = ({ id, storeId }) => {
    return {
      type: constants.FETCH_PRODUCT,
      id,
      promise: (dispatch, getState) => {
        const state = getState()
        const isConnected = networkSelectors.isConnected(state)

        if (isConnected) {
          return digitalStoreSdk.products.fetchProduct({ id, storeId })
        }
        // offline mode
        return couchbaseService.fetchProduct({ id, storeId })
      }
    }
  }

  fetchProductGroupProducts = ({ productGroup, productId, storeId }) => {
    return {
      type: constants.FETCH_PRODUCT_GROUP_PRODUCTS,
      productGroup,
      promise: (dispatch, getState) => {
        const state = getState()
        const isConnected = networkSelectors.isConnected(state)

        if (isConnected) {
          return digitalStoreSdk.products.fetchProductGroupProducts({ id: productId, storeId })
        }
        // offline mode
        return couchbaseService.fetchProductGroupProducts({ productGroup, storeId })
      }
    }
  }

  changeProductBottomTab = ({ productTab }) => ({
    type: constants.CHANGE_PRODUCT_TAB,
    productTab
  })

  putActiveMediaIndex = ({ mediaIndex }) => ({
    type: constants.PUT_ACTIVE_MEDIA_INDEX,
    mediaIndex
  })

  resetActiveMediaIndex = () => ({
    type: constants.RESET_ACTIVE_MEDIA_INDEX
  })

  resetBottomTabs = () => ({
    type: constants.RESET_PRODUCT_DETAILS_EXPANDED
  })
}

export default new ProductsActions()
