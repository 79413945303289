import React, { Fragment } from 'react'
import cx from 'classnames'
import PropTypes from 'prop-types'
import { withStyles } from '@material-ui/core/styles'
import List from '@material-ui/core/List';
import ListItem from '@material-ui/core/ListItem';
import ListSubheader from '@material-ui/core/ListSubheader';
import { getImage } from '../../../components/Images'
import { getFullName } from '../../../helpers'

import style from './style'

const closeIcon = getImage('closeIconBlack')

const RecentUsers = ({ classes, className, users, onClick, onRemove }) => (
  <Fragment>
    {!!users.length && (
      <div className={cx(classes.container, className)}>
        <ListSubheader className={classes.title}>Recent users</ListSubheader>
        <List className={classes.list} dense>
          {users.map(({ id, username, ...userRest }) => (
            <ListItem button key={id} className={classes.item} onClick={() => onClick(username)}>
              {getFullName(userRest)}
              <button className={classes.closeButton} onClick={(e) => {
                e.stopPropagation()
                onRemove(id)
              }}>
                <img src={closeIcon} className={classes.closeButtonIcon} />
              </button>
            </ListItem>
          ))}
        </List>
      </div>
    )}
  </Fragment>
)

RecentUsers.propTypes = {
  users: PropTypes.arrayOf(PropTypes.shape({
    id: PropTypes.string,
    username: PropTypes.string
  })).isRequired,
  onClick: PropTypes.func.isRequired,
  onRemove: PropTypes.func.isRequired
}

export default withStyles(style)(RecentUsers)
