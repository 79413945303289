import { colors } from '../../../config/theme'

const styles = {
  wrapper: {
    display: 'inline-flex',
    marginBottom: '-20px'
  },
  fieldWrapperDate: {
    width: '60%'
  },
  fieldWrapperTime: {
    width: '40%',
    marginLeft: 40
  },
  errorStyle: { color: colors.error },
  floatingLabelFocusStyle: { color: colors.accent }
}

export default theme => styles
