import React, { useState } from 'react'
import InfoIcon from '@material-ui/icons/Info'

import { VideoRoomMonitor } from '@twilio/video-room-monitor'

import ToggleButton from './ToggleButton'

const ToggleMonitorButton = () => {
  return (
    <ToggleButton
      onClick={() => VideoRoomMonitor.toggleMonitor()}
    >
      <InfoIcon />
    </ToggleButton>
  )
}

export default ToggleMonitorButton
