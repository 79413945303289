import { colors } from '../../config/theme'
import {
  wizardStepPadding,
  wizardStepHeight,
  wizardStepMarginBottom
} from './constants'

import { getImage } from '../../components/Images'
const wizardAddIcon = getImage('wizardAddIcon')
const wizardViewIcon = getImage('wizardViewIcon')
const wizardTickIcon = getImage('wizardTickIcon')

export default () => ({
  container: {
    padding: 10
  },
  wizardStepWrap: {
    display: 'block',
    width: '100%',
    marginBottom: wizardStepMarginBottom,
    '&:last': {
      marginBottom: 0
    }
  },
  wizardStep: {
    display: 'flex',
    alignItems: 'center',
    height: wizardStepHeight,
    backgroundColor: '#F5F3F3',
    width: '100%'
  },
  wizardStepFormToggle: {
    position: 'absolute',
    top: 0,
    right: 0,
    pointerEvents: 'none',
    display: 'flex',
    alignItems: 'center',
    height: wizardStepHeight,
    backgroundColor: '#F5F3F3',
    paddingRight: wizardStepPadding
  },
  wizardStepFormActions: {
    display: 'flex',
    justifyContent: 'flex-end',
    padding: wizardStepPadding,
    borderBottom: `0.5px solid ${colors.contentBoxGrey}`
  },
  wizardStepInner: {
    display: 'flex',
    flex: 1,
    flexDirection: 'column',
    alignItems: 'flex-start',
    padding: wizardStepPadding
  },
  wizardStepInnerBasket: {
    display: 'flex',
    flex: 1,
    flexDirection: 'column',
    alignItems: 'flex-start',
    padding: 11
  },
  wizardStepLabel: {
    fontSize: 16,
    lineHeight: '21px',
    whiteSpace: 'normal',
    textAlign: 'left',
    margin: 0,
    textTransform: 'uppercase',
    fontWeight: 500
  },
  wizardStepLabelBasket: {
    fontSize: 14,
    lineHeight: '25px',
    whiteSpace: 'normal',
    textAlign: 'left',
    margin: 0
  },
  wizardStepDetail: {
    fontSize: 13,
    lineHeight: '24px',
    margin: 0,
    textTransform: 'uppercase',
    fontWeight: 500
  },
  wizardStepDetailBasket: {
    fontSize: 11,
    margin: 0,
    wordBreak: 'break-normal',
    whiteSpace: 'normal',
    lineHeight: 'normal',
    textAlign: 'left'
  },
  wizardStepCheck: {
    ...wizardCheckStyle,
    backgroundImage: `url(${wizardAddIcon})`
  },
  wizardStepCheckReview: {
    ...wizardCheckStyle,
    backgroundImage: `url(${wizardViewIcon})`
  },
  wizardStepCheckActive: {
    ...wizardCheckStyle,
    backgroundImage: `url(${wizardTickIcon})`
  },
  imagePicker: {
    display: 'none'
  },
  basketRectangle: {
    width: '75%',
    padding: 15,
    wordBreak: 'break-normal',
    whiteSpace: 'normal',
    textAlign: 'left'
  },
  wizardForm: {
    display: 'flex',
    position: 'relative'
  },
  wizardFormWrap: {
    border: `0.5px solid ${colors.contentBoxGrey}`,
    marginTop: `-${wizardStepMarginBottom}px`,
    marginBottom: wizardStepMarginBottom
  }
})

const wizardCheckStyle = {
  height: 75,
  width: 75,
  borderRight: '2px solid #FFFFFF',
  backgroundPosition: '50% 50%',
  backgroundRepeat: 'no-repeat',
  backgroundSize: '40px 40px',
  flexDirection: 'column'
}
