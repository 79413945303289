import { colors } from '../../../config/theme'

export default (theme) => ({
  emptyColumn: {
    border: `1px solid ${colors.border}`,
    marginTop: -1,
    height: 112,
    display: 'flex',
    justifyContent: 'center',
    alignItems: 'center'
  },
  emptyColumnText: {
    opacity: 0.5,
    lineHeight: '1'
  },
  searchResultsMobile: {
    padding: 20
  },
  refreshIcon: {
    color: colors.text,
    [theme.breakpoints.down('xs')]: {
      width: 40
    },
    [theme.breakpoints.up('sm')]: {
      marginRight: 5
    }
  },
  searchContainer: {
    flex: 1,
    [theme.breakpoints.down('xs')]: {
      marginRight: 25
    }
  },
  kanban: {
    height: `calc(100vh - ${theme.subheaderHeight + theme.headerHeight}px)`
  }
})
