import React, { useCallback, useEffect, useMemo } from 'react'
import styled from 'styled-components'
import { useDispatch, useSelector } from 'react-redux'
import _ from 'lodash'

import media from '../../../config/media'
import { actions as customerDetailsActions, selectors as customerDetailsSelectors } from '../../../store/modules/customerDetails'
import { selectors as currentAppointmentSelectors } from '../../../store/modules/currentAppointment'

import { useIsLoading, useIsInitial } from '../../../hooks/customerDetailsHooks'
import * as validators from '../../../validators'
import { getAppConfig } from '../../../config'
import modalService from '../../../services/modalService'

import Form, { FormSubmit } from '../../../components/Form'
import Heading from '../../../components/Heading'
import FormBody from '../../../components/Form/FormBody'
import LoaderContainer from '../../../components/LoaderContainer'
import ViewCustomerScreen from '../../Customer/ViewCustomerScreen'

export const formId = 'consultationCustomerDetailsForm'
const CustomerDetailsForm = Form(formId)

const customerFieldsConfig = getAppConfig('CUSTOMER_MANAGEMENT', 'detailsFields', [])
const customerDetailsFields = customerFieldsConfig.map(field => field.id)

const customerDetailsSchema = (customerFieldsConfig) && customerFieldsConfig.map(item => {
  const { id, field, validate, ...rest } = item
  return {
    id,
    field,
    props: {
      name: id,
      validate: validators.generateSchemaValidationFromConfig(validate),
      ...rest
    }
  }
})

const CustomerDetailsWrapper = styled.div`
  padding: 20px;
  background-color: white;

  display: ${props => props.mobileOpen ? 'block' : 'none'};
  ${media.greaterThan('md')`
    display: block;
  `}
`

const HeadingContainer = styled.div`
  display: flex;
  justify-content: center;
  align-items: center;
  flex-direction: column;
  text-transform: uppercase;
  font-size: 17px;
  line-height: 24px;
  margin-bottom: 20px;
`

const SubHeading = styled.div`
  text-decoration: underline;
  cursor: pointer;
  text-transform: none;
  font-size: 13px;
  line-height: 20px;
  margin-top: 4px;
  font-weight: bold;
`

const SubmitContainer = styled.div`
  padding: 20px;
  display: flex;
  justify-content: center;
  align-items: center;
`

const CustomerDetailsPanel = ({ customerId, mobileOpen }) => {
  const dispatch = useDispatch()
  const isCustomerDetailsLoading = useIsLoading()
  const isInitial = useIsInitial()
  const customer = useSelector(customerDetailsSelectors.getCustomer)
  const currentAppointment = useSelector(currentAppointmentSelectors.getCurrentAppointment)

  useEffect(() => {
    if (customerId) {
      dispatch(customerDetailsActions.fetchFullCustomer(customerId))
    }
  }, [customerId])

  const customerNotesInitialValues = useMemo(() => {
    return _.chain(customer)
      .pickBy((value, key) => {
        if (_.find(customerDetailsFields, (field) => key === field)) {
          return value
        }

        return undefined
      })
      .value()
  }, [customer, customerDetailsFields])

  const onOpenFullProfile = useCallback(() => {
    modalService.open({
      component: ViewCustomerScreen,
      fullScreen: true,
      overflowHidden: true,
      exit: () => {
        modalService.close()
      },
      multiSelect: true,
      noRouting: true
    })
  })

  const onSubmit = useCallback((formValues) => {
    dispatch(customerDetailsActions.updateCustomer({
      id: customerId,
      details: formValues
    }))
  })

  return (
    <CustomerDetailsWrapper mobileOpen={mobileOpen}>
      <HeadingContainer>
        <Heading>{`${_.get(currentAppointment, 'bookingFirstName') || _.get(customer, 'firstName')}'s Details`}</Heading>
        <SubHeading onClick={onOpenFullProfile}>{`View full profile`}</SubHeading>
      </HeadingContainer>
      {!isInitial && <LoaderContainer isLoading={isCustomerDetailsLoading}>
        <CustomerDetailsForm
          editing
          initialValues={customerNotesInitialValues}
          onSubmit={onSubmit}
          enableReinitialize
        >
          <FormBody
            schema={customerDetailsSchema}
            fullWidthFields
            noSpacing
          />
          <SubmitContainer>
            <FormSubmit>
              {'Save to profile'}
            </FormSubmit>
          </SubmitContainer>
        </CustomerDetailsForm>
      </LoaderContainer>}
    </CustomerDetailsWrapper>
  )
}

export default CustomerDetailsPanel
