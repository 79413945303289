import { colors } from '../../../config'
import { getImage } from '../../../components/Images'
const chevronIcon = getImage('chevronIcon')
const orientationHelperLandscape = getImage('orientationHelperLandscape')

const style = theme => ({
  container: {
    display: 'flex',
    flex: '1',
    flexDirection: 'column'
  },
  imageContainer: {
    display: 'flex',
    flex: '1',
    backgroundSize: 'cover',
    backgroundRepeat: 'no-repeat',
    backgroundPosition: 'center center',
    margin: 10
  },
  headerContainer: {
    display: 'flex',
    flexDirection: 'row',
    position: 'fixed',
    left: 10,
    right: 10,
    top: 10,
    background: 'rgba(0,0,0,0.5)',
    padding: 10
  },
  headerButton: {
    width: 'auto',
    border: '1px solid #FFFFFF'
  },
  headerButtonActive: {
    backgroundColor: '#FFFFFF',
    color: colors.primary,
    '&:hover': {
      backgroundColor: '#FFFFFF',
      color: colors.primary
    }
  },
  headerButtonSwitch: {
  },
  headerContainerLeft: {
    display: 'flex',
    flex: 1,
    justifyContent: 'flex-start'
  },
  headerContainerCenter: {
    display: 'flex',
    flex: 1,
    justifyContent: 'center'
  },
  headerContainerRight: {
    display: 'flex',
    flex: 1,
    justifyContent: 'flex-end'
  },
  contentContainer: {
    padding: '20px 10px',
    backgroundColor: '#F5F3F3'
  },
  containerTitle: {
    padding: '0 5%',
    backgroundColor: '#F5F3F3',
    zIndex: 1,
    position: 'relative',
    textAlign: 'center',
    fontSize: 24,
    lineHeight: '29px'
  },
  containerTitlewrap: {
    marginBottom: 20,
    display: 'flex',
    justifyContent: 'center',
    position: 'relative',
    '&::after': {
      content: "''",
      position: 'absolute',
      height: 1,
      backgroundColor: '#D6CECE',
      top: '50%',
      left: 0,
      right: 0
    }
  },
  productsContainer: {
    display: 'flex',
    overflowX: 'auto',
    alignItems: 'flex-start',
    flex: 1
  },
  productContainer: {
    width: 120,
    margin: '0 7px'
  },
  productAddToButton: {
    width: 120,
    height: 120,
    borderRadius: '100%',
    whiteSpace: 'normal'
  },
  productImage: {
    width: 108,
    height: 108,
    borderRadius: '100%',
    backgroundRepeat: 'no-repeat',
    backgroundSize: 'cover',
    backgroundPosition: 'center center',
    position: 'relative',
    overflow: 'hidden'
  },
  productImageWrap: {
    width: 120,
    height: 120,
    padding: 5,
    border: '1px solid #D6CECE',
    borderRadius: '100%',
    background: '#FFFFFF'
  },
  productTitle: {
    fontSize: 14,
    textAlign: 'center',
    marginTop: 10,
    whiteSpace: 'normal'
  },
  playIconWrap: {
    background: 'rgba(0,0,0,0.25)',
    position: 'absolute',
    top: 0,
    left: 0,
    width: '100%',
    height: '100%',
    pointerEvents: 'none',
    display: 'flex',
    justifyContent: 'center',
    alignItems: 'center'
  },
  playIcon: {
    display: 'block',
    width: 50,
    height: 50
  },
  lookNavigation: {
    display: 'flex',
    flex: 1,
    alignItems: 'center',
    justifyContent: 'space-between'
  },
  lookNavigationButton: {
    position: 'relative',
    overflow: 'hidden'
  },
  lookNavigationButtonLabel: {
    position: 'absolute',
    top: '-9999px'
  },
  lookNavigationButtonInnerLeft: {
    width: 100,
    height: 100,
    borderRadius: '100%',
    backgroundColor: 'white',
    margin: '0 0 0 -50px',
    background: `#FFF url(${chevronIcon}) no-repeat 75% 50%`
  },
  lookNavigationButtonInnerRight: {
    width: 100,
    height: 100,
    borderRadius: '100%',
    margin: '0 -50px 0 0',
    background: `#FFF url(${chevronIcon}) no-repeat 75% 50%`,
    transform: 'rotate(180deg)'
  },
  lookNavigationLoader: {
    width: 70,
    height: 70,
    borderRadius: '100%',
    backgroundColor: '#FFF',
    padding: 10
  },
  productCarousel: {
    display: 'flex',
    flexDirection: 'row'
  },
  addToButtonContainer: {
    display: 'flex',
    justifyContent: 'center',
    alignItems: 'center',
    padding: '10px 10px 10px 20px'
  },
  orientationHelper: {
    background: `url(${orientationHelperLandscape}) 50% 50% no-repeat`,
    position: 'fixed',
    top: 0,
    left: 0,
    right: 0,
    bottom: 0,
    zIndex: 2
  }
})

export default style
