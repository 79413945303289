import { colors } from '../../../config/theme'
import { tableGutter } from '../Table'

const styles = theme => ({
  tableHead: {
    backgroundColor: colors.lightGrey,
    textTransform: 'uppercase',
    color: colors.text,
    height: '56px',
    fontSize: '13px',
    fontWeight: 'bold',
    alignItems: 'center',
    padding: `0 ${0.5 * tableGutter}px`,
    [theme.breakpoints.up('xs')]: {
      flexWrap: 'nowrap'
    }
  }
})

export default styles
