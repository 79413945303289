import _ from 'lodash'
import { createSelector } from 'reselect'

import { selectors as authSelectors } from '../auth'
import { selectors as regionsSelectors } from '../regions'

export const getSelectedRegion = createSelector([
  regionsSelectors.getRegions,
  authSelectors.getUserSelectedRegionId
], (regions, selectedRegionId) => {
  const regionsArray = regions || []
  return _.find(regionsArray, region => region.id === selectedRegionId)
})

export const getVatPercentAndCurrency = createSelector(
  getSelectedRegion,
  region => {
    return { vatPercent: _.get(region, 'details.vatPercent'), currencyCode: _.get(region, 'currencyCode') }
  }
)

export const getSelectedRegionName = createSelector(
  getSelectedRegion,
  region => _.get(region, 'name')
)

export const getSelectedRegionLocationLookupCode = createSelector(
  getSelectedRegion,
  region => _.get(region, 'locationLookupCode')
)

export const getCTBasketAPIStoreCode = createSelector(
  getSelectedRegion,
  region => _.get(region, 'details.ctBasketAPIStoreCode')
)

export const getSelectedRegionStripeKey = createSelector(
  getSelectedRegion,
  region => _.get(region, 'details.stripePublicKey')
)
