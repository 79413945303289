import _ from 'lodash'
import modalService from '../../../services/modalService'
import AddDiscountModal, { discountTypes } from '../../Basket/BasketItem/AddDiscountModal'
import { translations } from '../../../config'
import { multiplyPrice } from '@redant/digital-store-prices-beaverbrooks/dist/handlePrices'

export const calculateTotalPrice = ({ products, currencyCode }) => {
  return _.chain(products)
    .map('price.value')
    .map(num => parseInt(num))
    .reduce((a, b) => a + b)
    .thru(total => ({ code: currencyCode, value: `${total}` }))
    .value()
}

export const calculateTotalDiscount = ({ products, currencyCode }) => {
  return _.chain(products)
    .map('manualDiscount.value')
    .map(num => parseInt(num))
    .reduce((a, b) => a + b)
    .thru(total => ({ code: currencyCode, value: `${total}` }))
    .value()
}

export const calculateDiscount = ({
  products: productsUnsanitized,
  success,
  currencyCode,
  orderDiscount = false
}) => {
  const products = _.filter(productsUnsanitized, (product) => {
    return !product.service
  })
  const sanitizeRefundProducts = _.filter(productsUnsanitized, (product) => {
    return !product.service && !product.refund
  })

  const totalPrice = calculateTotalPrice({ products: sanitizeRefundProducts, currencyCode })

  const handler = (result) => {
    const { type, amount } = result

    if (type === discountTypes.percent) { // WILL NEED TO DO THIS FOR EACH PRODUCT
      const withPercentageDiscount = products.map(product => {
        const nowPrice = _.get(product, 'nowPrice')
        let discount = {
          ...multiplyPrice(nowPrice, amount / 100),
          percent: amount,
          type
        }

        return { product, discount, applyTo: 1 }
      })

      return success({ discountedProducts: withPercentageDiscount, category: type, value: amount })
    }

    if (type === discountTypes.amount && orderDiscount) {
      // Convert an amount as a percentage relative to the total and apply
      // the same percentage on all products
      const percentage = (100 - (((parseInt(totalPrice.value) - amount) / parseInt(totalPrice.value)) * 100))
      let appliedDiscounts = 0

      const withAmountDiscount = sanitizeRefundProducts.map((product, index) => {
        const nowPrice = _.get(product, 'nowPrice')
        const multiply = (percentage / 100)
        let discountAmount = parseFloat(nowPrice.value * multiply).toFixed(2)

        // Compensate for any remainders on the last products' discount
        if (index === (sanitizeRefundProducts.length - 1)) {
          discountAmount = (parseFloat(amount) - appliedDiscounts).toFixed(2)
        } else {
          appliedDiscounts += parseFloat(discountAmount)
        }

        const discount = {
          value: discountAmount,
          code: nowPrice.code,
          type
        }

        return { product, discount, applyTo: 1 }
      })

      return success({ discountedProducts: withAmountDiscount, category: type, value: amount })
    } else if (type === discountTypes.amount) {
      const withAmountDiscount = products.map(product => {
        const nowPrice = _.get(product, 'nowPrice')
        const discount = {
          value: parseFloat(amount).toFixed(2),
          code: nowPrice.code,
          type
        }

        return { product, discount, applyTo: 1 }
      })

      return success({ discountedProducts: withAmountDiscount, category: type, value: amount })
    }
  }

  return handler
}

export const openAddDiscountModal = ({
  products = [],
  success,
  currencyCode,
  orderDiscount = false
}) => {
  console.log(products)
  const validProducts = _.filter(products, (product) => {
    console.log({ product })
    return !product.service && !product.refund
  })
  const totalPrice = calculateTotalPrice({ products: validProducts, currencyCode })
  const handler = calculateDiscount({
    products,
    success,
    currencyCode,
    orderDiscount
  })

  return modalService.open({
    component: AddDiscountModal,
    products: products,
    orderDiscount: orderDiscount,
    nowPrice: totalPrice,
    success: handler,
    actions: [
      {
        text: translations('Done'),
        success: true,
        primary: true
      }
    ]
  })
}
