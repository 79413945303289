import React from 'react'
import PrivateRoute from '../../../../components/PrivateRoute'
import StoreFullList from './StoreFullList'
import { slugify } from '../../../../helpers'
import { storeReportingSchema as schema } from '../../../../config'

// this only needs to be done once so it can be outside a render function
// because schema is static
const StoreFullListScreens = (
  schema
    .map((report, i) => {
      const path = `/retail-analytics/store/:storeId/:timePeriod/${i}-${slugify(report.title)}`
      return (
        report.fullList
          ? <PrivateRoute
            key={path}
            path={path}
            component={props => <StoreFullList {...props} reportSchema={report} />}
            exact
          />
          : null
      )
    })
    .filter(Boolean)
)

export default StoreFullListScreens
