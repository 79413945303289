import React from 'react'
import PrivateRoute from '../../components/PrivateRoute'
import RetailAnalyticsScreen from './RetailAnalyticsScreen'
import StaffFullListScreens from './StaffReporting/StaffFullListScreens'
import StoreFullListScreens from './StoreReporting/StoreFullListScreens'

import TimedLogoutComponent from '../../components/TimedLogout'

export default [
  ...StaffFullListScreens,
  ...StoreFullListScreens,
  <PrivateRoute key='/retail-analytics' path='/retail-analytics' component={TimedLogoutComponent(RetailAnalyticsScreen)} />
]
