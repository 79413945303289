import React from 'react'
import { withStyles } from '@material-ui/core/styles'

import style from './style'

const ChartsContainer = props => {
  const { children, classes } = props
  
  return (
    <div className={classes.container}>
      {children}
    </div>
  )
}

export default withStyles(style)(ChartsContainer)