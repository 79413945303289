/* eslint-disable react/prop-types */

// ** IMPORTANT - READ THIS IF YOU PLAN ON CHANGING ANY OF THE TWILIO VIDEO INTEGRATION **
// https://github.com/cordova-rtc/cordova-plugin-iosrtc/issues/497

import React, { createContext, useCallback, useEffect, useState } from 'react'
import { useDispatch, useSelector } from 'react-redux'
import _ from 'lodash'

import AttachVisibilityHandler from './AttachVisibilityHandler/AttachVisibilityHandler'

import {
  actions as appointmentsActions,
  selectors as appointmentsSelectors
} from '../../../../../store/modules/appointments'
import { selectors as currentAppointmentSelectors } from '../../../../../store/modules/currentAppointment'

import useRoom from '../hooks/useRoom'
import useLogEvent from '../hooks/useLogEvent'
import useLocalTracks from '../hooks/useLocalTracks'

import { getIsRoomNotInitialised, showPermissionsDeniedModal } from '../helpers'

export const VideoContext = createContext(null)

export const VideoProvider = ({
  children,
  isConsultant,
  appointmentId,
  onError = () => {}
}) => {
  const dispatch = useDispatch()
  const [connectOptions, setConnectOptions] = useState()
  const isAppointmentEnded = useSelector(currentAppointmentSelectors.getIsAppointmentEnded)
  const isAppointmentExpired = useSelector(appointmentsSelectors.getIsAppointmentExpired(appointmentId))
  const clientId = isConsultant ? 'consultant' : 'customer'

  const {
    localTracks,
    getLocalVideoTrack,
    getLocalAudioTrack,
    isAcquiringLocalAudioTracks,
    isAcquiringLocalVideoTracks,
    isAcquiringLocalTracks,
    toggleLocalAudioTrack,
    removeLocalVideoTrack,
    publishLocalAudioTrack,
    publishLocalVideoTrack
  } = useLocalTracks()

  const handleError = (error) => {
    logEvent(`ERROR: ${JSON.stringify(error)}`)
    if (error.name === 'NotAllowedError') {
      showPermissionsDeniedModal()
    }
    onError(error)
  }

  const onErrorCallback = () => useCallback(
    error => {
      handleError(error)
    },
    [onError]
  )

  const logEvent = useLogEvent(isConsultant, appointmentId)

  const {
    room,
    isConnecting,
    connect,
    connectionData
  } = useRoom(localTracks, onErrorCallback, connectOptions, logEvent)

  // TODO: Replace the hide/show Remote Participant's video functionality with
  // toggling the VideoTrack (enabled/disabled) for the Remote Participant.
  // Then remove this code from the Provider.
  const shouldParticipantBeVisibleByDefault = !isConsultant
  const [participantVideoIsEnabled, setParticipantVideoIsEnabled] = useState(false)

  const onClickHideParticipantVisible = useCallback(() => {
    setParticipantVideoIsEnabled(prevParticipantVideoIsEnabled => !prevParticipantVideoIsEnabled)
  })

  useEffect(() => {
    if (!connectionData) return
    logEvent(`${clientId} Connecting using device data: ${JSON.stringify(connectionData)}`)
  }, [connectOptions])

  useEffect(() => {
    const fetchVideoToken = async () => {
      try {
        const { token } = await dispatch(appointmentsActions.fetchVideoToken({
          clientId,
          appointmentId: appointmentId
        }))
        if (token) {
          logEvent('Successfully fetched video token')
          await connect(token)
        }
      } catch (error) {
        console.log({ error })
        logEvent(`Failed to fetched video token for appointmentId: ${appointmentId} and clientId: ${clientId}`)
      }
    }

    const noRoom = getIsRoomNotInitialised(room, appointmentId)

    if (!noRoom) return

    if (!clientId || !appointmentId || isAppointmentEnded || isAppointmentExpired) return

    logEvent(`Fetching video token for ${clientId} appointment ID: ${appointmentId}`)

    fetchVideoToken()
  }, [appointmentId, clientId])

  useEffect(() => {
    if (!appointmentId || isAppointmentEnded || isAppointmentExpired) return
    setConnectOptions({
      name: appointmentId,
      audio: { name: `${clientId}-audio-${Date.now()}` },
      video: { name: `${clientId}-video-${Date.now()}` },
      automaticSubscription: true,
      // VP8 simulcast enables the media server in a Small Group or Group Room
      // to adapt your encoded video quality for each RemoteParticipant based on
      // their individual bandwidth constraints.
      // NOTE: Simulcast should be disabled if you are using Peer-to-Peer or 'Go' Rooms.
      preferredVideoCodecs: [{ codec: 'VP8', simulcast: false }],
      region: 'ie1'
    })
  }, [appointmentId, isAppointmentEnded, isAppointmentExpired])

  return (
    <VideoContext.Provider
      value={{
        appointmentId,
        room,
        localTracks,
        screenLocalParticipantIdentity: isConsultant ? 'consultant' : 'customer',
        screenRemoteParticipantIdentity: isConsultant ? 'customer' : 'consultant',
        isConsultant,
        isConnecting,
        onError: handleError,
        connectionData,
        getLocalVideoTrack,
        getLocalAudioTrack,
        connect,
        isAcquiringLocalAudioTracks,
        isAcquiringLocalVideoTracks,
        isAcquiringLocalTracks,
        toggleLocalAudioTrack,
        removeLocalVideoTrack,
        publishLocalAudioTrack,
        publishLocalVideoTrack,
        participantVideoIsEnabled: shouldParticipantBeVisibleByDefault
          ? true
          : participantVideoIsEnabled,
        onClickHideParticipantVisible,
        logEvent
      }}
    >
      {children}
      <AttachVisibilityHandler />
    </VideoContext.Provider>
  )
}
