import { colors } from '../../config/theme'

export default (theme) => ({
  orderContainer: {
    backgroundColor: 'white',
    border: `1px solid ${colors.border}`,
    margin: 0,
    marginTop: -1,
    padding: '10px 6px',
    display: 'flex',
    flexDirection: 'row',
    flexWrap: 'wrap',
    overflowX: 'hidden'
  },
  labelValueContainer: {
    width: '50%',
    padding: '3px 8px',
    display: 'flex',
    flexDirection: 'row'
  },
  fullWidthLabelValueContainer: {
    width: '100%'
  },
  listLabelValueContainer: {
    flexDirection: 'column'
  },
  orderDataLabel: {
    marginRight: '0.2em',
    fontSize: 13
  },
  orderDataValue: {
    fontWeight: 'bold',
    fontSize: 13
  },
  orderDataButton: {
    fontWeight: 'inherit'
  },
  orderDataValueSku: {
    color: colors.primary
  }
})
