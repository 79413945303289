import { colors } from '../../config/theme'

const styles = theme => ({
  container: {
    borderRadius: 20,
    border: `1px solid ${colors.whiteText}`,
    padding: 10
  },
  text: {
    color: colors.whiteText
  }
})

export default styles
