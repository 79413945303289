import { useEffect, useState } from 'react'

const useTrack = (publication) => {
  const [track, setTrack] = useState(publication && publication.track)

  useEffect(() => {
    // Reset the track when the 'publication' variable changes.
    setTrack(publication && publication.track)

    if (publication) {
      const onTrackSubscribed = (track) => {
        setTrack(track)
      }

      const onTrackUnsubscribed = (track) => {
        setTrack(null)
      }

      publication.on('subscribed', onTrackSubscribed)
      publication.on('unsubscribed', onTrackUnsubscribed)
      return () => {
        publication.off('subscribed', onTrackSubscribed)
        publication.off('unsubscribed', onTrackUnsubscribed)
      }
    }
  }, [publication])

  return track
}

export default useTrack
