import React from 'react'
import _ from 'lodash'

import ReportFullList from '../../../../Reporting/ReportFullList'

const StaffFullList = props => {
  const { user, reportSchema, match } = props
  return <ReportFullList
    items={[user]}
    reportSchema={reportSchema}
    timePeriod={_.get(match, 'params.timePeriod')}
  />
}

export default StaffFullList
