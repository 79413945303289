const styles = theme => ({
  deactivateButton: {
    width: '130px',
    marginBottom: '10px',
    marginTop: '-10px',
    marginLeft: 'auto'
  },
  actionBar: {
    width: '100%',
    background: 'black',
    color: 'white',
    display: 'flex',
    alignItems: 'center',
    flexDirection: 'column',
    justifyContent: 'space-evenly',
    padding: '20px 15px 15px 15px',
    [theme.breakpoints.up('sm')]: {
      padding: 15,
      justifyContent: 'flex-end',
      flexDirection: 'row'
    }
  },
  actionBarHeader: {
    color: 'white',
    fontSize: 13,
    textTransform: 'uppercase',
    paddingBottom: 10,
    [theme.breakpoints.up('sm')]: {
      paddingBottom: 0,
      position: 'absolute',
      left: '50%',
      transform: 'translateX(-50%)'
    }
  },
  reactivateButton: {
    [theme.breakpoints.up('sm')]: {
      marginRight: '15px'
    }
  }
})

export default theme => styles(theme)
