import { colors } from '../../../../../config/theme'

const styles = theme => ({
  container: {

  },
  buttonContainer: {
    display: 'flex',
    flexDirection: 'row',
    alignItems: 'center',
    justifyContent: 'space-between',
    paddingLeft: 20,
    paddingRight: 20
  },
  legendContainer: {
    display: 'flex',
    flexDirection: 'row',
    alignItems: 'center',
    justifyContent: 'space-between',
    paddingTop: 10
  },
  scale: {
    height: 40,
    width: 40,
    borderRadius: '50%'
  },
  scaleImage: {
    height: 40,
    width: 40
  },
  scaleFaded: {
    height: 40,
    width: 40,
    borderRadius: '50%',
    opacity: 0.4
  },
  scaleImageFaded: {
    height: 40,
    width: 40
  },
  scaleEmphasised: {
    height: 50,
    width: 50,
    borderRadius: '50%'
  },
  scaleImageEmphasised: {
    height: 50,
    width: 50
  },
  legendTextDaily: {
    color: colors.whiteText,
    fontWeight: 'bold',
    fontSize: 10
  },
  legendText: {
    fontWeight: 'bold',
    fontSize: 10
  }
})

export default styles
