import _ from 'lodash'
import React from 'react'
import styled from 'styled-components'
import Grid from '@material-ui/core/Grid'
import marked from 'marked'
import { useSelector, useDispatch, useState } from 'react-redux'

import P from '../../../../components/P'
import H1 from '../../../../components/H1'

import SnapshotDetails from '../ProductResourceItem/Sections/SnapshotDetails'
import Notice from '../../ConstulationPartialComponents/Notice'
import StripePayment from '../StripePayment'

import currencyFormatter from '../../../../formatters/currencyFormatter'

import * as currentOrderCombinedSelectors from '../../../../store/modules/combinedSelectors/currentOrderCombinedSelectors'
import { selectors as currentOrderSelectors, actions as currentOrderActions } from '../../../../store/modules/currentOrder'
import { selectors as checkoutFlowSelectors, actions as checkoutFlowActions } from '../../../../store/modules/checkoutFlow'
import { actions as currentAppointmentActions } from '../../../../store/modules/currentAppointment'

import { translations, environment } from '../../../../config'
import { colors } from '../../../../config/theme'

const Section = styled.div`
  padding: 1rem;
`

const Title = styled(H1)`
  text-align: center;
  font-size: 1.25rem;
  margin-bottom: 1rem;
`

const SubTitle = styled(P)`
  text-align: center;
`

const BasketInfo = styled.div`
  padding: 2rem 4rem;
  background: ${colors.lightGrey};
`

const BasketInfoText = styled.div`
  text-align: center;
  font-size: 1rem;
  line-height: 1.5;
`

const ProductItem = styled.div`
  border-bottom: 1px solid ${colors.lightGrey};
`

const ProcessingOrderNotice = styled.div`
  background-color: #fffadb;
  border: 1px solid #ffe6db;
  padding: 1rem;
  text-align: center;
`

const ReviewConsultationPanel = ({
  appointmentStage,
  basketTotal
}) => {
  const dispatch = useDispatch()
  const order = useSelector(currentOrderCombinedSelectors.currentOrderSelector)
  const orderTotal = _.get(order, 'total.value')
  const calculatedOrderTotal = _.get(order, 'total')
  const orderProductsCount = useSelector(currentOrderCombinedSelectors.getCurrentOrderNumberOfProducts)
  const appointmentProducts = useSelector(currentOrderCombinedSelectors.getCurrentOrderGroupedProducts)
  const basketProducts = useSelector(currentOrderCombinedSelectors.getBasketGroupedProducts)
  const paymentDetails = useSelector(currentOrderSelectors.getPaymentDetails) || {}
  const {
    stripePublicKey,
    paymentIntentTokenPublic,
    paymentIntentTokenSecret,
    basketStoreCode,
    retailOSOrderId,
    basketSessionToken
  } = paymentDetails

  const productsToDisplay = appointmentStage === 'REVIEW' ? appointmentProducts : basketProducts
  const totalToDisplay = appointmentStage === 'REVIEW' ? calculatedOrderTotal : basketTotal
  // const hasProducts = productsToDisplay.length > 0
  const hasProducts = _.size(productsToDisplay) > 0
  const showTotalSummary = hasProducts && totalToDisplay && totalToDisplay.value !== 0

  const onPaymentError = (err) => {
    dispatch(currentAppointmentActions.setStage({ stage: 'PAYMENT_PENDING' }))
  }

  const onPaymentSuccess = (paymentIntent) => {
    dispatch(currentAppointmentActions.setStage({ stage: 'PAYMENT_SUCCESS' }))
    dispatch(checkoutFlowActions.payOrder({
      accessToken: basketSessionToken,
      paymentIntentToken: paymentIntentTokenPublic,
      orderId: retailOSOrderId,
      orderTotal,
      storeCode: basketStoreCode
    }))
    .then(() => {
      dispatch(currentAppointmentActions.setStage({ stage: 'COMPLETE' }))
    })
    .catch(() => {
      dispatch(currentAppointmentActions.setStage({ stage: 'ORDER_COMPLETION_FAILED' }))
    })
  }

  return (
    <Grid container direction='column'>
      <Grid item>
        <Section>
          {appointmentStage === 'REVIEW' && (
            <>
              <Title value={translations('Customer review consultation - title')} />
              <SubTitle value={translations('Customer review consultation - subtitle')} />
            </>
          )}
          {appointmentStage === 'CHECKOUT' && (
            <>
              <Title value={translations('Customer review consultation - title')} />
              <Notice
                loader
                title={translations('Customer review consultation - processing order notice')}
              />
            </>
          )}
          {appointmentStage === 'ORDER_COMPLETION_FAILED' && (
            <Notice
              title={translations('Customer review consultation - order completion failed message')}
            />
          )}
          {appointmentStage === 'COMPLETE' && (
            <>
              <Title value={translations('Consultation Complete')} />
              <SubTitle
                value={translations(
                  'You have completed your Virtual Consultation! Please close this window to disconnect and end the session.'
                )}
              />
            </>
          )}
          {
            appointmentStage === 'PAYMENT_PENDING' ||
            appointmentStage === 'PAYMENT_SUCCESS' && (
            <Title value={translations('Payment Via Stripe')} />
          )}
        </Section>
      </Grid>
      {showTotalSummary && (
        <Grid item>
          <BasketInfo>
            <BasketInfoText
              dangerouslySetInnerHTML={{
                __html: marked(
                  translations('Customer review consultation - basket info', {
                    itemsCount: `**${orderProductsCount}**`,
                    formattedPrice: `**${currencyFormatter.format(totalToDisplay)}**`
                  })
                )
              }}
            />
            {
              appointmentStage === 'PAYMENT_PENDING' &&
              stripePublicKey && (
                <StripePayment
                  orderTotal={currencyFormatter.format(totalToDisplay)}
                  stripeKey={stripePublicKey}
                  paymentIntentToken={paymentIntentTokenSecret}
                  onSuccess={onPaymentSuccess}
                  onError={onPaymentError}
                />
              )}
          </BasketInfo>
        </Grid>
      )}
      <Grid item>
        {hasProducts &&
          productsToDisplay.map((product) => (
            <ProductItem>
              <SnapshotDetails
                {...product}
                showBasketButtons={appointmentStage === 'REVIEW' || appointmentStage === 'CHECKOUT'}
                canEditBasket={appointmentStage === 'REVIEW'}
              />
            </ProductItem>
          ))}
      </Grid>
    </Grid>
  )
}
export default ReviewConsultationPanel