import React from 'react'
import useVideoContext from '../../hooks/useVideoContext'

import ToggleButton from './ToggleButton'

import { getImage } from '../../../../../../components/Images'

const ToggleParticipantVideoButton = () => {
  const {
    participantVideoIsEnabled,
    onClickHideParticipantVisible
  } = useVideoContext()

  return (
    <ToggleButton
      active={!participantVideoIsEnabled}
      onClick={onClickHideParticipantVisible}>
      {!participantVideoIsEnabled
        ? <img src={getImage('participantVideoOffIcon')} />
        : <img src={getImage('participantVideoOnIcon')} />
      }
    </ToggleButton>
  )
}

export default ToggleParticipantVideoButton
