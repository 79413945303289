import { colors } from '../../config/theme'

const styles = theme => ({
  fullScreenContainer: {
    display: 'flex',
    flexDirection: 'row',
    justifyContent: 'flex-end'
  },
  fullScreenButton: {
    backgroundColor: 'transparent'
  },
  closeIcon: {
    width: 26,
    height: 26,
    color: colors.primary
  }
})

export default styles
