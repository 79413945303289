import React, { useCallback, useEffect } from 'react'
import _ from 'lodash'
import { useDispatch, useSelector } from 'react-redux'
import styled from 'styled-components'
import Grid from '@material-ui/core/Grid'
import { translations, consultationsContentOptions } from '../../../config'
import media from '../../../config/media'
import modalService from '../../../services/modalService'
import ConsultationContentItem from '../ConsultationContentItem'
import AddContentButton from '../../../components/AddContentButton'
import ProductsRouter from '../../Products/ProductsRouter'
import InspirationsRouter from '../../Inspirations/InspirationsRouter'
import { actions as currentAppointmentActions } from '../../../store/modules/currentAppointment'
import { actions as productsActions } from '../../../store/modules/products'
import { actions as categoriesActions } from '../../../store/modules/categories'
import { actions as inspirationsActions } from '../../../store/modules/inspirations'

const Container = styled(Grid)`
  flex-wrap: ${props => props.horizontalScrollingLayout ? 'nowrap' : 'wrap'};
  overflow: ${props => props.horizontalScrollingLayout ? 'scroll' : 'visible'};
  align-items: flex-start;
`

const AddContentGridItem = styled(Grid)`
  display: flex;
  align-items: center;
  justify-content: flex-start;
  min-width: ${props => props.horizontalScrollingLayout ? '33%' : '138px'};
`

const AddContentGridItemHorizontal = styled(Grid)`
  display: flex;
  align-items: center;
  justify-content: center;
  height: 100%;
`

const GridItem = styled(Grid)`
  min-width: ${props => props.horizontalScrollingLayout ? '41.666667%' : '138px'};
  ${media.lessThan('md')`
    min-width: ${props => props.horizontalScrollingLayout ? '58%' : '138px'};
  `}
  border: ${props => props.showBorder ? '1px solid #D1D1D1;' : 'none'};
  border-radius: ${props => props.showBorder ? '10px' : '0'};
`

const ContentPanel = ({ items, onItemClick, selectedResource, horizontalScrollingLayout }) => {
  const dispatch = useDispatch()

  useEffect(() => {
    dispatch(categoriesActions.fetchAllCategories())
  }, [])

  const contentOptions = consultationsContentOptions || []

  const addContent = useCallback(() => {

    if (contentOptions.length === 1) {
      const contentOption = contentOptions[0]
      if (contentOption === 'product') {
        return addProductsToAppointment()
      }
      if (contentOption === 'inspiration') {
        return addInspirationToAppointment()
      }
    }

    return modalService.action({
      title: translations('Select Content Type Title'),
      text: translations('Select Content Type Text'),
      actions: [
        ..._.includes(contentOptions, 'product') ? [{
          success: true,
          text: translations('Product'),
          onClick: addProductsToAppointment,
          primary: false
        }] : [],
        ..._.includes(contentOptions, 'inspiration') ? [{
          text: translations('Inspiration'),
          success: true,
          primary: false,
          onClick: addInspirationToAppointment
        }] : [],
      ]
    })
  })

  const addProductsToAppointment = useCallback(() => {
    dispatch(currentAppointmentActions.appointmentAnalyticsEvent('addProductOrService'))
    dispatch(productsActions.resetStore())
    modalService.open({
      component: ProductsRouter,
      fullScreen: true,
      overflowHidden: true,
      exit: () => {
        modalService.close()
      },
      buttonValue: translations('Select Product'),
      multiSelect: true,
      noRouting: true
    })
  })

  const addInspirationToAppointment = useCallback(() => {
    dispatch(inspirationsActions.fetchInspirationTags())
    dispatch(inspirationsActions.searchInspirationsFresh({ type: 'brand' }))
    dispatch(currentAppointmentActions.appointmentAnalyticsEvent('addLook'))
    modalService.open({
      component: InspirationsRouter,
      hideBottomBar: true,
      selectMode: true,
      fullScreen: true,
      exit: () => modalService.close(),
      noRouting: true,
      tabbed: false
    })
  })

  return (
    <Container container spacing={2} horizontalScrollingLayout={horizontalScrollingLayout}>
      {horizontalScrollingLayout && (
        <AddContentGridItemHorizontal item xs={2} sm={1}>
          <AddContentButton onClick={addContent} small />
        </AddContentGridItemHorizontal>
      )}
      {_.map(items, (item) => {
        if (item.type === 'product' || item.type === 'inspiration') {
          const selected = _.get(item, 'isShowing', false)
          const isService = _.get(item, 'details.service')
          const gridItemWidthForSmall = horizontalScrollingLayout ? 5 : 6
          return (
            <GridItem item xs={6} sm={gridItemWidthForSmall} horizontalScrollingLayout={horizontalScrollingLayout} showBorder={isService}>
              <ConsultationContentItem
                {...item}
                {...item.details}
                onClick={() => onItemClick(item)}
                selected={selected} />
            </GridItem>
          )
        } else {
          return null
        }
      })}

      {!horizontalScrollingLayout && (
        <AddContentGridItem item xs={6} sm={4}>
          <AddContentButton onClick={addContent} />
        </AddContentGridItem>
      )}
    </Container>

  )
}

export default ContentPanel
