import { connect } from 'react-redux'
import _ from 'lodash'
import { selectors as authSelectors } from '../../../../store/modules/auth'
import AddDiscountModal, { discountTypes } from './AddDiscountModal'
import { compose, withState } from 'recompose'

const mapStateToProps = state => {
  const currencyCode = authSelectors.getCurrencyCode(state)
  return {
    currencyCode
  }
}
export { discountTypes }

export default compose(
  connect(mapStateToProps),
  withState('discountType', 'setDiscountType', discountTypes.percent)
)(AddDiscountModal)
