import StaffFullList from './StaffFullList'
import { connect } from 'react-redux'

import { selectors as storeDetailsSelectors } from '../../../../../store/modules/storeDetails'

const mapStateToProps = state => {
  const user = storeDetailsSelectors.getViewUser(state)
  return {
    user
  }
}

export default connect(mapStateToProps)(StaffFullList)
