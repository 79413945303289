import _ from 'lodash'
import { createSelector } from 'reselect'
import { formValueSelector } from 'redux-form'
import { PENDING } from '../../middleware/redux-promise'
import currencyFormatter from '../../../formatters/currencyFormatter'
import {
  stockLevel as stockLevelConstants,
  showInStoreStockInVariantDropdown,
  translations,
  formNames
} from '../../../config'
import { getAllVariantsFromProducts } from '../products/parseProductGroups'

export const getProduct = state => state.productDetails.product
export const getActiveMediaIndex = state => state.productDetails.mediaIndex
export const getProductCareInstructions = state => _.get(state.productDetails.product, 'details.productCareInstructions', '')
export const getSizeGuide = state => _.get(state.productDetails.product, 'details.productSizeGuide', '')
export const getProductDescriptionSummary = state => _.get(state.productDetails.product, 'details.summary', '')
export const getProductError = state => state.productDetails.error
export const getBottomTab = state => state.productDetails.productTab
export const getSelectedVariantId = state => formValueSelector(formNames.productDetails)(state, 'variantId')

const extractPriceDetails = (product) => ({
  price: _.get(product, 'price.value', 0),
  discount: _.get(product, 'discount.value', 0),
  currency: _.get(product, 'price.code', 'GBP')
})

export const getVariants = state => {
  const productGroupVariants = getAllVariantsFromProducts(_.get(state.productDetails, 'productGroupProducts', []))

  // Fall back to standard product variants if there aren't any product group variants
  return productGroupVariants.length ? productGroupVariants : _.get(state.productDetails, 'product.variants', [])
}

const _hasVariantPricing = (variants = []) => !!_.find(variants, (variant) => variant.price)

export const getHasVariantPricing = createSelector([
  getVariants
], (variants) => {
  return _hasVariantPricing(variants)
})

export const getMinMaxVariantPrices = (product) => {
  const variants = product.variants || []
  if (!variants.length) {
    return { min: product, max: product }
  }
  const variantsWithPrice = _.map(variants, (v) => {
    if (!_.get(v, 'price.value')) {
      return {
        ...v,
        price: product.price,
        discount: product.discount
      }
    }

    return v
  })
  const min = _.minBy(variantsWithPrice, (v) => v.price.value)
  const max = _.maxBy(variantsWithPrice, (v) => v.price.value)
  return { min, max }
}

export const getVariantRangeDisplay = (product) => {
  const variants = product.variants || []
  if (variants.length === 1) {
    return getDisplayPriceOfProduct(variants[0])
  }
  const { min, max } = getMinMaxVariantPrices(product)

  if (min.price.value === max.price.value) {
    return getDisplayPriceOfProduct(min)
  }
  return translations('Display Price - Variant Price Range', {
    min: getDisplayPriceOfProduct(min),
    max: getDisplayPriceOfProduct(max)
  })
}

export const getPriceDisplayForProduct = (product) => {
  const variants = product.variants || []
  const hasVariantPricing = _hasVariantPricing(variants)
  if (!hasVariantPricing) {
    return getDisplayPriceOfProduct(product)
  }

  return getVariantRangeDisplay(product)
}

export const getSelectedVariant = createSelector([
  getVariants, getSelectedVariantId
], (variants, selectedVariantId) => {
  if (selectedVariantId) {
    return _.find(variants, (variant) => variant.id === selectedVariantId)
  }
})

export const getDisplayPrice = createSelector([
  getProduct,
  getHasVariantPricing,
  getSelectedVariant
], (product, hasVariantPricing, selectedVariant) => {
  if (!product) return ''

  if (hasVariantPricing) {
    if (selectedVariant) {
      return getDisplayPriceOfProduct(
        selectedVariant.price
        ? selectedVariant
        : product
      )
    }
    return getVariantRangeDisplay(product)
  }
  return getDisplayPriceOfProduct(product)
})
export const getOriginalPrice = createSelector([
  getProduct,
  getHasVariantPricing,
  getSelectedVariant
], (product, hasVariantPricing, selectedVariant) => {
  if (!product) return ''
  if (hasVariantPricing && selectedVariant) {
    const { price, currency } = getFinalPriceDetailsForProduct(product, selectedVariant)
    return currencyFormatter.format(price, currency)
  }
  const { price, currency } = extractPriceDetails(product)
  return currencyFormatter.format(price, currency)
})
export const hasDiscount = createSelector([
  getProduct,
  getHasVariantPricing,
  getSelectedVariant
], (product, hasVariantPricing, selectedVariant) => {
  if (!product) return false
  if (hasVariantPricing && selectedVariant) {
    return _.get(selectedVariant, 'discount.value', 0) > 0
  }
  return _.get(product, 'discount.value', 0) > 0
})

const getFinalPriceDetailsForProduct = (product, variant) => {
  const variantPrice = _.get(variant, 'price.value')
  if (variantPrice) {
    return extractPriceDetails(variant)
  }

  return extractPriceDetails(product) 
}

export const getDiscountPrice = createSelector([
  getProduct,
  getHasVariantPricing,
  getSelectedVariant
], (product, hasVariantPricing, selectedVariant) => {
  if (!product) return ''
  if (hasVariantPricing && selectedVariant) {
    const { discount, currency } = getFinalPriceDetailsForProduct(product, selectedVariant)
    return currencyFormatter.format(discount, currency)
  }
  const { discount, currency } = getFinalPriceDetailsForProduct(product)
  return currencyFormatter.format(discount, currency)
})

export const getDiscountValue = createSelector([
  getProduct,
  getHasVariantPricing,
  getSelectedVariant
], (product, hasVariantPricing, selectedVariant) => {
  if (!product) return ''
  if (hasVariantPricing) {
    if (selectedVariant) {
      const { discount }  = getFinalPriceDetailsForProduct(product, selectedVariant)
      return discount
    }

    return 0
  }
  const { discount } = extractPriceDetails(product)
  return discount
})

export const getVariationAttributes = state => {
  const variationAttributes = _.get(state.productDetails, 'product.details.variationAttributes', [])
  return variationAttributes
}

export const getVariationAttributeNames = createSelector([
  getVariationAttributes
], (variationAttributes = []) => {
  return variationAttributes.map(attr => {
    return attr.id
  })
})

export const getIsLoading = state => state.productDetails.status === PENDING

const getVariantLabel = (variant = {}, noStock, hasVariantPricing, product = {}) => {
  const { name, variantStoreStock = {} } = variant
  const { stock } = variantStoreStock
  let result = name
  if (showInStoreStockInVariantDropdown && !noStock && stock) {
    let stockText = ''
    const addStockNumberTostockText = () => {
      stockText += ` - ${stock}`
    }
    if (stock >= stockLevelConstants.inStockLevel) {
      stockText = translations('In Stock')
      addStockNumberTostockText()
    } else if (stock >= stockLevelConstants.lowStockLowLevel) {
      stockText = translations('Low Stock')
      addStockNumberTostockText()
    } else if (stock === stockLevelConstants.outOfStockLevel) {
      stockText = translations('Out Of Stock')
    } else if (stock === stockLevelConstants.itemNotStocked) {
      stockText = translations('Not Stocked')
    } else {
      stockText = translations('Unknown Stock')
    }
    result = `${name} (${stockText})`
  }

  // Special case for product groups
  if (hasVariantPricing) {
    const variantPrice = _.get(variant, 'price.value')
    const price = getDisplayPriceOfProduct(
      variantPrice 
      ? variant
      : product
    )
    result += ` (${price})`
  }

  return result
}
export const getOptionsFromVariants = (variants, noStock, product) => {
  const hasVariantPricing = _hasVariantPricing(variants)
  return _.chain(variants)
    .sortBy('variantOrder')
    .map(variant => ({
      label: getVariantLabel(variant, noStock, hasVariantPricing, product),
      value: variant.id
    }))
    .value()
}

export const getVariantsOptions = createSelector([
  getVariants,
  getProduct,
], (variants, product) => {
  return getOptionsFromVariants(variants, false, product)
})

export const getDisplayPriceOfProduct = (product) => {
  const { price, discount, currency } = extractPriceDetails(product)
  const displayPrice = price - discount
  return currencyFormatter.format(displayPrice, currency)
}

export const getProductHasVariantPricing = (product) => {
  return _hasVariantPricing(product.variants)
}

export const getProductHasPriceRange = (product) => {
  if (!_hasVariantPricing(product.variants)) return false

  const { min, max } = getMinMaxVariantPrices(product)
  return min !== max
}

export const getSaveAmount = (product) => {
  const { discount, currency } = extractPriceDetails(product)
  return currencyFormatter.format(discount, currency)
}

export const getOriginalProductPrice = (product) => {
  const { price, currency } = extractPriceDetails(product)
  return currencyFormatter.format(price, currency)
}

export const getDiscountValueOfProduct = (product) => {
  const { discount }  = extractPriceDetails(product)
  return discount
}

export const getOnlineAvailabilty = createSelector([
  getVariants, getSelectedVariantId
], (variants, selectedVariantId) => {
  if (selectedVariantId) {
    return _.chain(variants)
      .find(variant => variant.id === selectedVariantId)
      .get('onlineStock')
      .value()
  } else {
    const defaultStockWeight = stockLevelConstants.stockStatusWeight[stockLevelConstants.ONLINE_UNAVAILABLE_STATUS]
    const bestStockStatusValue = _.reduce(variants, (memo, variant) => {
      const stockStatusValue = stockLevelConstants.stockStatusWeight[variant.onlineStock]
      return (stockStatusValue > memo) ? stockStatusValue : memo
    }, defaultStockWeight)

    const stockStatus = _.findKey(stockLevelConstants.stockStatusWeight, (weight) => weight === bestStockStatusValue)

    return stockStatus
  }
})
export const getRelatedProducts = createSelector([
  getProduct
], product => {
  return _.get(product, 'reporting.relatedProducts', [])
})
