import React, { PureComponent } from 'react'
import _ from 'lodash'

import Drawer from '@material-ui/core/Drawer'
import { withStyles } from '@material-ui/core/styles'

import { translations, consultationsEnabled } from '../../../config'
import P from '../../../components/P'
import Button from '../../../components/Button'
import SafeAreaSpacer from '../../../components/SafeAreaSpacer'
import EditMode from '../../../components/EditMode'
import Tabs from '../../../components/Tabs'
import ConsultationWizard from '../../ConsultationWizard'
import ScanButton from './ScanButton'
import FullScreenButton from './FullScreenButton'
import BasketList from '../BasketList'
import BasketButtons from '../BasketButtons'
import style from './style'
import Avatar from '@material-ui/core/Avatar'

class BasketMenu extends PureComponent {
  renderRefundExchangeBasketHeader = () => {
    const { basketType, classes } = this.props

    return (
      <div className={classes.header}>
        <P type='large' className={classes.headerText} value={translations(basketType)} />
      </div>
    )
  }

  render () {
    const {
      groupedProducts,
      isOpen,
      onClose,
      classes,
      editMode,
      orderNumber,
      stopEditingOrder,
      customer,
      exchangeMode,
      basketType,
      tabValue,
      customerNameDetails,
      removeCustomer,
      isConsultationMode
    } = this.props
    return <Drawer
      open={isOpen}
      onClose={onClose}
      anchor='right'
      classes={{ paper: classes.rootMenu }}
    >
      <div className={classes.container}>
        <div>
          <SafeAreaSpacer inset={'top'} />
          <FullScreenButton onClose={onClose} />
        </div>
        <ScanButton />
        {customerNameDetails && (
          <div className={classes.customerNameContainer}>
            <div className={classes.customerName}>
              <Avatar className={classes.avatar}>{customerNameDetails.initials}</Avatar>
              <p>{customerNameDetails.fullName}</p>
            </div>
            {!isConsultationMode && (
              <Button hyperlink className={classes.removeButton} onClick={removeCustomer}>Remove</Button>
            )}
          </div>
        )}
        {(!exchangeMode && basketType !== 'default') ? this.renderRefundExchangeBasketHeader() : null}
        {editMode ? <EditMode orderNumber={orderNumber} stopEditingOrder={stopEditingOrder} /> : null}
        <div className={classes.listContainer}>
          <BasketList
            products={groupedProducts}
            editableQuantities
            textType='large'
            gap
            clickable
            hideUndo={isConsultationMode}
          />
        </div>
        <BasketButtons />
      </div>
    </Drawer>
  }
}

export default withStyles(style)(BasketMenu)
