import React from 'react'
import _ from 'lodash'
import style from './style'
import PropTypes from 'prop-types'
import P from '../../../components/P'
import { apps } from '../../../config'
import { getFullName } from '../../../helpers'
import { withStyles } from '@material-ui/core/styles'

const AppDetails = ({
  user,
  storeName,
  departmentName,
  versionNo,
  classes
}) => {
  const username = _.get(user, 'username')
  const usernameStr = username ? ` (${username})` : ''
  const userInfo = `${getFullName(user)}${usernameStr}`
  return (
    <div className={classes.container}>
      <P value={userInfo} />
      <P value={storeName} />
      {apps.DEPARTMENTS ? <P value={departmentName} /> : null}
      <P value={`App Version: ${versionNo}`} />
    </div>
  )
}

export default withStyles(style)(AppDetails)
