import { useDeviceData } from './useDeviceData'
import { buildSelectorsObject } from './lib/buildSelectors'

export const useDeviceSelectors = (userAgent) => {
  const hookUserAgent = userAgent || window.navigator.userAgent
  const deviceData = useDeviceData(hookUserAgent)
  const selectors = buildSelectorsObject(deviceData)

  return [selectors, deviceData]
}
