import React, { PureComponent } from 'react'
import PropTypes from 'prop-types'
import { SubmissionError } from 'redux-form'
import Grid from '@material-ui/core/Grid'
import _ from 'lodash'
import ContentBox from '../ContentBox'
import ContentBoxBody from '../ContentBox/ContentBoxBody'
import ContentBoxHeader from '../ContentBox/ContentBoxHeader'
import TextContent from '../Form/TextContent'
import RightButtonContent from '../Form/RightButtonContent'
import LeftButtonContent from '../Form/LeftButtonContent'
import { FormBody, FormError } from '../Form'
import H2 from '../H2'
import SubmittingButton from '../SubmittingButton'
import { withStyles } from '@material-ui/core/styles'
import { translations } from '../../config'

import styles from './style'

class FormContentBox extends PureComponent {
  onClick = () => {
    const { editing, toggleEdit } = this.props
    if (!editing) {
      toggleEdit()
    }
  }

  onCancelButtonClick = () => {
    const { toggleEdit, resetForm, destroyForm } = this.props
    // reset form so changes are not persisted on cancel
    resetForm()
    toggleEdit()
    if (destroyForm) {
      destroyForm()
    }
  }

  submit = (values) => {
    // destroy form will not always exist
    const { onSubmit, editing, toggleEdit, destroyForm } = this.props
    if (editing) {
      const submitResult = onSubmit(values)
      if (submitResult && submitResult.then) {
        return submitResult
          .then(() => {
            // only do these after the submit otherwise it can interfere
            if (destroyForm) {
              destroyForm()
            }
            toggleEdit(false)
          })
          .catch(error => {
            throw new SubmissionError({ _error: error.message })
          })
      } else {
        return submitResult
      }
    }
  }

  handleRenderChildren (children) {
    const { editing, initialValues, error, extraProps } = this.props
    return _.chain([children])
      .flatten()
      .map(child => {
        switch (child.type) {
          case FormBody:
            return React.cloneElement(child, { editing, initialValues, extraProps, key: 'body' })
          case FormError:
            return React.cloneElement(child, { error, key: 'error' })
          default:
            return child
        }
      })
      .value()
  }

  render () {
    const {
      showHeader = true,
      boxName,
      classes,
      children,
      isSubmitting,
      isValid,
      editing,
      handleSubmit,
      editable,
      givenContentBoxClass,
      givenContentContainerClass,
      editButtonText = translations('Edit'),
      saveButtonText = translations('Save'),
      saveDisabled = false
    } = this.props

    return (
      <form onSubmit={handleSubmit(this.submit)}>
        <ContentBox
          givenContentContainerClass={givenContentContainerClass}
          givenContentClass={givenContentBoxClass || classes.contentContainer}>
          {showHeader && <ContentBoxHeader>
            <LeftButtonContent>
              {editing && editable &&
                (
                  <SubmittingButton
                    type={'button'}
                    color='primary'
                    className={classes.cancelButton}
                    onClick={this.onCancelButtonClick}
                  >
                    {'Cancel'}
                  </SubmittingButton>
                )
              }
            </LeftButtonContent>
            <TextContent><H2 className={classes.formContentBoxHeader} value={boxName} /></TextContent>
            <RightButtonContent>
              <Grid container spacing={0}>
                {/* Buttons are rendered this way to stop the auto submit bug */}
                {editing && editable &&
                  (
                    <Grid item xs={12} className={classes.editButtonGridItem} >
                      <SubmittingButton
                        type={'submit'}
                        color='primary'
                        className={classes.editButton}
                        disabled={!isValid || saveDisabled}
                        isSubmitting={isSubmitting}
                        onClick={this.onClick}
                      >
                        {saveButtonText}
                      </SubmittingButton>
                    </Grid>
                  )
                }
                {!editing && editable &&
                  (
                    <Grid item xs={12} className={classes.editButtonGridItem} >
                      <SubmittingButton
                        type={'button'}
                        color='primary'
                        className={classes.editButton}
                        disabled={!isValid}
                        isSubmitting={isSubmitting}
                        onClick={this.onClick}
                      >
                        {editButtonText}
                      </SubmittingButton>
                    </Grid>
                  )
                }
              </Grid>
            </RightButtonContent>
          </ContentBoxHeader>}
          <ContentBoxBody>{this.handleRenderChildren(children)}</ContentBoxBody>
        </ContentBox>
      </form>
    )
  }
}

FormContentBox.propTypes = {
  boxName: PropTypes.string.isRequired,
  children: PropTypes.oneOfType([
    PropTypes.arrayOf(PropTypes.element),
    PropTypes.element
  ]),
  editing: PropTypes.bool.isRequired,
  toggleEdit: PropTypes.func.isRequired,
  resetForm: PropTypes.func.isRequired,
  destroyForm: PropTypes.func,
  onSubmit: PropTypes.func.isRequired,
  error: PropTypes.string,
  initialValues: PropTypes.shape({}),
  classes: PropTypes.shape({}),
  isSubmitting: PropTypes.bool.isRequired,
  isValid: PropTypes.bool.isRequired,
  editable: PropTypes.bool.isRequired,
  handleSubmit: PropTypes.func.isRequired,
  editButtonText: PropTypes.string,
  saveButtonText: PropTypes.string,
  saveDisabled: PropTypes.bool,
  extraProps: PropTypes.shape({})
}

export default withStyles(styles)(FormContentBox)
