import _ from 'lodash'
import { compose, withHandlers, withPropsOnChange, pure } from 'recompose'

import { translations } from '../../../config'
import OptionsFormatter from '../Wrappers/OptionsFormatter'
import Dropdown from './Dropdown'

export default compose(
  OptionsFormatter,
  withPropsOnChange(
    ['options', 'multiple', 'nullOptionLabel', 'strict', 'value', 'noNullOption', 'label', 'noFloatingLabel'],
    props => {
      const {
        multiple,
        noNullOption,
        nullOptionLabel = translations('Please select'),
        options,
        strict,
        value,
        label,
        noFloatingLabel,
        autocomplete
      } = props

      const hasGroupedOptions = Array.isArray(options[0])

      let firstGroupOfOptions = (
        hasGroupedOptions
        ? options[0]
        : options
      )

      const hasNullOption = !multiple && !noNullOption && !autocomplete
      const nullOption = { label: nullOptionLabel, value: null, nullOption: true }

      // eg: if a quantity picker has options 1-10, but the value is 14,
      // this will add an extra option to the dropdown for 14
      const hasOptionFromValue = (
        !(strict || autocomplete) &&
        value &&
        _.isString(value) &&
        !_.flatten(options).find(option => option.value === value)
      )
      const optionFromValue = { label: value, value }

      const hasFieldLabelAsOption = !!noFloatingLabel
      const fieldLabelOption = { label, value: '', dropdownLabel: true }

      const newOptions = []
      if (hasFieldLabelAsOption) newOptions.push(fieldLabelOption)
      if (hasNullOption) newOptions.push(nullOption)
      newOptions.push(...firstGroupOfOptions)
      if (hasOptionFromValue) newOptions.push(optionFromValue)

      return {
        value: (multiple && !Array.isArray(value)) ? [value] : value,
        options: (
          hasGroupedOptions
          ? [newOptions, ...options.slice(1)]
          : [newOptions]
        )
      }
    }
  ),
  withHandlers({
    getIsSelected: ({ value }) => someValue => (
      Array.isArray(value)
      ? value.includes(someValue)
      : String(someValue) === String(value)
    ),
    wrappedOnChange: ({ onChange, onBlur }) => e => {
      onChange && onChange(e)
      onBlur && onBlur(e)
    }
  }),
  withHandlers({
    renderLabel: ({ options, getIsSelected }) => selectedValues => {
      const selectedLabels = _.flatten(options)
        .filter(option => getIsSelected(option.value))
        .map(option => {
          return option.label
        })
        .join(', ')
      return selectedLabels
    }
  }),
  pure
)(Dropdown)
