import _ from 'lodash'
import countriesConfig from './configFiles/countries'
import { sortWithPriority } from '../helpers'

const { allCountries, prioritized } = countriesConfig

const orderedNumByNameList = sortWithPriority(allCountries, prioritized, 'name')
const orderedNumByCodeList = sortWithPriority(allCountries, prioritized, 'code')

export const countryPhoneCodes = _.chain(orderedNumByCodeList)
  .map(country => `${country.code} ${country.dialCode}`)
  .value()
export const countryPhoneDictionary = orderedNumByNameList.reduce((memo, next) => ({ ...memo, [next.dialCode]: next }), {})
export const countries = orderedNumByNameList.map(country => country.name)
