import { colors } from '../../../config/theme'

const style = theme => ({
  root: {
    position: 'relative',
    display: 'flex',
    flex: 1,
    flexDirection: 'column'
  },
  contentContainer: {
    display: 'flex',
    justifyContent: 'center',
    position: 'relative',
    maxWidth: 1024,
    margin: 'auto'
  },
  galleryContainer: {
    position: 'relative'
  },
  productContent: {
    padding: 20,
    flex: 1,

    [theme.breakpoints.up('sm')]: {
      padding: 30
    }
  },
  titleContainer: {
    marginBottom: 8
  },
  titleGroupContainer: {
    display: 'flex',
    flexDirection: 'row',
    justifyContent: 'space-between'
  },
  iconsContainer: {
    top: 0,
    right: 0,
    zIndex: 1,
    position: 'absolute',
    marginRight: 10,
    marginLeft: 'auto',
    display: 'flex',
    flexDirection: 'row',
    marginTop: 10
  },
  title: {
    marginBottom: 8,
    [theme.breakpoints.down('xs')]: {
      fontSize: 19
    }
  },
  subTitle: {
    fontWeight: 300,
    marginBottom: 8,
    [theme.breakpoints.down('xs')]: {
      fontSize: 19
    }
  },
  promotionalBadge: {
    padding: 3,
    backgroundColor: '#FF9400'
  },
  promotionalBadgeContainer: {
    display: 'flex',
    minHeight: 18,
    marginTop: 10,
    marginBottom: 10
  },
  price: {
    fontSize: 29
  },
  description: {
    marginTop: 25
  },
  errorContainer: {
    height: 'calc(100vh - 112px)',
    display: 'flex',
    justifyContent: 'center',
    alignItems: 'center',
    [theme.breakpoints.up('xs')]: {
      height: 'calc(100vh - 120px)'
    }
  },
  errorText: {
    fontSize: 18
  },
  iconImage: {
    paddingRight: 10
  },
  fullProductDetailsContainer: {
    width: '100%'
  },
  discount: {
    textTransform: 'uppercase',
    fontWeight: 600
  },
  discountInner: {
    display: 'flex',
    flexDirection: 'row',
    marginBottom: 11
  },
  wasPrice: {
    color: colors.primary,
    textTransform: 'uppercase',
    fontWeight: 600,
    lineHeight: 1.2,
    marginRight: 10
  },
  sku: {
    marginBottom: 10
  },
  stockOutter: {
    marginBottom: 30
  },
  stockContainer: {
    display: 'flex',
    flexDirection: 'row',
    flexWrap: 'nowrap',
    paddingRight: 14,
    fontWeight: 600
  },
  locationContainer: {
    fontWeight: 600,
    textDecoration: 'underline',
    display: 'flex',
    flexDirection: 'row',
    flexWrap: 'nowrap'
  },
  stockIcon: {
    paddingRight: 10
  },
  sizeGuideButton: {
    [theme.breakpoints.down('xs')]: {
      marginRight: -16
    }
  },
  careButton: {
    marginLeft: -15
  }
})

export default style
