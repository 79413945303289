import React from 'react'
import PropTypes from 'prop-types'
import styled from 'styled-components'
import InfiniteScroll from 'react-infinite-scroller'
import AppointmentsFilters from './AppointmentsFilters'
import AppointmentsList from './AppointmentsList'
import P from '../../../../components/P'
import LoadMoreButton from '../../../../components/LoadMoreButton'
import { translations } from '../../../../config'
import media from '../../../../config/media'
import { groupByDate } from '../../../../helpers'

const Container = styled.div`
  display: flex;
  flex-direction: column;
  padding: 20px;
`

const AppointmentsListWrapper = styled.div`
  margin-top: 24px;
  ${media.greaterThan('md')`
    margin-top: 48px;
  `}
`

const EmptyMessageText = styled(P)`
  font-size: 19px;
  text-align: center;
  margin-top: 30px;
`

const LoadMoreContainer = styled.div`
  display: flex;
  flex: 1;
  flex-direction: column;
  align-items: center;
  justify-content: center;
  margin: 15px 0 15px 0;
`

const AppointmentsListing = ({ appointments = [], searchAppointmentsNext, isLoading, isAppointmentsLoading, hasMore }) => {
  const groupedAppointments = groupByDate(appointments, 'startDateTime', { today: 'Today', tomorrow: 'Tomorrow', upcoming: 'Upcoming' })
  return (
    <Container>
      <AppointmentsFilters />
      <AppointmentsListWrapper>
        {appointments.length ? (
          <>
            <AppointmentsList appointments={groupedAppointments} />
            <LoadMoreContainer>
              <LoadMoreButton
                hasMore={hasMore}
                isLoading={isAppointmentsLoading}
                loadNext={searchAppointmentsNext}
              />
            </LoadMoreContainer>
          </>
        ) : (
          <EmptyMessageText value={translations('No results found')} type='textButton' />
        )}
      </AppointmentsListWrapper>
    </Container>
  )
}

AppointmentsListing.propTypes = {
  appointments: PropTypes.array.isRequired,
  searchAppointmentsNext: PropTypes.func
}

export default AppointmentsListing
