import React from 'react'
import PropTypes from 'prop-types'
import { withStyles } from '@material-ui/core/styles'
import P from '../../../../../components/P'
import Button from '../../../../../components/Button'
import { getImage } from '../../../../../components/Images'
import { translations } from '../../../../../config'
import style from './style'

const StockInOtherStores = ({classes, openStoresList}) => {
  return (
    <div className={classes.locationContainer}>
      <Button
        onClick={openStoresList}
        className={classes.storeLink}
        iconImage={'locationIcon'}
        hyperlink
        children={translations('Find in other stores')}
      />
    </div>
  )
}

export default withStyles(style)(StockInOtherStores)
