import React, { PureComponent } from 'react'
import PropTypes from 'prop-types'
import { withStyles } from '@material-ui/core/styles'
import moment from 'moment'
import IconButton from '@material-ui/core/IconButton'
import InfiniteScroll from 'react-infinite-scroller'
import _ from 'lodash'

import { translations } from '../../config'
import { getImage } from '../Images'
import FullScreenProgress from '../FullScreenLoader'
import { Table, TableHeader, TableRow, TableCell } from '../Table'
import currencyFormatter from '../../formatters/currencyFormatter'

import style from './style'

const trashIcon = getImage('trashIcon')

class Waitlist extends PureComponent {

  renderTableRows () {
    const {waitlist = [], classes, onClickDelete, onClickSendMessage } = this.props

    return waitlist.map((waitlistItem, index) => {
      const { id, customer, createdAt, product } = waitlistItem
      
      const getCustomerTotalSpend = (customer) => {
        const lifetimeSpend = _.get(customer, 'reporting.totalSpend') || _.get(customer, 'reporting.lifetime.profit.totalSpend')
        return lifetimeSpend
        ? currencyFormatter.format(lifetimeSpend.value, lifetimeSpend.code, { precision: 0 })
        : undefined
      }
      
      const customerTotalSpend = getCustomerTotalSpend(customer) || "-"
      
      return (
        <TableRow key={id}>
          <TableCell xs={4} sm={4}>
            <div
              className={classes.nameContainer}
            >
              <div
                className={classes.nameWrapper}
              >
                {`${customer.firstName} ${customer.lastName}`}
              </div>
            </div>
          </TableCell>
          <TableCell className={classes.hideExtraSmall} xs={0} sm={3}>{moment(createdAt).format('DD/MM/YYYY')}</TableCell>
          <TableCell xs={4} sm={3}>{customerTotalSpend}</TableCell>
          <TableCell xs={4} sm={2} justify={'flex-end'} direction={'row'}>
              <IconButton
                aria-label='Remove from waitlist'
                onClick={() => onClickDelete(id)}
              >
                <img alt="" src={trashIcon} className={classes.trashIconImg} />
              </IconButton>
              <IconButton
                aria-label='Send message'
                onClick={() => onClickSendMessage({customer, product})}
                className={classes.messageIconButton}
              >
                <img alt="" src={getImage('messageDarkIcon')} className={classes.messageIcon} />
              </IconButton>
          </TableCell>
        </TableRow>
      )
    })
  }
  _renderTable () {
    const { classes, sort, onChange } = this.props
    return (
      <Table
        header={(
          <TableHeader>
            <TableCell xs={4} sm={4}>{translations('Customer Name')}</TableCell>
            <TableCell className={classes.hideExtraSmall} xs={0} sm={3}>{translations('Added')}</TableCell>
            <TableCell xs={4} sm={3}>{translations('LifeTime Spend')}</TableCell>
          </TableHeader>
        )}
        rows={this.renderTableRows()}
        emptyMessage={translations('No results found')}
      />
    )
  }
  render () {
    const { classes, loadMore, hasMore } = this.props
    return (
      <InfiniteScroll
        loadMore={loadMore}
        hasMore={hasMore}
        initialLoad={false}
      >
        {this._renderTable()}
      </InfiniteScroll>
    )
  }
}

Waitlist.propTypes = {
  getProductWaitlist: PropTypes.func.isRequired
}

export default FullScreenProgress(
  withStyles(style)(Waitlist)
)
