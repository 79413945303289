import theme, { colors } from '../../config/theme'

const styles = {
  h3: {
    ...theme.h3,
    color: colors.text
  }
}

export default theme => styles
