import _ from 'lodash'
import React, { useState, useEffect, useCallback } from 'react'
import { useDispatch, useSelector } from 'react-redux'
import { replace } from 'connected-react-router'
import styled from 'styled-components'

import modalService from '../../../services/modalService'

import { selectors as customerDetailsSelectors, actions as customerDetailsActions } from '../../../store/modules/customerDetails'
import { actions as currentOrderActions } from '../../../store/modules/currentOrder'
import { selectors as currentAppointmentSelectors, actions as currentAppointmentActions } from '../../../store/modules/currentAppointment'

import FullScreenProgress from '../../../components/FullScreenLoader/FullScreenLoader'

import HeaderBar from './HeaderBar'
import BottomBar from './BottomBar'

import media from '../../../config/media'
import { translations } from '../../../config'

const Block = styled.div`
  display: flex;
  flex: 1;
  height: 100%;
  width: 100%;
  flex-direction: column;
`
const Row = styled(Block)`
  flex-direction: row;
`

const NoFlexRow = styled(Block)`
  height: auto;
  flex: none;
`

const CenterBlock = styled(Block)`
  justify-content: center;
  align-items: center;
`

const MainRow = styled(Row)`
  flex-direction: row;
  ${media.lessThan('md')`
    flex-direction: column-reverse;
  `}
`

const CoreConsultation = ({
  appointmentId,
  headerBarComponent,
  bottomBarComponent,
  primaryComponent,
  secondaryComponent,
  carouselComponent
}) => {
  const dispatch = useDispatch()
  const [isLoading, setIsLoading] = useState(true)
  const [errorMessage, setErrorMessage] = useState(null)
  const customer = useSelector(customerDetailsSelectors.getCustomer)
  const currentAppointment = useSelector(currentAppointmentSelectors.getCurrentAppointment)
  const customerFirstName = _.get(currentAppointment, 'bookingFirstName') || _.get(customer, 'firstName')
  const customerLastName = _.get(currentAppointment, 'bookingLastName') || _.get(customer, 'lastName')
  const customerFullName = `${customerFirstName} ${customerLastName}`
  const defaultHeaderBarTitle = _.template(translations('Consultation with {customer}'))({ fullName: customerFullName })
  const appointmentContents = useSelector(currentAppointmentSelectors.getAppointmentContents)
  const appointmentOrder = useSelector(currentAppointmentSelectors.getAppointmentOrder)
  const appointmentOrderProducts = _.get(appointmentOrder, 'products', [])
  const isAppointmentComplete = useSelector(currentAppointmentSelectors.getIsAppointmentComplete)

  useEffect(() => {
    setIsLoading(true)
    setErrorMessage(null)
    if (appointmentId) {
      dispatch(currentAppointmentActions.startAppointment({ appointmentId }))
        .then((appointment) => {
          const appointmentCustomerId = _.get(appointment, 'customer.id')
          return dispatch(customerDetailsActions.fetchFullCustomer(appointmentCustomerId))
            .then(({ customer }) => {
              dispatch(currentOrderActions.updateOrder({ customer }))
            })
        })
        .catch((error) => setErrorMessage(error.message))
        .finally(() => { setIsLoading(false) })
    } else {
      setErrorMessage('No appointment id found.')
      setIsLoading(false)
    }
  }, [appointmentId])

  const onBack = useCallback(() => {
    if (isAppointmentComplete) {
      dispatch(replace('/consultations'))
      dispatch(currentOrderActions.clearOrder())
      dispatch(currentAppointmentActions.clearAppointment())
    } else {
      modalService.action({
        title: translations('Are you sure?'),
        text: translations('Clear consultation modal text'),
        actions: [
          {
            success: false,
            text: translations('Clear consultation modal button text'),
            onClick: () => {
              console.log('Clear consultation:', { twilioRoom: window.twilioRoom })
              if (window.twilioRoom) {
                window.twilioRoomDisconnect()
              }
              dispatch(replace('/consultations'))
              dispatch(currentOrderActions.clearOrder())
              dispatch(currentAppointmentActions.clearAppointment())
            }
          },
          {
            text: translations('Clear consultation modal continue button text'),
            primary: true
          }
        ]
      })
    }
  })

  const headerBar = headerBarComponent || <HeaderBar title={defaultHeaderBarTitle} onBack={onBack} />
  const bottomBar = bottomBarComponent || <BottomBar hasContents={_.size(appointmentContents) || _.size(appointmentOrderProducts)} />
  const primary = primaryComponent || null
  const secondary = secondaryComponent || null
  const carousel = carouselComponent || null

  const primaryGridItemWidth = secondary ? 8 : 12
  const secondaryGridItemWidth = primary ? 4 : 12

  if (isLoading) {
    return (
      <CenterBlock>
        <FullScreenProgress />
      </CenterBlock>
    )
  }

  if (errorMessage) {
    return (
      <CenterBlock>{errorMessage}</CenterBlock>
    )
  }

  return (
    <Block>
      <Block>
        <MainRow>
          {primary}
          {secondary}
        </MainRow>
        {carousel && (
          <NoFlexRow>
            {carousel}
          </NoFlexRow>
        )}
      </Block>
      {bottomBar}
    </Block>
  )
}

export default CoreConsultation
