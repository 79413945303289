import React, { useEffect, useRef } from 'react'
import styled from 'styled-components'

import useVideoContext from '../../hooks/useVideoContext'
import _ from 'lodash'

const ParticipantVideo = styled.video`
  display: flex;
  flex: 1;
  z-index: 9;
  width: 100%;
  height: 100%;
  position: absolute;
  visibility: ${({ isVisible }) => isVisible ? 'visible' : 'hidden'};
`

const SelfVideo = styled.video`
  z-index: 10;
  width: 33%;
  max-width: 200px;
  height: auto;
  position: absolute;
  top: 10px;
  right: 10px;
  transform: translate3d(0,0,0) rotateY(180deg);
  -webkit-transform: translate3d(0,0,0) rotateY(180deg); /* translate3d(0,0,0) Fix issue with self-video hidden behind participants on ipad: https://stackoverflow.com/questions/37714009/z-index-problems-on-ipad */
`

const VideoTrack = ({ track, isLocalParticipant }) => {
  const { participantVideoIsEnabled } = useVideoContext()
  const ref = useRef(null)

  useEffect(() => {
    const el = ref.current
    el.muted = true
    track.attach(el)
    return () => {
      track.detach(el)

      // This addresses a Chrome issue where the number of WebMediaPlayers is limited.
      // See: https://github.com/twilio/twilio-video.js/issues/1528
      el.srcObject = null
    }
  }, [track])

  return isLocalParticipant
    ? <SelfVideo ref={ref} />
    : <ParticipantVideo ref={ref} isVisible={participantVideoIsEnabled}/>
}

export default VideoTrack
