import formStyle from '../../../components/Form/style'

const styles = {
  field: {
    // maxWidth: '330px',
    width: '100%'
  }
}

export default theme => ({
  ...formStyle,
  ...styles
})
