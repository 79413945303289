import React, { Fragment } from 'react'

import Filters from './RetailAnalyticsFilters'
import Content from './RetailAnalyticsContent'

const RetailAnalyticsScreen = props => {
  return (
    <Fragment>
      <Filters />
      <Content />
    </Fragment>
  )
}

export default RetailAnalyticsScreen
