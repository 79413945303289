import i18next from 'i18next'
import en from './configFiles/translations/en'

i18next.init({
  lng: 'en',
  debug: false,
  resources: {
    en: { translation: en }
  }
})

export const translations = (key, options) => i18next.t(key, options)
