import React, { useEffect, useState } from 'react'
import _ from 'lodash'
import styled from 'styled-components'

import usePublications from '../hooks/usePublications'
import useVideoContext from '../hooks/useVideoContext'

const Content = styled.div`
  background: transparent;
  display: flex;
  flex-direction: column;
  align-items: center;
  justify-content: center;
  position: absolute;
  top: 0;
  left: 0;
  right: 0;
  bottom: 0;
  z-index: 8;
`

const VideoOverlay = ({ participant }) => {
  const [message, setMessage] = useState(null)
  const [hasVideo, setHasVideo] = useState(false)
  const {
    screenRemoteParticipantIdentity,
    participantVideoIsEnabled
  } = useVideoContext()

  const publications = usePublications(participant)

  useEffect(() => {
    let hasVideoPublished = false
    _.map(publications, (publication) => {
      switch (publication.kind) {
        case 'video':
          hasVideoPublished = true
          break
        case 'audio':
          // do nothing
          break
        default:
          break
      }
    })
    setHasVideo(hasVideoPublished)
  }, [publications])

  useEffect(() => {
    if (!participant) {
      setMessage(`Waiting for ${screenRemoteParticipantIdentity} to connect`)
    } else {
      if (!participantVideoIsEnabled && hasVideo) {
        setMessage(`${_.capitalize(participant.identity)} is connected`)
      } else if (participantVideoIsEnabled && hasVideo) {
        setMessage(null)
      } else if (!hasVideo) {
        setMessage(`${_.capitalize(participant.identity)} has disabled their video`)
      }
    }
  }, [participant, participantVideoIsEnabled, hasVideo])

  return message
    ? (
      <Content>
        <p style={{ color: 'white', fontWeight: 'bold' }}>{message}</p>
      </Content>
    )
    : <></>
}

export default VideoOverlay
