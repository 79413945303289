import { colors } from '../../../../../config/theme'

const boxPadding = '16px 24px'

const style = theme => ({
  customerBar: {
    display: 'flex',
    flexDirection: 'column',
    justifyContent: 'space-between',
    alignItems: 'center',
    border: `1px solid ${colors.border}`,
    padding: boxPadding,
    marginTop: 15,
    [theme.breakpoints.up('sm')]: {
      flexDirection: 'row'
    }
  },
  container: {
    marginTop: 15
  }
})

export default style
