import React from 'react'
import Grid from '@material-ui/core/Grid'
import FormControl from '@material-ui/core/FormControl'
import { withStyles } from '@material-ui/core/styles'
import _ from 'lodash'

import Editable from '../../../../components/Editable'
import FormContentBox from '../../../../components/FormContentBox'
import { FormBody, FormError } from '../../../../components/Form'
import { Checklist, Dropdown } from '../../../../components/EditableFields'
import { communicationOptions, translations } from '../../../../config'

import style from './style'

const CommunicationOptionsForm = FormContentBox('communicationOptions')

const WrappedCommunicationOptions = Editable(({ initialValues, editing, toggleEdit, isSubmitting, boxName, onSubmit, formId, extraProps, allStoresOptions, classes, editable }) => {
  const channelsDisplayValue = initialValues.customerMarketingChannels.map(value => _.get(communicationOptions.channelOptionKeys.find(option => option.value === value), 'label')).join(', ')
  const preferencesDisplayValue = initialValues.customerMarketingPreferences.map(value => _.get(communicationOptions.preferenceOptionKeys.find(option => option.value === value), 'label')).join(', ')
  const storesDisplayValue = allStoresOptions ? initialValues.marketingStoreIds.map(value => _.get(allStoresOptions.find(option => option.value === value), 'label')).join(', ') : ''
  const { storeMarketingValue } = extraProps
  // pass extra props into form so that it re-renders through the form body container
  return (
    <div>
      <CommunicationOptionsForm
        enableReinitialize
        initialValues={initialValues}
        editing={editing}
        editable={editable}
        toggleEdit={toggleEdit}
        isSubmitting={isSubmitting}
        boxName={boxName}
        onSubmit={onSubmit}
        formId={formId}
        extraProps={extraProps}
      >
        <FormError />
        <FormBody>
          <Grid container spacing={2}>
            <Grid item xs={12} sm={6}>
              <Checklist
                editing={editing}
                initialValues={initialValues}
                options={communicationOptions.channelOptionKeys}
                name='customerMarketingChannels'
                label='Marketing Channels'
                multiple
                displayValue={channelsDisplayValue}
              />
            </Grid>
          </Grid>
        </FormBody>
      </CommunicationOptionsForm>
    </div>
  )
})

export default withStyles(style)(WrappedCommunicationOptions)
