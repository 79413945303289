import Video from 'twilio-video'
import { VideoRoomMonitor } from '@twilio/video-room-monitor'
import { useCallback, useEffect, useRef, useState } from 'react'
import { useDeviceSelectors } from '../../../../../hooks/deviceDetect'

window.twilioVideo = Video

/*
* The `useRoom` hook provides the connection method and returns the Twilio room.
* The connectionData refers to the device and platform the used is connecting with.
*/
/*
 *  The useRoom hook provides the connection method and the Twilio room.
 *  IMPORTANT: This hooks is intended to be used by the `VideoProvider` component only. Using this hook
 *  elsewhere in the application may cause problems as it should not be used more than once in an application.
 */

const useRoom = (localTracks, onError, options, logEvent) => {
  const [room, setRoom] = useState(null)
  const [connectionData, setConnectionData] = useState()
  const [isMobile, setIsMobile] = useState(false)
  const [isConnecting, setIsConnecting] = useState(false)
  const optionsRef = useRef(options)

  useEffect(() => {
    // capture user's browser/device information
    const [selectors, deviceData] = useDeviceSelectors()
    setConnectionData(selectors)
    setIsMobile(selectors.isMobile)
  }, [])

  useEffect(() => {
    // This allows the connect function to always access the most recent version of the options object.
    // This allows us to reliably use the connect function at any time.
    optionsRef.current = options
  }, [options])

  const connect = useCallback(
    async token => {
      setIsConnecting(true)
      return Video.connect(token, { ...optionsRef.current, tracks: localTracks })
        .then(
          newRoom => {
            logEvent(`Connecting to Twilio room ${newRoom.name} with options ${JSON.stringify(optionsRef.current)}`)
            setRoom(newRoom)
            VideoRoomMonitor.registerVideoRoom(newRoom)

            const disconnect = () => {
              newRoom.localParticipant.videoTracks.forEach(publication => {
                publication.track.stop()
                const attachedElements = publication.track.detach()
                attachedElements.forEach(element => element.remove())
                newRoom.localParticipant.unpublishTrack(publication.track)
              })

              newRoom.localParticipant.audioTracks.forEach(publication => {
                publication.track.stop()
                const attachedElements = publication.track.detach()
                attachedElements.forEach(element => element.remove())
                newRoom.localParticipant.unpublishTrack(publication.track)
              })

              newRoom.disconnect()
            }

            // This app can add up to 2 'participantDisconnected' listeners to the room object, which can trigger
            // a warning from the EventEmitter object. Here we increase the max listeners to suppress the warning.
            newRoom.setMaxListeners(2)

            newRoom.once('disconnected', () => {
              // Reset the room only after all other `disconnected` listeners have been called.
              setTimeout(() => setRoom(null))
              window.removeEventListener('beforeunload', disconnect)

              if (isMobile) {
                window.removeEventListener('pagehide', disconnect)
              }
            })

            // SET Global variables for use outside the Video App scope

            window.twilioRoom = newRoom

            window.twilioRoomDisconnect = disconnect

            newRoom.localParticipant.videoTracks.forEach(publication =>
              publication.setPriority('low')
            )

            setIsConnecting(false)

            // Add a listener to disconnect from the room when a user closes their browser
            window.addEventListener('beforeunload', disconnect)

            if (isMobile) {
              // Add a listener to disconnect from the room when a mobile user closes their browser
              window.addEventListener('pagehide', disconnect)
            }

            const cordova = window.cordova

            // ** IMPORTANT - READ THIS IF YOU PLAN ON CHANGING ANY OF THE TWILIO VIDEO INTEGRATION **
            // https://github.com/cordova-rtc/cordova-plugin-iosrtc/issues/497

            if (cordova && cordova.plugins && cordova.plugins.iosrtc) {
              logEvent('Cordova: using iosrtc plugin')
              // Expose WebRTC and GetUserMedia SHIM as Globals (Optional)
              // Alternatively WebRTC API will be inside cordova.plugins.iosrtc namespace
              cordova.plugins.iosrtc.registerGlobals()
              // Patch MediaStreamTrack with clone
              MediaStreamTrack.prototype.clone = () => {
                return this
              }
              // Enable iosrtc debug (Optional)
              cordova.plugins.iosrtc.debug.enable('*', false)
            }
          },
          error => {
            onError(error)
            setIsConnecting(false)
          }
        )
        .catch((error) => {
          onError(error)
          setIsConnecting(false)
        })
    },
    [onError]
  )

  return { room, isConnecting, connect, connectionData }
}

export default useRoom
