import React from 'react'
import BasicModalOverlay from '../../../../../components/BasicModalOverlay'

import { translations } from '../../../../../config'


const PermissionsDeniedModal = () => (
  <BasicModalOverlay
    title={translations('Video/audio permission denied modal title')}
    text={translations('Video/audio permission denied modal text')}
    actions={[
      {
        success: true,
        text: 'Try again',
        onClick: () => window.location.reload(),
        primary: true
      }
    ]}
  />
)

export default PermissionsDeniedModal
