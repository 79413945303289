import { colors } from '../../../../../config/theme'

export default {
  desktopFiltersContainer: {
    marginRight: '7.5%',
    marginLeft: '7.5%'
  },
  buttonContainer: {
    padding: 20,
    marginBottom: 10,
    borderTop: `1px solid ${colors.mediumGrey}`,
    background: colors.lightGrey
  },
  button: {
    margin: 'auto'
  }
}
